import { Button, Select, Input, DatePicker, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import { useDrawer } from '../../../../../context/DrawerContext'
import { TicketSupportTable } from '../../components'
import { useTikectList } from '../../../../../services/ticket-support-list/ticket-support-list.service'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { CompanyChangeContainer } from '../../../../../containers'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useTicketStore } from '../../../../../services/ticket-support-create/ticket-support.store'
import { TICKET_STATUS_LABEL, TICKET_STATUS_LIST } from '../../../../../services/order-create/order.params'
import moment from 'moment'

const { Option } = Select
const { Search } = Input
const { RangePicker } = DatePicker
const { Title } = Typography

const TicketSupportsContainer = () => {
  const { openDrawer } = useDrawer()
  const { tickets, getTickets, loading } = useTikectList()
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { tkUpdated, setTkSelected } = useTicketStore()
  const [defaultSelected, setDefaultSelected] = useState<string>('ALL')
  const [type, setType] = useState<string>('ALL')
  const dateFormat = 'YYYY-MM-DD'
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    moment(new Date(), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])

  const handleCalendarChangeOrder = (dates: any, _dateStrings: any) => {
    setSelectedDatesOrder(dates)
  }

  useEffect(() => {
    if (authUser.user.role.name === 'SELLER' || authUser.user.role.name === 'ADMIN_STORE') {
      getTickets(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        type,
        defaultSelected
      )
    } else if (companyGlobal) {
      getTickets(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        type,
        defaultSelected
      )
    }
  }, [companyGlobal, tkUpdated, defaultSelected, type, selectedDatesOrder])

  const handleOpenDrawer = () => {
    setTkSelected(null)
    openDrawer('TK_CREATE', 'Agregar Ticket')
  }

  const handleChange = (status: string) => {
    setDefaultSelected(status)
  }

  const handleType = (type: string) => {
    setType(type)
  }

  const onSearch = (value: string) => {

    if (value.length > 0) {

      getTickets(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        type,
        defaultSelected,
        value
      )

    } else {
      getTickets(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        type,
        defaultSelected,
        value
      )
    }
  }

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', justifyContent: 'flex-end', alignItems: 'center' }}>

          <Title level={5}>Tickets #{tickets.length}</Title>
          <h4 style={{ alignSelf: 'center' }}>Fechas</h4>

          <RangePicker format={dateFormat} 
            defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
            onCalendarChange={handleCalendarChangeOrder} clearIcon={false}/>

          <Search placeholder='Buscar por #ticket' 
            onSearch={onSearch} allowClear enterButton style={{ width: '300px' }} />
          <div>|</div>

          {authUser.user.role?.permissions?.order_company_change ? (
            <CompanyChangeContainer isAll={true}/>
          ) : null}

          { authUser.user.role.name === 'SELLER' || authUser.user.role.name === 'ADMIN_STORE' ? <>
            <Button type="primary" onClick={handleOpenDrawer}>
              Nuevo ticket
            </Button>
          </> : null }

          { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' ? <>

            <Select
              placeholder='Seleccione un tipo de ticket'
              style={{ minWidth: '130px' }}
              onChange={handleType}
              value={type}
            >
              <Option key={'ALL'} value={'ALL'}>
                {'TODOS'}
              </Option>
              <Option key={'PLATFORM'} value={'PLATFORM'}>
                {'PLATAFORMA'}
              </Option>
              <Option key={'COURIER'} value={'COURIER'}>
                {'COURIER'}
              </Option>
            </Select>

            { companyGlobal && companyGlobal.type === 'NORMAL' ? <>
              <Button type="primary" onClick={handleOpenDrawer}>
                Nuevo ticket
              </Button>
            </> : null } 
          </> : null }

          <Select 
            placeholder="seleccione un estado"
            style={{ minWidth: '130px' }}
            onChange={handleChange}
            value={defaultSelected}
          >
            <Option key={'ALL'} value={'ALL'}>
              {'TODOS'}
            </Option>
            <Option key={TICKET_STATUS_LIST.OPEN} value={TICKET_STATUS_LIST.OPEN}>
              {TICKET_STATUS_LABEL[TICKET_STATUS_LIST.OPEN].label}
            </Option>
            <Option key={TICKET_STATUS_LIST.IN_PROCESS} value={TICKET_STATUS_LIST.IN_PROCESS}>
              {TICKET_STATUS_LABEL[TICKET_STATUS_LIST.IN_PROCESS].label}
            </Option>
            <Option key={TICKET_STATUS_LIST.ATTENDED} value={TICKET_STATUS_LIST.ATTENDED}>
              {TICKET_STATUS_LABEL[TICKET_STATUS_LIST.ATTENDED].label}
            </Option>
          </Select>
        
        </div>

      </PageHeader>

      <TicketSupportTable tickets={tickets} loading={loading} />
      
    </>
  )
}

export default TicketSupportsContainer
