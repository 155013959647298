import { ILiquidationEditResponse } from '../../services/liquidation-edit/liquidation-edit.interface'
import { ILiquidationResponse } from '../../services/liquidation-list/liquidation-list.interface'
import {
  ILiquidationRecalculateResponse
} from '../../services/liquidation-recalculate/liquidation-recalculate.interface'

export const actionTypes = {
  SET_LIQUIDATION_SELECTED: 'SET_LIQUIDATION_SELECTED',
  SET_LIQUIDATION_UPDATED: 'SET_LIQUIDATION_UPDATED',
  SET_LIQUIDATION_CALCULATED: 'SET_LIQUIDATION_CALCULATED'
}

const setLiquidationSelected = (data: ILiquidationResponse | null) => ({
  type: actionTypes.SET_LIQUIDATION_SELECTED,
  payload: data,
})

const setLiquidationUpdated = (data: ILiquidationEditResponse) => ({
  type: actionTypes.SET_LIQUIDATION_UPDATED,
  payload: data,
})

const setLiquidationCalculated = (data: ILiquidationRecalculateResponse) => ({
  type: actionTypes.SET_LIQUIDATION_CALCULATED,
  payload: data,
})

export const liquidationActions = {
  setLiquidationSelected,
  setLiquidationUpdated,
  setLiquidationCalculated,
}
