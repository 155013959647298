/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './product.actions'
import { IProductSearchSchema } from '../../services/product/product.schema'
import { IProductResponse, ISkuItemResponse } from '../../services/product/product.interface'
import { IProductCreateResponse } from '../../services/product-create/product-create.interface'

export interface IProductState {
  productSearch: IProductSearchSchema[];
  productSelected: IProductResponse | null;
  productUpdated: IProductCreateResponse | null;
  skuSelected: ISkuItemResponse | null
}

const initialState: IProductState = {
  productSearch: [],
  productSelected: null,
  productUpdated: null,
  skuSelected: null,
}

export const productReducer = (
  state: IProductState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_PRODUCT_EMPTY:
    return {
      ...state,
      productSearch: action.payload,
    }
  case actionTypes.SET_PRODUCT_SEARCH:
    return {
      ...state,
      productSearch: [...state.productSearch, action.payload],
    }
  case actionTypes.PRODUCT_DELETE:
    return {
      ...state,
      productSearch: state.productSearch.filter((p) => p.skuId !== action.payload),
    }
  case actionTypes.SET_PRODUCT_SELECTED:
    return {
      ...state,
      productSelected: action.payload,
    }
  case actionTypes.SET_PRODUCT_UPDATED:
    return {
      ...state,
      productUpdated: action.payload,
    }
  case actionTypes.SET_SKU_SELECTED:
    return {
      ...state,
      skuSelected: action.payload
    }
  default:
    return state
  }
}
