import { Select, DatePicker, DatePickerProps } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { PageHeader } from '../../../../../components'
import { DepositTable } from '../../components'
import {
  useLiquidationList,
  useLiquidationTotal
} from '../../../../../services/liquidation-list/liquidation-list.service'
import { useMotoriedList } from '../../../../../services/user-motorized/user-motorized.service'
import { IMotorizedResponse } from '../../../../../services/user-motorized/user-motorized.interface'
import { LIQUIDATION_STATUS_LABEL } from '../../../../../services/liquidation-list/liquidation.params'
import { useLiquidationStore } from '../../../../../services/liquidation-edit/liquidation-edit.store'

const { Option } = Select

const DepositListContainer = () => {
  const { getLiquidations, liquidations, loading } = useLiquidationList()
  const { getLiquidationTotal, summaryTotal } = useLiquidationTotal()
  const { getMotorizeds, motorizeds, loadingMt } = useMotoriedList()
  const [motorizedSelected, setMotorizedSelected] = useState<number>()
  const [statusSelected, setStatusSelected] = useState<string>()
  const [dateSelected, setDateSelected] = useState<string>()
  const { liquidationUpdated, liquidationCalculated } = useLiquidationStore()

  useEffect(() => {
    getMotorizeds()
    setDateSelected(moment().startOf('day').format('yyyy-MM-DD'))
  }, [])

  const handleMotorizedChange = (value: number, _: any) => {
    setMotorizedSelected(value)
  }

  const handleStatusChange = (value: string, _: any) => {
    setStatusSelected(value)
  }

  const handleDateChange: DatePickerProps['onChange'] = (date) => {
    setDateSelected(date?.startOf('day').format('yyyy-MM-DD'))
  }

  useEffect(() => {
    getLiquidations({
      deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
      userId: motorizedSelected ? String(motorizedSelected) : '',
      status: statusSelected ? statusSelected : ''
    })

    getLiquidationTotal(
      dateSelected || moment().startOf('day').format('yyyy-MM-DD')
    )

  }, [motorizedSelected])

  useEffect(() => {
    getLiquidations({
      deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
      userId: motorizedSelected ? String(motorizedSelected) : '',
      status: statusSelected ? statusSelected : ''
    })

    getLiquidationTotal(
      dateSelected || moment().startOf('day').format('yyyy-MM-DD')
    )

  }, [statusSelected])
  
  useEffect(() => {
    getLiquidations({
      deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
      userId: motorizedSelected ? String(motorizedSelected) : '',
      status: statusSelected ? statusSelected : ''
    })

    getLiquidationTotal(
      dateSelected || moment().startOf('day').format('yyyy-MM-DD')
    )

  }, [dateSelected])

  useEffect(() => {
    if (liquidationUpdated) {
      getLiquidations({
        deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        userId: motorizedSelected ? String(motorizedSelected) : '',
        status: statusSelected ? statusSelected : ''
      })

      getLiquidationTotal(
        dateSelected || moment().startOf('day').format('yyyy-MM-DD')
      )
    }
  }, [liquidationUpdated])

  useEffect(() => {
    if (liquidationCalculated) {
      getLiquidations({
        deliveryDate: dateSelected || moment().startOf('day').format('yyyy-MM-DD'),
        userId: motorizedSelected ? String(motorizedSelected) : '',
        status: statusSelected ? statusSelected : ''
      })

      getLiquidationTotal(
        dateSelected || moment().startOf('day').format('yyyy-MM-DD')
      )

    }
  }, [liquidationCalculated])

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex', justifyContent: 'space-between', gap: '.5rem', alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
            <DatePicker
              placeholder='Seleccionar día'
              onChange={handleDateChange}
              defaultValue={moment()}
              format={'yyyy-MM-DD'}
            />
            <div> | </div>
            <Select
              loading={loadingMt}
              style={{ width: '200px' }}
              showSearch
              optionFilterProp="label"
              allowClear
              placeholder='Seleccionar motorizado'
              options={motorizeds.map((e: IMotorizedResponse) => ({
                label: e.email,
                value: e.id,
              }))}
              onChange={handleMotorizedChange}
            />
            <div> | </div>
            <Select
              style={{ width: '200px' }}
              showSearch
              allowClear
              optionFilterProp="label"
              placeholder='Seleccionar estado'
              onChange={handleStatusChange}
            >
              {Object.keys(LIQUIDATION_STATUS_LABEL).map(key => (
                <Option key={key} value={key}>
                  {LIQUIDATION_STATUS_LABEL[key].label}
                </Option>
              ))}
            </Select>
            
            <div> | </div>

            <h5>#Entregados: {summaryTotal ? summaryTotal.orderDelivered.toFixed(0) : 0}</h5>

            <div> | </div>

            <h5>E: {summaryTotal ? summaryTotal.totalCash.toFixed(0) : 0}</h5>

            <div> | </div>

            <h5>T: {summaryTotal ? summaryTotal.totalTransfer.toFixed(0) : 0}</h5>

            <div> | </div>

            <h5>POS: {summaryTotal ? summaryTotal.totalPos.toFixed(0) : 0}</h5>

            <div> | </div>
            <h5>Total depositado: {summaryTotal ? summaryTotal.depositedMotoTotal.toFixed(0) : 0}</h5>

          </div>
        </div>
      </PageHeader>
      <DepositTable
        liquidations={liquidations}
        loading={loading}
      />
    </>
  )
}

export default DepositListContainer
