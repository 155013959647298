import moment from 'moment'
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IMovementResponse } from './movement-list.interface'

export const useMovementList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IMovementResponse[]>()

  const getMovements = (
    companyId?: string, 
    warehouseId?: string,
    skuId?: string,
  ) => {
    execute({
      method: 'GET',
      url: '/transaction',
      params: {
        companyId: companyId === '0' ? '' : (companyId || authUser.user.company.id),
        warehouseId: warehouseId === 'ALL' ? '' : warehouseId,
        skuId: skuId === 'ALL' ? '' : skuId,
        createdAtTo: moment().subtract(60, 'days').format('YYYY-MM-DD'),
        createdAtFrom: moment().format('YYYY-MM-DD')
      },
    })
  }

  return {
    getMovements,
    loading,
    movements: response ? response : []
  }
}
