import React, { FC, useEffect, useState } from 'react'
import { IUpdateEvidenceSchema } from '../../../../../services/evidence-update/evidence-update.schema'
import { IOrderDeliveryResponse } from '../../../../../services/order-list/order.interface'
import { Button, Form, Image, Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { storage } from '../../../../../libs/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import TextArea from 'antd/lib/input/TextArea'

interface IEvidenceEditFormProps {
  onSubmit: (data: IUpdateEvidenceSchema) => void
  orderId?: number,
  orderDelivery?: IOrderDeliveryResponse
}
  
const EvidenceForm: FC<IEvidenceEditFormProps> = ({
  onSubmit, orderId, orderDelivery
}) => {

  const [form] = Form.useForm<IUpdateEvidenceSchema>()

  const urlDelivery = Form.useWatch(['evidenceDelivery'], form)
  const [fileDelivery, setFileDelivery] = useState<any>()
  const [urlEvDelivery, setUrlEvDelivery] = useState('')
  const [percentDelivery, setPercentDelivery] = useState(0)

  const urlCall = Form.useWatch(['evidenceCall'], form)
  const [fileCall, setFileCall] = useState<any>()
  const [urlEvCall, setUrlEvCall] = useState('')
  const [percentCall, setPercentCall] = useState(0)

  const urlChat = Form.useWatch(['evidenceChat'], form)
  const [fileChat, setFileChat] = useState<any>()
  const [urlEvChat, setUrlEvChat] = useState('')
  const [percentChat, setPercentChat] = useState(0)

  const urlSupport = Form.useWatch(['evidenceSupport'], form)
  const [fileSupport, setFileSupport] = useState<any>()
  const [urlEvSupport, setUrlEvSupport] = useState('')
  const [percentSupport, setPercentSupport] = useState(0)

  useEffect(() => {

    if (orderDelivery) {
    
      form.setFieldsValue({
        evidenceCall: orderDelivery.evidenceCall,
        evidenceDelivery: orderDelivery.evidenceDelivery,
        evidenceSupport: orderDelivery.evidenceSupport,
        evidenceChat: orderDelivery.evidenceChat,
        comment: orderDelivery.comment
      })
    }
    
  }, [orderDelivery])

  useEffect(() => {
    if (urlEvCall.length > 0) {
      form.setFieldValue(['evidenceCall'], urlEvCall)
    }
  }, [urlEvCall])

  function handleBeforeUploadCall(file: any) {
    setFileCall(file)
    if (!file) {
      message.success('Debe seleccionar una imagen')

      return
    }

    const storageRef = ref(storage, `/files-evidences/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercentCall(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlEvCall(url)
        })
      }
    )

    return false
  }
  
  useEffect(() => {
    if (urlEvDelivery.length > 0) {
      form.setFieldValue(['evidenceDelivery'], urlEvDelivery)
    }
  }, [urlEvDelivery])

  function handleBeforeUploadDelivery(file: any) {
    setFileDelivery(file)
    if (!file) {
      message.success('Debe seleccionar una imagen')

      return
    }

    const storageRef = ref(storage, `/files-evidences/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercentDelivery(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlEvDelivery(url)
        })
      }
    )

    return false
  }

  useEffect(() => {
    if (urlEvChat.length > 0) {
      form.setFieldValue(['evidenceChat'], urlEvChat)
    }
  }, [urlEvChat])

  function handleBeforeUploadChat(file: any) {
    setFileChat(file)
    if (!file) {
      message.success('Debe seleccionar una imagen')

      return
    }

    const storageRef = ref(storage, `/files-evidences/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercentChat(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlEvChat(url)
        })
      }
    )

    return false
  }

  useEffect(() => {
    if (urlEvSupport.length > 0) {
      form.setFieldValue(['evidenceSupport'], urlEvSupport)
    }
  }, [urlEvSupport])

  function handleBeforeUploadSupport(file: any) {
    setFileSupport(file)
    if (!file) {
      message.success('Debe seleccionar una imagen')

      return
    }

    const storageRef = ref(storage, `/files-evidences/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        setPercentSupport(percent)
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUrlEvSupport(url)
        })
      }
    )

    return false
  }
  
  return <>
    <Form
      layout="horizontal"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      name="orderDelivery"
      form={form}
      labelWrap
      onFinish={onSubmit}
    >
    
      <Form.Item label="Comentario" name={['comment']}>
        <TextArea rows={2}/>
      </Form.Item>

      <Form.Item name={['evidenceDelivery']} label="Evidencia de entrega">
        <Upload 
          name='file'
          beforeUpload={handleBeforeUploadDelivery}
          maxCount={1}
          fileList={[]}
        >
          <Button>
            <UploadOutlined /> {fileDelivery ? fileDelivery.name : ''} {percentDelivery} %
          </Button>
        </Upload>
        { urlDelivery && urlDelivery.length > 0 ? <>
          <Image
            width={100}
            src={urlDelivery}
          />
        </> : null }
      </Form.Item>
    
      <Form.Item name={['evidenceCall']} label="Llamada a cliente">
        <Upload 
          name='file'
          beforeUpload={handleBeforeUploadCall}
          maxCount={1}
          fileList={[]}
        >
          <Button>
            <UploadOutlined /> {fileCall ? fileCall.name : ''} {percentCall} %
          </Button>
        </Upload>
        { urlCall && urlCall.length > 0 ? <>
          <Image
            width={100}
            src={urlCall}
          />
        </> : null }
      </Form.Item>

      <Form.Item name={['evidenceChat']} label="Chat a cliente">
        <Upload 
          name='file'
          beforeUpload={handleBeforeUploadChat}
          maxCount={1}
          fileList={[]}
        >
          <Button>
            <UploadOutlined /> {fileChat ? fileChat.name : ''} {percentChat} %
          </Button>
        </Upload>
        { urlChat && urlChat.length > 0 ? <>
          <Image
            width={100}
            src={urlChat}
          />
        </> : null }
      </Form.Item>

      <Form.Item name={['evidenceSupport']} label="Chat a soporte">
        <Upload 
          name='file'
          beforeUpload={handleBeforeUploadSupport}
          maxCount={1}
          fileList={[]}
        >
          <Button>
            <UploadOutlined /> {fileSupport ? fileSupport.name : ''} {percentSupport} %
          </Button>
        </Upload>
        { urlSupport && urlSupport.length > 0 ? <>
          <Image
            width={100}
            src={urlSupport}
          />
        </> : null }
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" size="middle">
          Actualizar evidencia
        </Button>
      </Form.Item>
    </Form>
  </>
}
  
export default EvidenceForm
