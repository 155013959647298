export const DISPATCH_STATUS_LIST = {
  REGISTERED: 'REGISTERED',
  IN_AGENCY: 'IN_AGENCY',
  DELIVERED: 'DELIVERED',
}

export const DISPATCH_STATUS_LABEL: { [key: string]: { label: string } } = {
  REGISTERED: {
    label: 'REGISTRADO',
  },
  IN_AGENCY: {
    label: 'EN AGENCIA',
  },
  DELIVERED: {
    label: 'ENTREGADO',
  },
}
