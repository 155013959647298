import { useAxios } from '../../hooks'
import { ICustomerPhoneDelivered } from './customer-phone.interface'

export const useCustomerPhoneInfo = () => {
  const { execute, response, loading } = useAxios<ICustomerPhoneDelivered>()

  const getCustomerPhoneInfo = (phone?: string) => {
    execute({
      method: 'GET',
      url: `/order/customer-phone/${phone}`,
    })
  }

  return {
    getCustomerPhoneInfo,
    loading,
    response,
  }
}
