import { IProductCreateResponse } from '../../services/product-create/product-create.interface'
import { IProductResponse, ISkuItemResponse } from '../../services/product/product.interface'
import { IProductSearchSchema } from '../../services/product/product.schema'

export const actionTypes = {
  SET_PRODUCT_EMPTY: 'SET_PRODUCT_EMPTY',
  SET_PRODUCT_SEARCH: 'SET_PRODUCT_SEARCH',
  PRODUCT_DELETE: 'PRODUCT_DELETE',
  SET_PRODUCT_SELECTED: 'SET_PRODUCT_SELECTED',
  SET_PRODUCT_UPDATED: 'SET_PRODUCT_UPDATED',
  SET_SKU_SELECTED: 'SET_SKU_SELECTED',
}

const setProductEmpty = () => ({
  type: actionTypes.SET_PRODUCT_EMPTY,
  payload: []
})

const setProductSearch = (preOrder: IProductSearchSchema) => ({
  type: actionTypes.SET_PRODUCT_SEARCH,
  payload: preOrder,
})

const productDelete = (productId: number) => ({
  type: actionTypes.PRODUCT_DELETE,
  payload: productId,
})

const setProductSelected = (data: IProductResponse | null) => ({
  type: actionTypes.SET_PRODUCT_SELECTED,
  payload: data,
})

const setProductUpdated = (data: IProductCreateResponse) => ({
  type: actionTypes.SET_PRODUCT_UPDATED,
  payload: data,
})

const setSkuSelected = (data: ISkuItemResponse) => ({
  type: actionTypes.SET_SKU_SELECTED,
  payload: data
})

export const productActions = {
  setProductEmpty,
  setProductSearch,
  productDelete,
  setProductSelected,
  setProductUpdated,
  setSkuSelected
}
