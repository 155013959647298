import React, { FC, useEffect } from 'react'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Space,
  Switch,
  Typography,
  Select,
  List
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import {
  IProductCreateSchema, ISkuCreateSchema, ISkuOptionCreateSchema,
} from '../../../../../services/product-create/product-create.schema'
import { useWarehouseList } from '../../../../../services/warehouse-list/warehouse-list.service'
import { useProductStore } from '../../../../../services/product/product.store'
import Title from 'antd/lib/typography/Title'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { generateCombination } from '../../../../../utils/generate-combination'
import { uuid } from '../../../../../utils/uuid'
import { makeSku } from '../../../../../utils/make-sku'
import { IWarehouseResponse } from '../../../../../services/warehouse-list/warehouse-list.interface'
import Barcode from 'react-barcode'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCategories } from '../../../../../services/category/category.service'
import { ICategory } from '../../../../../services/category/category.interface'
import { useCatalogsByCompany } from '../../../../../services/catalog/catalog.service'
import { ICatalogStore } from '../../../../../services/catalog/catalog.interface'

const { Text } = Typography
const { Option } = Select

interface IProductEditFormProps {
  onSubmit: (data: IProductCreateSchema) => void
}

const ProductEditForm: FC<IProductEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<IProductCreateSchema>()
  const { productSelected } = useProductStore()
  const { getWarehouses, loading, warehouses } = useWarehouseList()
  const { categories, getCategories, loadingCategory } = useCategories()
  const variants = Form.useWatch('variants', form)
  const isProductGlobal = Form.useWatch('isProductGlobal', form)
  const name = Form.useWatch('name', form)
  const { authUser } = useAuthStore()
  const { getCatalogsByCompany, loadingCatalogsStore, catalogs } = useCatalogsByCompany()

  useEffect(() => {
    getWarehouses('-1')
    getCategories()
    getCatalogsByCompany()
    if (productSelected) {
      form.setFieldsValue({
        id: productSelected.id,
        name: productSelected.name,
        shortDescription: productSelected.shortDescription,
        largeDescription: productSelected.largeDescription,
        urlImage: productSelected.urlImage,
        catalogId: productSelected.catalogId,
        urlReference: productSelected.urlReference,
        retailPriceSuggested: productSelected.retailPriceSuggested,
        priceDropCrate: productSelected.priceDropCrate,
        priceDropDozen: productSelected.priceDropDozen,
        unitsCrate: productSelected.unitsCrate,
        isProductGlobal: productSelected.isProductGlobal,
        isNovelty: productSelected.isNovelty,
        isLargeVolume: productSelected.isLargeVolume,
        salePriceDrop: productSelected.salePriceDrop,
        isActive: true,
        categoryId: productSelected.categoryId,
      })
    }
  }, [productSelected])

  useEffect(() => {
    if (variants?.length) {
      const variantsMap = variants
        .filter(e => e)
        .filter(e => e.options?.length)
        .map(variant => ({
          variantId: uuid(),
          name: variant?.variant,
          options: variant?.options?.split(',')
            .map((e: string) => e.trim())
            .filter((e: string) => e)
            .map((e: string) => ({
              optionId: uuid(),
              name: e,
            }))
        }))

      const groups = variantsMap?.map(
        e => e.options?.map((opt: ISkuOptionCreateSchema) => `${opt.name}|${opt.optionId}`)
      )
      const combinations = generateCombination(groups as string[][])

      const skuAuto = makeSku(3)
      const skus: ISkuCreateSchema[] = combinations.map((c, index) => ({
        check: true,
        sku: `${skuAuto}${index + 1}`,
        purchasePrice: 1,
        regularPrice: 1,
        stock: 1,
        heightProd: 0,
        widthProd: 0,
        lengthProd: 0,
        skuOptions: c.split(' ').map(e => ({ optionId: e.split('|')[1] })) as ISkuOptionCreateSchema[],
        skuOptionsLabel: c.split(' ').map(e => e.split('|')[0]).join(', ')
      }))

      form.setFieldsValue({
        skus,
        variantsMap,
      })
    }
  }, [variants])

  useEffect(() => {
    if (name) {
      const skus = [{
        check: true,
        sku: `${makeSku(3)}1`,
        purchasePrice: 1,
        regularPrice: 1,
        skuOptions: [],
        skuOptionsLabel: name,
        stock: 1,
        heightProd: 0,
        widthProd: 0,
        lengthProd: 0,
      }]

      form.setFieldsValue({ skus, variantsMap: [] })
    }
  }, [name])

  useEffect(() => {
    if (warehouses && warehouses.length > 0) {
      form.setFieldsValue({
        warehouseId: authUser.user.warehouse?.id
      })
    }
  }, [warehouses])

  const validateURL = (_: any, value: any) => {

    if (!value) {
      return Promise.reject(new Error('Por favor ingresa una URL'))
    }
    // Expresión regular para validar la URL
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/

    if (!urlRegex.test(value)) {
      return Promise.reject(new Error('Por favor ingresa una URL válida'))
    }

    return Promise.resolve()
  }

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='UserForm'
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item name={['id']} hidden>
          <Input />
        </Form.Item>

        <Form.Item name={['variantsMap']} hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label='Nombre producto'
          name={['name']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Descripción'
          name={['shortDescription']}
        >
          <Input maxLength={100}/>
        </Form.Item>

        <Form.Item
          label='Detalle'
          name={['largeDescription']}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          label='Categoría'
          name={['categoryId']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select placeholder='Seleccione una opción' loading={loadingCategory}>
            {categories?.map((r: ICategory) => (
              <Option key={r.id} value={r.id}>
                {r.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Catálogo'
          name={['catalogId']}
          rules={[{ required: false, message: 'Obligatorio' }]}
        >
          <Select placeholder='Seleccione una opción' loading={loadingCatalogsStore}>
            {catalogs?.map((r: ICatalogStore) => (
              <Option key={r.id} value={r.id}>
                {r.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Link imagen'
          name={['urlImage']}
          rules={[{ required: true, validator: validateURL }]}
        >
          <Input placeholder='Ingresa una URL'/>
        </Form.Item>

        <Form.Item
          label='Link de drive'
          name={['urlReference']}
        >
          <Input />
        </Form.Item>

        {!productSelected ? 
          authUser.user.warehouse ? <>
            <Form.Item
              label='Almacén'
              name={['warehouseId']}
              rules={[{ required: true, message: 'Obligatorio' }]}
            >
              <Select placeholder="Seleccione una opción" loading={loading} disabled>
                {warehouses?.map((r: IWarehouseResponse) => (
                  <Option key={r.id} value={r.id}>
                    {r.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </> : <>
            <Form.Item
              label='Almacén'
              name={['warehouseId']}
              rules={[{ required: true, message: 'Obligatorio' }]}
            >
              <Select placeholder="Seleccione una opción" loading={loading}>
                {warehouses?.map((r: IWarehouseResponse) => (
                  <Option key={r.id} value={r.id}>
                    {r.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
          : null }

        <Form.Item
          label="Producto global"
          name={['isProductGlobal']}
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>

        { authUser.user.role.name === 'ADMIN_STORE' || authUser.user.role.name === 'SELLER' ? 
          <>
            <Form.Item label='Producto Volumen grande' name={['isLargeVolume']} hidden>
              <Input />
            </Form.Item> 
          </> : <>
            <Form.Item
              label="Producto Volumen grande"
              name={['isLargeVolume']}
              valuePropName='checked'
            >
              <Switch />
            </Form.Item>
          </>
        }

        <Form.Item label="Nuevo producto" name={['isNovelty']} valuePropName='checked'>
          <Checkbox />
        </Form.Item>

        { isProductGlobal ? <>
          <Form.Item label='Precio venta sugerido' name={['retailPriceSuggested']}>
            <Input />
          </Form.Item>
          <Form.Item label='Precio drop unidad' name={['salePriceDrop']}>
            <Input />
          </Form.Item>
          <Form.Item label='Precio drop docena' name={['priceDropDozen']}>
            <Input />
          </Form.Item>
          <Form.Item label='Precio drop cajón' name={['priceDropCrate']}>
            <Input />
          </Form.Item>
          <Form.Item label='Unidades por cajón' name={['unitsCrate']}>
            <Input />
          </Form.Item>
        </> : 
          <>
            <Form.Item label='Precio venta sugerido' name={['retailPriceSuggested']} hidden>
              <Input />
            </Form.Item>
            <Form.Item label='Precio drop unidad' name={['salePriceDrop']} hidden>
              <Input />
            </Form.Item>
            <Form.Item label='Precio drop docena' name={['priceDropDozen']} hidden>
              <Input />
            </Form.Item>
            <Form.Item label='Precio drop cajón' name={['priceDropCrate']} hidden>
              <Input />
            </Form.Item>
            <Form.Item label='Unidades por cajón' name={['unitsCrate']} hidden>
              <Input />
            </Form.Item>
          </> }

        {productSelected ? (
          <Form.Item
            label="Activo"
            name={['isActive']}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        ) : null}

        <Divider />

        {!productSelected ? (
          <>
            <Title level={4}>Variantes</Title>

            <Form.List name="variants">
              {(fields, { add, remove }) => (
                <>
                  <Text
                    type="secondary"
                    style={{ display: 'block', marginBottom: '.65rem' }}
                  >Ingresar opciones separado por comas</Text>

                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} >
                      <Form.Item
                        {...restField}
                        name={[name, 'variant']}
                        rules={[{ required: true, message: '' }]}
                        className='fullWidth'
                      >
                        <Input placeholder="Color" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'options']}
                        rules={[{ required: true, message: '' }]}
                        className='fullWidth'
                      >
                        <Input placeholder="Opciones" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Title level={4}>SKUs</Title>

            <Form.List name="skus">
              {(fields) => (
                <>
                  <Text
                    type="secondary"
                    style={{ display: 'block', marginBottom: '.65rem' }}
                  >Sólo los SKUs marcados con check se guardan</Text>

                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} >
                      
                      <Form.Item name={[name, 'skuOptions']} hidden>
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        valuePropName="checked"
                        name={[name, 'check']}
                      >
                        <Checkbox />
                      </Form.Item>

                      <Form.Item>  
                        <Form.Item
                          label={<span style={{ fontSize: '13px' }}>
                          Variante
                          </span>}
                          name={[name, 'skuOptionsLabel']}
                        >
                          <Input readOnly style={{ display: 'inline-block', width: '100%' }}/>
                        </Form.Item>

                        <Form.Item
                          label={<span style={{ fontSize: '13px' }}>
                          SKU
                          </span>}
                          {...restField}
                          name={[name, 'sku']}
                        >
                          <Input style={{ display: 'inline-block', width: '100%' }}/>
                        </Form.Item>

                        <Form.Item
                          label={<span style={{ fontSize: '13px' }}>
                          P. Compra
                          </span>}
                          {...restField}
                          name={[name, 'purchasePrice']}
                        >
                          <InputNumber style={{ display: 'inline-block', width: '100%' }}/>
                        </Form.Item>
                        <Form.Item
                          label={<span style={{ fontSize: '13px' }}>
                          P. Venta
                          </span>}
                          {...restField}
                          name={[name, 'regularPrice']}
                        >
                          <InputNumber style={{ display: 'inline-block', width: '100%' }}/>
                        </Form.Item>

                      </Form.Item>

                      <Form.Item
                        style={{ display: 'inline-flex', alignItems: 'flex-end', alignContent: 'flex-end' }}
                      >
                        <Form.Item
                          label={<span style={{ fontSize: '13px' }}>
                          Stock
                          </span>}
                          {...restField}
                          name={[name, 'stock']}
                        >
                          <InputNumber style={{ display: 'inline-block', width: '100%' }}/>
                        </Form.Item>

                        <Form.Item
                          label={<span style={{ fontSize: '13px' }}>
                          Alto cm
                          </span>}
                          {...restField}
                          name={[name, 'heightProd']}>
                          <InputNumber style={{ display: 'inline-block', width: '100%' }}/>
                        </Form.Item>

                        <Form.Item
                          label={<span style={{ fontSize: '13px' }}>
                          Ancho cm
                          </span>}
                          {...restField}
                          name={[name, 'widthProd']}>
                          <InputNumber style={{ display: 'inline-block', width: '100%' }}/>
                        </Form.Item>

                        <Form.Item
                          label={<span style={{ fontSize: '13px' }}>
                          Largo cm.
                          </span>}
                          {...restField}
                          name={[ name, 'lengthProd']}>
                          <InputNumber style={{ display: 'inline-block', width: '100%' }}/>
                        </Form.Item>

                      </Form.Item>

                    </Space>
                    
                  ))}
                </>
              )}
            </Form.List>

            <Divider />
          </>
        ) : null}

        <Button type='primary' htmlType='submit'>
          Guardar
        </Button>

        <Divider />

        { productSelected ? <>

          <Title level={5}>{productSelected.name}</Title>

          <List
            itemLayout="vertical"
            size='small'
            pagination={false}
            dataSource={productSelected.skus}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[]}
                extra={
                  <Barcode
                    value={item.ean ? item.ean : ''}
                  />
                }
              >
                <List.Item.Meta
                  title={<h5>EAN: {item.ean}<br/>{
                    item.skuOptions.length > 0 ? <>
                      {item.skuOptions?.map(o => `${o.option?.variant.name}: ${o.option?.name}`).join('\n')}
                    </> : ''
                  }</h5>}
                />
              </List.Item>
            )}
          />

        </> : null }

      </Form>
    </>
  )
}

export default ProductEditForm
