import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, Select, List, Typography, Image } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { ILiquidationSchema } from '../../../../../services/liquidation-edit/liquidation.schema'
import { useLiquidationStore } from '../../../../../services/liquidation-edit/liquidation-edit.store'
import {
  LIQUIDATION_STATUS_LABEL,
} from '../../../../../services/liquidation-list/liquidation.params'
import { PAYMENT_METHOD_LABEL } from '../../../../../services/payment-create/payment.params'
import { DeleteFilled } from '@ant-design/icons'

const { Option } = Select
const { Title } = Typography

interface ILiquidationEditFormProps {
  onSubmit: (data: ILiquidationSchema) => void
  onDeleteVoucher: (id: number) => void
}

const DepositEditForm: FC<ILiquidationEditFormProps> = ({ onSubmit, onDeleteVoucher }) => {
  const [form] = Form.useForm<ILiquidationSchema>()
  const { liquidationSelected } = useLiquidationStore()
  const [disabled, setDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (liquidationSelected) {

      if (liquidationSelected.status === 'OBSERVED' || 
        liquidationSelected.status === 'VALIDATED') {
        setDisabled(false)
      } else {
        setDisabled(false)
      }

      form.setFieldsValue({
        id: liquidationSelected.id,
        commentLiquidated: liquidationSelected.commentLiquidated,
        commentMotorized: liquidationSelected.commentMotorized,
        status: liquidationSelected.status,
      })
    } 
  }, [liquidationSelected])

  const handleDeleteVoucher = (id: number) => {
    onDeleteVoucher(id)
  }

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='DepositForm'
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item name={['id']} hidden>
          <Input />
        </Form.Item>
        
        { liquidationSelected ? <>
          <Form.Item
            label='Comentario motorizado'
            name={['commentMotorized']}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            label='Comentario liquidación'
            name={['commentLiquidated']}
          >
            <TextArea rows={3} disabled={disabled}/>
          </Form.Item>

          <Form.Item name={['status']} label="Estado de liquidación"
            rules={[{ required: true, message: 'Obligatorio' }]}>
            <Select placeholder="Seleccione una opción" disabled={disabled}>
              {Object.keys(LIQUIDATION_STATUS_LABEL).map(key => (
                <Option key={key} value={key}>
                  {LIQUIDATION_STATUS_LABEL[key].label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          { !disabled ? <>
            <Divider />
          </> : null}
          
          <Button type='primary' htmlType='submit' hidden={disabled}>
            Guardar
          </Button>

          <Divider />

          <Title level={5}>Depósitos</Title>

          <List
            itemLayout="vertical"
            size="large"
            pagination={false}
            dataSource={liquidationSelected.evidenceLiquidations}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <Button key={item.id} icon={<DeleteFilled />} type='primary' danger
                    onClick={() => handleDeleteVoucher(item.id)}>Eliminar
                  </Button>
                ]}
                extra={
                  <Image
                    width={150}
                    alt="logo"
                    src={item.voucherUrl}
                  />
                }
              >
                <List.Item.Meta
                  title={<h5>Entidad: {item.entity}
                    <br/>Método de pago: {PAYMENT_METHOD_LABEL[item.paymentMethod].label}
                    <br/>Monto: {liquidationSelected.currency}{item.amount.toFixed(2)}</h5>}
                />
              </List.Item>
            )}
          />

        </> : null}

      </Form>
    </>
  )
}

export default DepositEditForm
