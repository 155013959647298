/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './ticket-support.actions'
import { ITicketSupport } from '../../services/ticket-support-list/ticket-support-list.interface'

export interface ITkState {
  tkSelected: ITicketSupport | null;
  tkUpdated: ITicketSupport | null;
}

const initialState: ITkState = {
  tkSelected: null,
  tkUpdated: null,
}

export const tkReducer = (
  state: ITkState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_TK_SELECTED:
    return {
      ...state,
      tkSelected: action.payload,
    }
  case actionTypes.SET_TK_UPDATED:
    return {
      ...state,
      tkUpdated: action.payload,
    }
  default:
    return state
  }
}
