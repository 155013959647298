import { ICoverageItemResponse } from '../../services/coverage-create/coverage-create.interface'

export const actionTypes = {
  SET_COVERAGE_SELECTED: 'SET_COVERAGE_SELECTED',
  SET_COVERAGE_UPDATED: 'SET_COVERAGE_UPDATED',
}

const setCoverageSelected = (data: ICoverageItemResponse | null) => ({
  type: actionTypes.SET_COVERAGE_SELECTED,
  payload: data,
})

const setCoverageUpdated = (data: ICoverageItemResponse) => ({
  type: actionTypes.SET_COVERAGE_UPDATED,
  payload: data,
})

export const coverageActions = {
  setCoverageSelected,
  setCoverageUpdated,
}
