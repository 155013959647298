import React, { FC } from 'react'
import { IOrderResumen } from '../../../../../services/order-list/order.interface'
import './OrderResumen.css'

interface IOrderResumenProps {
  resumen: IOrderResumen
}

const OrderResumen: FC<IOrderResumenProps> = ({ resumen }) => {
  return (
    <>
      <div className='OrderResumen'>
        <div style={{ textAlign: 'center', display: 'flex', fontSize: 'small' }}>
          <span className='OrderResumen__label'>#</span>
          <b>{resumen?.totalOrder}</b>
        </div>

        <div style={{ textAlign: 'center', display: 'flex', fontSize: 'small' }}>
          <span className='OrderResumen__label'>TOT:</span>
          <b>{resumen?.totalAmount.toFixed(2)}</b>
        </div>

        <div style={{ textAlign: 'center', display: 'flex', fontSize: 'small' }}>
          <span className='OrderResumen__label'>SDO:</span>
          <b>
            {((resumen?.totalAmount || 0) - (resumen?.totalPayment || 0)).toFixed(2)}
          </b>
        </div>

        <div style={{ textAlign: 'center', display: 'flex', fontSize: 'small' }}>
          <span className='OrderResumen__label'>ADE:</span>
          <b>{(resumen?.totalPayment || 0).toFixed(2)}</b>
        </div>

      </div>
    </>
  )
}

export default OrderResumen
