import { IWarehouseCreateSchema } from './warehouse-create.schema'
import { IWarehouseCreateRequest } from './warehouse-create.interface'
import { IAuthResponse } from '../auth/auth.interface'
import { getLocations } from '../location/location.service'
import { ICompanyItemResponse } from '../company-list/company-list.interface'
import { BANK_LIST } from '../../config/data.params'

const mapToWarehouseCreateRequest = (
  warehouse: IWarehouseCreateSchema,
  authUser: IAuthResponse,
  companyGlobal: ICompanyItemResponse | null,
): IWarehouseCreateRequest => {

  const locations = (warehouse.province && warehouse.district) ?
    getLocations(warehouse.province, warehouse.district) : undefined

  const bank = BANK_LIST.find((b) => b.code === warehouse.codeBank)

  return {
    name: warehouse.name,
    companyId: companyGlobal?.id || authUser.user.company.id,
    address: warehouse.address || '',
    reference: warehouse.reference || '',
    lat: warehouse.gps?.split(',')[0] || '',
    lng: warehouse.gps?.split(',')[1] || '',
    countryName: authUser.user.company.setting.country.name,
    countryCode: authUser.user.company.setting.country.code,
    departmentName: locations?.department.nombre_ubigeo || null,
    departmentCode: locations?.department.id_ubigeo || null,
    provinceName: locations?.province.nombre_ubigeo || null,
    provinceCode: locations?.province.id_ubigeo || null,
    districtName: locations?.district.nombre_ubigeo || null,
    districtCode: locations?.district.id_ubigeo || null,
    phone: `${warehouse.phone}`,
    typeWarehouse: companyGlobal?.type || authUser.user.company.type,
    userId: warehouse.userId,
    codeBank: warehouse.codeBank,
    accountNumber: warehouse.accountNumber,
    cci: warehouse.cci,
    holderName: warehouse.holderName,
    emailTransfer: warehouse.emailTransfer,
    documentHolderAccount: warehouse.documentHolderAccount,
    businessName: warehouse.businessName,
    businessDocument: warehouse.businessDocument,
    nameBank: bank?.value,
  }
}

export {
  mapToWarehouseCreateRequest,
}
