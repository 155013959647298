import React, { FC, useEffect } from 'react'
import { DatePicker, Form, FormInstance, Input, Select, Typography } from 'antd'
import {
  useLocationLevel1,
  useLocationLevel2,
  useLocationLevel3,
} from '../../../../../../services/location/location.service'
import { IOrderUpdateSchema } from '../../../../../../services/order-update/order-update.schema'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import { useCountryEdit } from '../../../../../../services/pre-order/pre-order.edit'
import { useAuthStore } from '../../../../../../services/auth/auth.service'

const { Title } = Typography

interface IOrderShippingPartProps {
  form: FormInstance<IOrderUpdateSchema>
  companyUser: number
  disabled: boolean
  isField: boolean
  isDate: boolean
  warName?: string
  deliveryDate?: string
  dispatchDateCurrent?: string
}

const OrderShippingPart: FC<IOrderShippingPartProps> = ({
  form, companyUser, disabled, isField, isDate, warName, deliveryDate, dispatchDateCurrent
}) => {
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form)
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form)
  const districtId = Form.useWatch(['shipping', 'districtId'], form)
  const dispatchDate = Form.useWatch(['shipping', 'dispatchDate'], form)
  const enableDpto = Form.useWatch(['enableDpto'], form)
  const addDays = Form.useWatch(['addDays'])
  const hoursWork = Form.useWatch(['hoursWork'])
  const typePattern = Form.useWatch(['typePattern'])
  const orderDetails = Form.useWatch(['orderDetails'])
  const { authUser } = useAuthStore()
  const { requiredByCountry } = useCountryEdit()
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } = useLocationLevel1()
  const { getLocationsGeoLevel2, loadingGeo2, locationsGeolevel2 } = useLocationLevel2()
  const { getLocationsGeoLevel3, loadingGeo3, locationsGeolevel3 } = useLocationLevel3()
  const deliveryCost = Form.useWatch(['shippingCost'])
  const currencySymbol = authUser.user.company.setting.currency.symbol

  const handleChangeProvince = (ubigeoId: string) => {
    getLocationsGeoLevel3({
      parentId: Number.parseInt(ubigeoId)
    })
  }

  const handleChangeDepartment = (ubigeoId: string) => {
    getLocationsGeoLevel2({
      parentId: Number.parseInt(ubigeoId)
    })
  }

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    if (companyUser === 2) {

      const hour = hoursWork ? hoursWork : 0

      const pattern = typePattern ? typePattern : 0

      const now = moment().utcOffset(-5)
      const today = moment().startOf('day').add(hour, 'h')
      
      if (pattern === 0) {

        if (now > today) {
          return (now.day() === 2 || now.day() === 4 || now.day() === 6) ? current && 
          current < moment().add(1, 'd').startOf('day') 
          || current.day() === 2 || current.day() === 4 || current.day() === 6 : current && 
          current < moment().add(1, 'd').startOf('day') 
          || current.day() === 2 || current.day() === 4 || current.day() === 6 
        }
        
        return current.isBefore(now.startOf('day')) || current.day() === 2 
          || current.day() === 4 || current.day() === 6

      } else {

        if (now > today) {
          return current && current < moment().add(1, 'd').startOf('day') || current.day() === 0
        }
        
        return current.isBefore(now.startOf('day')) || current.day() === 0 ||
          (current.date() === 28 && current.month() === 2 && authUser.user.company.countryCode === 'PER') ||
          (current.date() === 29 && current.month() === 2 && authUser.user.company.countryCode === 'PER')

      }
        
    } else if ((authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER')) {

      const pattern = typePattern ? typePattern : 0

      //const now = moment().utcOffset(-5)
      
      if (pattern === 0) {
        
        return current.day() === 2 
          || current.day() === 4 || current.day() === 6

      } else {

        return false

      }
    } else {
      return current && current < moment().startOf('day')
    }

  }

  const flagDeliveryDate = () => {
    if (isDate) {
      if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER') {
        return false
      } else if (companyUser === 2) {
        return true
      } else {
        return true
      }
    } else {
      return false
    }
  }

  const flagSchedule = () => {
    if (isDate) {
      if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER') {
        return false
      } else if (companyUser === 2) {
        return true
      } else {
        return true
      }
    } else {

      if (authUser.user.role.name === 'ADMIN') {
        return true
      } else {
        return false
      }

    }
  }

  useEffect(() => {
    if (dispatchDate) {
      if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER') {

        // free date delivery

      } else if (companyUser === 2) {

        const days = addDays ? addDays : 0
        const pattern = typePattern ? typePattern : 0
        const currentDay = moment(dispatchDate).utcOffset(-5)
        let newScheduleDate

        if (pattern === 0) {
          
          if (currentDay.day() === 5) { // friday
            newScheduleDate = moment(dispatchDate).add(3 + parseInt(`${days}`), 'd')
          } else { //others days
            newScheduleDate = moment(dispatchDate).add(parseInt(`${days}`), 'd')
          }

        } else {
          if (currentDay.day() === 6) { // saturday
            const tempDays = parseInt(`${days}`)

            newScheduleDate = moment(dispatchDate).add(1 + (tempDays > 0 ? (tempDays - 1) : 0), 'd')

          } else if (currentDay.day() === 5 && parseInt(`${days}`) === 3) {

            newScheduleDate = moment(dispatchDate).add(0 + parseInt(`${days}`), 'd')

          } else { //others days
            newScheduleDate = moment(dispatchDate).add(parseInt(`${days}`), 'd')
          }

          if (newScheduleDate.day() === 0) {
            newScheduleDate = moment(newScheduleDate).add(1, 'd')
          }

        }

        if (dispatchDateCurrent) {
          const tempCurrent = moment(new Date(dispatchDateCurrent), 'DD/MM/YYYY')
          const newCurrent = moment(dispatchDate, 'DD/MM/YYYY')

          if (tempCurrent.day() === newCurrent.day() && tempCurrent.month() === newCurrent.month() && 
          tempCurrent.year() === newCurrent.year()) {

            if (deliveryDate) {
              const formatDate = moment(new Date(deliveryDate), 'DD/MM/YYYY')
              
              form.setFieldValue(['shipping', 'scheduleDate'], formatDate)  
            } else {
              form.setFieldValue(['shipping', 'scheduleDate'], newScheduleDate)
            }

          } else {
            form.setFieldValue(['shipping', 'scheduleDate'], newScheduleDate)
          }

        } else {
          form.setFieldValue(['shipping', 'scheduleDate'], newScheduleDate)
        }
      }
    }
  }, [dispatchDate, addDays, typePattern])

  useEffect(() => {
    const dptos = locationsGeolevel1 ? locationsGeolevel1.filter(f => f.flagContraentrega) : []

    if (departmentId && dptos.length > 0) {
      const dpto = dptos.find(d => `${d.id}` === `${departmentId}`)

      if (dpto) {
        form.setFieldValue(['shipping', 'department'], dpto?.name)
        form.setFieldValue(['shipping', 'dptoJson'], dpto.warehouseIds)

        if (warName) {
          const war = dpto.warehouseIds?.find(it => it.name === warName)

          if (war) {
            form.setFieldValue('typePattern', war.typePattern)
            form.setFieldValue('addDays', war.addDays || 0)
            form.setFieldValue('warehouseCurrent', war.name)
          }
        }

        handleChangeDepartment(`${dpto.id}`)
      }
    }
  }, [departmentId, locationsGeolevel1])

  useEffect(() => {
    const provs = locationsGeolevel2 ? locationsGeolevel2.filter(f => f.flagContraentrega) : []

    if (provinceId && provs.length > 0) {
      const prov = provs.find(d => `${d.id}` === `${provinceId}`)

      if (prov) {
        form.setFieldValue(['shipping', 'province'], prov?.name)

        if (prov.warehouseIds && prov.warehouseIds !== null) {
          form.setFieldValue(['shipping', 'dptoJson'], prov.warehouseIds)
        }

        if (warName && prov.warehouseIds && prov.warehouseIds !== null) {
          const war = prov.warehouseIds?.find(it => it.name === warName)

          if (war) {
            form.setFieldValue('typePattern', war.typePattern)
            form.setFieldValue('addDays', war.addDays || 0)
            form.setFieldValue('warehouseCurrent', war.name)
            form.setFieldValue('shippingCost', war.deliveryCost || 0)
          }
        }

        handleChangeProvince(`${prov.id}`)
      }
    }
  }, [provinceId, locationsGeolevel2])

  useEffect(() => {
    const districts = locationsGeolevel3 ? locationsGeolevel3.filter(f => f.flagContraentrega) : []

    if (districtId && districts.length > 0) {
      const dist = districts.find(d => `${d.id}` === `${districtId}`)

      if (dist) {
        form.setFieldValue(['shipping', 'district'], dist?.name)
      }
    }
  }, [districtId, locationsGeolevel3])

  useEffect(() => {
    getLocationsGeoLevel1()
  }, [])

  return <>
    <Title level={4}>Entrega</Title>

    <Form.Item
      hidden
      label="ID"
      name={['shipping', 'id']}>
      <Input />
    </Form.Item>

    <Form.Item
      label="Departamento"
      name={['shipping', 'departmentId']}
      rules={[{ required: true, message: '' }]}>
      <Select
        onChange={handleChangeDepartment}
        showSearch
        optionFilterProp="label"
        disabled={enableDpto}
        loading={loadingGeo1}
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={locationsGeolevel1?.filter(f => f.flagContraentrega).map(e => ({
          label: e.name,
          value: e.id,
        }))}
      />
    </Form.Item>

    <Form.Item
      label="Provincia"
      name={['shipping', 'provinceId']}
      rules={[{ required: true, message: '' }]}>
      <Select
        onChange={handleChangeProvince}
        showSearch
        optionFilterProp="label"
        disabled={disabled}
        loading={loadingGeo2}
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={locationsGeolevel2?.filter(f => f.flagContraentrega).map(e => ({
          label: e.name,
          value: e.id,
        }))}
      />
    </Form.Item>

    <Form.Item
      label="Distrito"
      name={['shipping', 'districtId']}
      rules={[{ required: true, message: '' }]}>
      <Select
        showSearch
        optionFilterProp="label"
        disabled={disabled}
        loading={loadingGeo3}
        filterOption={(input, option) =>
          (option?.label as string).toLowerCase().includes(input.toLowerCase())
        }
        options={locationsGeolevel3?.filter(f => f.flagContraentrega).map(e => ({
          label: e.name,
          value: e.id,
        }))}
      />
    </Form.Item>

    { orderDetails && Array.isArray(orderDetails) 
      && orderDetails.filter(e => e.action !== 'delete').length > 0 ? 
    
      <>

        <Form.Item label="F. despacho"
          name={['shipping', 'dispatchDate']}
          rules={[{ required: !isDate, message: 'Seleccionar una fecha de despacho' }]}
        >
          <DatePicker
            disabled={flagSchedule() ? flagSchedule() : isDate}
            inputReadOnly={true}
            showToday={false}
            disabledDate={disabledDate}
            format={'DD/MM/YYYY'} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label="F. entrega aprox."
          name={['shipping', 'scheduleDate']}
          rules={[{ required: !isDate, message: '' }]}
        >
          <DatePicker disabled={flagDeliveryDate()}
            format={'DD/MM/YYYY'} style={{ width: '100%' }}/>
        </Form.Item>

        { authUser.user.company.countryCode === 'PER' ? <>

          <div className='right'>
            <b>Horario de entrega del pedido de 10AM a 5PM</b>
          </div>
        
          <Form.Item
            label='ALI LOGISTIC' style={{ fontWeight: 'bold' }}
          >
            Pedido ENTREGADO: {currencySymbol}{deliveryCost} - Pedido NO ENTREGADO: {currencySymbol}
            {deliveryCost / 2 + 0.75 + (provinceId === 4123 || provinceId === 4432 ? 0.75 : 0)}
          </Form.Item>
          
        </> : authUser.user.company.countryCode === 'BOL' ? <>

          <Form.Item
            label='ALIBOLIVIA LOGISTIC' style={{ fontWeight: 'bold' }}>
            ENVÍO: {currencySymbol}{deliveryCost}  DEVOLUCIÓN: {currencySymbol}{deliveryCost / 2}
          </Form.Item>
        
        </> : null }

      </> : null 
    
    }

    <Form.Item
      label="Dirección 1"
      name={['shipping', 'address1']} rules={[{ required: true, message: '' }]}>
      <Input disabled={companyUser == 2 ? isField : disabled}/>
    </Form.Item>

    <Form.Item
      label="Dirección 2"
      name={['shipping', 'address2']} >
      <Input disabled={disabled}/>
    </Form.Item>

    <Form.Item
      label="Referencia"
      name={['shipping', 'reference']}
      rules={[{ required: requiredByCountry ? (companyUser == 2 ? !isField : false) : false, message: '' }]}>
      <Input disabled={companyUser == 2 ? isField : disabled}/>
    </Form.Item>

    <Form.Item
      label="GPS"
      name={['shipping', 'gps']}
      rules={[{
        message: '',
        required: requiredByCountry ? (companyUser == 2 ? !isField : false) : false,
        pattern: /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/
      }]}>
      <Input
        placeholder='Latitud,Longitud'
        disabled={companyUser == 2 ? isField : disabled}
      />
    </Form.Item>

    { !isField || !disabled ? <>
      <Form.Item
        label='GPS'>
        Solicítalo al cliente por WhatsApp o 
        <a target='_blank' rel='noreferrer' href="https://bit.ly/3wqggn9"> 
          {' '}ver TUTORIAL</a>
      </Form.Item>
    </> : null }

    <Form.Item
      hidden
      name={['shipping', 'department']}>
      <Input disabled={disabled}/>
    </Form.Item>

    <Form.Item
      hidden
      name={['shipping', 'dptoJson']}>
      <Input disabled={disabled}/>
    </Form.Item>

    <Form.Item
      hidden
      name={['shipping', 'district']}>
      <Input disabled={disabled}/>
    </Form.Item>

    <Form.Item
      hidden
      name={['shipping', 'province']}>
      <Input disabled={disabled}/>
    </Form.Item>

  </>
}

export default OrderShippingPart
