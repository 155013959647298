import { Button, Divider, Form, Input, Spin } from 'antd'
import React, { useEffect } from 'react'
import { useDrawer } from '../../../../../context/DrawerContext'
import { IUserEditPasswordRequest } from '../../../../../services/user/user.interface'
import { useUserEditPassword, useUserStore } from '../../../../../services/user/user.service'

const UserEditPasswordContainer = () => {
  const [form] = Form.useForm<IUserEditPasswordRequest>()
  const { userEditPassword, loading, response } = useUserEditPassword()
  const { closeDrawer } = useDrawer()
  const { userSelected } = useUserStore()

  const onSubmit = (data: IUserEditPasswordRequest) => {
    if (userSelected?.id) userEditPassword(userSelected?.id, data)
  }

  useEffect(() => {
    if (response) {
      closeDrawer()
    }
  }, [response])

  return (
    <>
      <Spin spinning={loading}>
        <Form 
          form={form} 
          name='UserFormEditPassword'
          onFinish={onSubmit}
        >
          <Form.Item
            label='Password'
            name={['password']}
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <Input />
          </Form.Item>

          <Divider />

          <Button type='primary' htmlType="submit">
            Guardar
          </Button>
        </Form>
      </Spin>
    </>
  )
}

export default UserEditPasswordContainer
