import React from 'react'
import { Table } from 'antd'
import { IWarehouseResponse } from '../../../../../services/warehouse-list/warehouse-list.interface'
import { ColumnsType } from 'antd/lib/table'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useWarehouseStore } from '../../../../../services/warehouse-create/warehouse.store'
import useMeasureY from '../../../../../hooks/use-measurey'

interface IWarehousesListTableProps {
  warehouses: IWarehouseResponse[]
  loading: boolean
}

const WarehousesListTable: React.FC<IWarehousesListTableProps> = ({ warehouses, loading }) => {

  const { openDrawer } = useDrawer()
  const { setWarehouseSelected } = useWarehouseStore()
  const { windowSize } = useMeasureY()
  
  const handleSelected = (data: IWarehouseResponse) => {
    setWarehouseSelected(data)
    openDrawer('WAREHOUSE_EDIT', 'Editar almacén')
  }

  const columns: ColumnsType<IWarehouseResponse> = [
    {
      title: '',
      width: '2.5%',
    },
    {
      title: 'Almacén',
      dataIndex: 'name',
      align: 'center',
      width: '25%',
      render: (_, warehouse) => <>{warehouse.name}</>
    },
    {
      title: 'Soporte almacén',
      dataIndex: 'phone',
      align: 'center',
      width: '15%',
      render: (_, warehouse) => <>{warehouse.phone || '-'}</>
    },
    {
      title: 'Contraentrega en',
      dataIndex: 'reference',
      align: 'center',
      width: '30%',
      render: (_, warehouse) => <>{warehouse.reference || '-'}</>
    },
    {
      title: 'Editar', dataIndex: 'id', width: '10%', align: 'center',
      render: (_, user) => <a onClick={() => handleSelected(user)}>Editar</a>,
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={warehouses}
      columns={columns}
      rowClassName="editable-row"
      loading={loading}
      pagination={{
        pageSize: 20, total: warehouses.length
      }}
      size='small'
      scroll={{
        y: windowSize.height - 200,
      }}
    />
  )
}

export default WarehousesListTable
