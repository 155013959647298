import React, { FC } from 'react'
import { IPreOrderResumen } from '../../../../../services/pre-order/pre-order.interface'
import './PreOrderResumen.css'
import { Badge } from 'antd'

interface IPreOrderResumenProps {
  resumen: IPreOrderResumen
}

const PreOrderResumen: FC<IPreOrderResumenProps> = ({ resumen }) => {
  return (
    <>
      <div className='PreOrderResumen'>
        <div>
          <Badge count={resumen?.totalQueue} style={{ backgroundColor: '#ff0066' }} />
          <span className='PreOrderResumen__label'>TOTAL COLA</span>
        </div>
        <div>
          |
        </div>

        {resumen?.totalAssigned ? (
          <div>
            <Badge count={resumen?.totalAssigned} style={{ backgroundColor: '#009688' }} />
            <span className='PreOrderResumen__label'>ASSIGNED</span>
          </div>
        ) : null}

        {resumen?.totalInWorking ? (
          <div>
            <Badge count={resumen?.totalInWorking} style={{ backgroundColor: '#ff9800' }} />
            <span className='PreOrderResumen__label'>WORKING</span>
          </div>
        ) : null}

        {resumen?.totalPreOrderUnique ? (
          <div>
            <Badge count={resumen?.totalPreOrderUnique} style={{ backgroundColor: '#888' }} />
            <span className='PreOrderResumen__label'>UNICOS</span>
          </div>
        ) : null}

      </div>
    </>
  )
}

export default PreOrderResumen
