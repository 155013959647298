import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBm3hgNW4ViimO_eGfQ2TPLAQJ0pqgBltg',
  authDomain: 'wanklik-platform.firebaseapp.com',
  projectId: 'wanklik-platform',
  storageBucket: 'wanklik-platform.appspot.com',
  messagingSenderId: '224232392740',
  appId: '1:224232392740:web:3cb4508e1732853205f0b1'
}

const app = initializeApp(firebaseConfig)
const dbFirebase = getDatabase(app)
const storage = getStorage(app)

export {
  dbFirebase,
  storage,
}
