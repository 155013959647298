import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, Form, Input, Select, Radio } from 'antd'
import { IPreOrderItemResponse } from '../../../../../services/pre-order/pre-order.interface'
import { IPreOrderUpdateSchema } from '../../../../../services/pre-order/pre-order.schema'
import {
  PreOrderAgencyPart,
  PreOrderCustomerPart,
  PreOrderProductPart,
  PreOrderShippingPart,
} from './parts'
import { IFieldData } from '../../../../../config/data.interface'
import { useProductStore } from '../../../../../services/product/product.store'
import {
  ORDER_STATUS_LIST_CONFIRMED,
  ORDER_STATUS_LIST_NOT_AVAILABLE,
  ORDER_STATUS_LIST_WORKING,
  CALL_STATUS_LIST,
} from '../../../../../services/pre-order/pre-order.params'
import TextArea from 'antd/lib/input/TextArea'
import { useEcomPremiun, usePreOrderEdit } from '../../../../../services/pre-order/pre-order.edit'

const { Option, OptGroup } = Select

interface IPreOrderEditFormProps {
  initialValues: IPreOrderItemResponse | null;
  onSubmit: (data: IPreOrderUpdateSchema) => void;
}

const PreOrderEditForm: FC<IPreOrderEditFormProps> = ({ initialValues, onSubmit }) => {
  const [form] = Form.useForm<IPreOrderUpdateSchema>()
  const [defaultValues, setDefaultValues] = useState<IFieldData[]>()
  const { setProductEmpty } = useProductStore()
  const { userCompany } = usePreOrderEdit()
  const [disabled, setDisabled] = useState<boolean>(false)
  const [required, setRequired] = useState<number>(0)
  const status = Form.useWatch('orderStatus', form)
  const provinceId = Form.useWatch(['shipping', 'provinceId'], form)
  const departmentId = Form.useWatch(['shipping', 'departmentId'], form)
  const districtId = Form.useWatch(['shipping', 'districtId'], form)
  const enableDpto = Form.useWatch(['enableDpto'], form)
  const { flagPremium } = useEcomPremiun()
 
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        stage: initialValues.stage,
        orderStatus: initialValues.order?.orderStatus || 'CONFIRMED',
        note: initialValues.order?.note,
        shippingCost: initialValues.order?.shippingCost,
        customer: {
          name: initialValues.firstName,
          lastName: initialValues.lastName,
          phone: initialValues.phone.replace('+', ''),
        },
        shipping: {
          address1: initialValues.address1,
          address2: initialValues.address2,
          department: initialValues.order?.shipping.department,
          departmentId: Number.parseInt(String(initialValues.order?.shipping.departmentId)),
          province: initialValues.order?.shipping.province,
          provinceId: initialValues.order?.shipping.provinceId,
          district: initialValues.order?.shipping.district,
          districtId: initialValues.order?.shipping.districtId,
          shippingByAgency: false
        }
      })
    }
  }, [initialValues])

  useEffect(() => {
    if (userCompany === 2) {
      setDisabled(false)
    } else if (userCompany === 1) {
      setDisabled(true)
    } else if (userCompany === 3) {
      setDisabled(false)
    }
    setProductEmpty()
    setDefaultValues([
      { name: ['shipping', 'shippingByAgency'], value: false },
      { name: ['prePayment'], value: false },
    ])
  }, [])

  useEffect(() => {
    if (status === CALL_STATUS_LIST.FOLLOW || status === CALL_STATUS_LIST.CALL_LATER) {
      setRequired(1)
      setDisabled(false)
    } else if (status === CALL_STATUS_LIST.CONFIRMED) {
      if (userCompany === 2) {
        setRequired(0)
        setDisabled(false)
      } else if (userCompany === 1) {
        setDisabled(true)
      } else if (userCompany === 3) {
        setDisabled(false)
      }
    } else {
      setRequired(2)
      setDisabled(false)
    }
  }, [status])

  return <>
    <Form
      layout="horizontal"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17 }}
      form={form}
      name="order"
      labelWrap
      fields={defaultValues}
      onFinish={onSubmit}
    >

      <Form.Item name="orderStatus" label="Estado" rules={[{ required: true, message: '' }]}>
        <Select
          placeholder="Seleccione una opción"
          allowClear
        >
          {ORDER_STATUS_LIST_CONFIRMED.map(status => (
            <Option key={status.value} value={status.value}>{status.label}</Option>
          ))}
          <OptGroup label="WORKING">
            {ORDER_STATUS_LIST_WORKING.map(status => (
              <Option key={status.value} value={status.value}>{status.label}</Option>
            ))}
          </OptGroup>
          <OptGroup label="ORDEN">
            {ORDER_STATUS_LIST_NOT_AVAILABLE.map(status => (
              <Option key={status.value} value={status.value}>{status.label}</Option>
            ))}
          </OptGroup>
        </Select>
      </Form.Item>

      <Form.Item label="Nota" name="note">
        <TextArea rows={2} disabled={disabled}/>
      </Form.Item>

      <Form.Item label="Costo de entrega" name="shippingCost" hidden={!disabled}>
        <Input disabled={disabled}/>
      </Form.Item>

      <Form.Item name={['shipping', 'shippingByAgency']} 
        label="Tipo de pedido"
        hidden={ flagPremium === 2 ? false : true }
      >
        <Radio.Group
          options={[
            { label: 'Contraentrega', value: false },
            { label: 'Agencia', value: true },
          ]}
          optionType="button"
          buttonStyle="solid"
          disabled={disabled === false ? enableDpto : disabled}
        />
      </Form.Item>

      <Divider />

      <PreOrderCustomerPart
        disabled={disabled}
      />

      <Divider />

      <PreOrderShippingPart form={form} disabled={disabled} required={required} />

      { departmentId && provinceId && districtId ? <>

        <Divider />
        <PreOrderProductPart
          form={form}
          disabled={disabled}
        />

      </> : null}

      <PreOrderAgencyPart form={form} disabled={disabled} />

      <Divider />

      <Form.Item hidden={disabled}>
        <Button type="primary" htmlType="submit" size="large">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  </>
}

export default PreOrderEditForm
