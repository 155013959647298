import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { preOrderActions } from '../../state/pre-order/pre-order.actions'
import { IPreOrderState } from '../../state/pre-order/pre-order.reducer'
import { IPreOrderItemResponse } from './pre-order.interface'

export const usePreOrderStore = () => {
  const dispatch = useDispatch()
  const { preOrderSelected } = useSelector((state: IRootState) => state.preOrder as IPreOrderState)

  const setPreOrderSelected = (preOrder: IPreOrderItemResponse) => {
    dispatch(preOrderActions.setPreOrderSelected(preOrder))
  }

  return {
    setPreOrderSelected,
    preOrderSelected,
  }
}
