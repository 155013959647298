import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { mapToReturnCreateRequest, mapToReturnUpdateRequest } from './return-create.mapper'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'
import { ICreateReturnResponse } from './return-create.interface'
import { ICreateReturnSchema } from './return-create.schema'

export const useReturnCreate = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ICreateReturnResponse>()

  const saveReturn = (data: ICreateReturnSchema) => {
    
    if (data.id === null) {
      const request = mapToReturnCreateRequest(authUser, data)

      datadogLogs.logger.info('RETURN_CREATE', { action: 'RETURN_CREATE', request: request,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })

      execute({
        method: 'POST',
        url: '/return',
        data: request,
      })
    } else {

      const request = mapToReturnUpdateRequest(authUser, data)

      datadogLogs.logger.info('RETURN_UPDATE', { action: 'RETURN_UPDATE', request: request,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
      
      execute({
        method: 'PATCH',
        url: `/return/${data.id}`,
        data: request,
      })
    }
    
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('RETURN:POST', { action: 'RETURN:POST',
        response: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])

  return {
    saveReturn,
    loading,
    returnCreated: response
  }
}
