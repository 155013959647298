import { useEffect, useState } from 'react'
import { useAxios, useFirebase } from '../../hooks'
import { objectToArray } from '../../utils/object-to-array'
import { useAuthStore } from '../auth/auth.service'
import { IPreOrderItemResponse, IPreOrderImportResponse, IPreOrderResumen } from './pre-order.interface'
import { IPreOrderUpdateSchema } from './pre-order.schema'
import {
  IS_NOT_AVAILABLE_STATUS,
  IS_SUCCESS_STATUS,
  IS_WORKING_STATUS,
  STAGE_STATUS,
} from './pre-order.params'
import { useCompanyStore } from '../company-create/company-create.service'

export const usePreOrderImport = () => {
  const { execute, response: resultImport, loading } = useAxios<IPreOrderImportResponse>()
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()

  const preOrderImport = () => {
    execute({
      method: 'POST',
      url: `/pre-order/import/${companyGlobal?.id || authUser.user.company.id}`,
    })
  }

  return {
    preOrderImport,
    resultImport,
    loading,
  }
}

export const usePreOrders = () => {
  const { authUser } = useAuthStore()
  const [preOrdersAll, setPreOrdersAll] = useState<IPreOrderItemResponse[]>([])
  const [preOrdersGroup, setPreOrdersGroup] = useState<IPreOrderItemResponse[]>([])
  const [preOrdersByUser, setPreOrdersByUser] = useState<IPreOrderItemResponse[]>([])
  const { getFromDatabase, response, loading } = useFirebase()
  const [resumen, setResumen] = useState<IPreOrderResumen>()

  useEffect(() => {
    const listAll: IPreOrderItemResponse[] = objectToArray<IPreOrderItemResponse>(response)
    const listByUser = authUser.user.role.permissions.preorder_all_users ?
      listAll :
      listAll?.filter(e => e.userId === authUser.user.id)

    const tempUnique = new Set([...listByUser.map(po => po.phone)])

    const totalQueue = listAll?.filter(e =>
      [STAGE_STATUS.NEW, STAGE_STATUS.UNASSIGNED].includes(e.stage as string)
    )

    const totalAssigned = listByUser?.filter(e =>
      [STAGE_STATUS.ASSIGNED].includes(e.stage as string)
    )

    const totalInWorking = listByUser?.filter(e =>
      [STAGE_STATUS.WORKING].includes(e.stage as string)
    )

    setPreOrdersAll(listAll)
    setPreOrdersGroup(detectPreOrdersDuplicates(listByUser))
    setPreOrdersByUser(listByUser)
    setResumen({
      totalQueue: totalQueue.length,
      totalAssigned: totalAssigned.length,
      totalInWorking: totalInWorking.length,
      totalPreOrderUnique: tempUnique.size,
    })
  }, [response])

  const getPreOrders = (companyId?: number | null) => {
    const myCompanyId = companyId || authUser.user.company.id

    getFromDatabase(`preOrders/company_${myCompanyId}`)
  }

  return {
    getPreOrders,
    preOrdersAll,
    preOrdersGroup,
    preOrdersByUser,
    resumen,
    loading,
  }
}

export const usePreOrderUpdate = () => {
  const { execute, response: orderUpdated, loading } = useAxios<IPreOrderImportResponse>()

  const preOrderUpdateStatus = (orderNumber: string, data: IPreOrderUpdateSchema) => {
    let stage = ''

    if (IS_WORKING_STATUS.includes(data?.orderStatus as string)) {
      stage = STAGE_STATUS.WORKING
    } else if (IS_NOT_AVAILABLE_STATUS.includes(data?.orderStatus as string)) {
      stage = STAGE_STATUS.NOT_AVAILABLE
    } else if (IS_SUCCESS_STATUS.includes(data?.orderStatus as string)) {
      stage = STAGE_STATUS.CONFIRMED
    }

    execute({
      method: 'PATCH',
      url: `/pre-order/update/${orderNumber}`,
      data: {
        stage,
        order: data,
      },
    })
  }

  return {
    preOrderUpdateStatus,
    orderUpdated,
    loading,
  }
}

const detectPreOrdersDuplicates = (preOrders: IPreOrderItemResponse[]): IPreOrderItemResponse[] => {
  const preOrdersUnique: IPreOrderItemResponse[] = []

  preOrders.forEach(po => {
    const existIndex = preOrdersUnique.findIndex(e => e.phone === po.phone)

    if (existIndex === -1) {
      preOrdersUnique.push(po)

      return
    }

    if (!preOrdersUnique[existIndex].children?.length) {
      preOrdersUnique[existIndex].children = []
    }
    preOrdersUnique[existIndex].children?.push(po)
  })

  return preOrdersUnique
}
