import React from 'react'
import { DashboardContainer } from './containers'

const DashboardView = () => {
  return <>
    <DashboardContainer/>
  </>
}

export default DashboardView
