/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { Space, Spin } from 'antd'
import Title from 'antd/lib/typography/Title'
import { formatDate } from '../../../../../utils/date'
import { OrderShopifyAgencyForm } from '../../forms'
import { IOrderUpdateSchema } from '../../../../../services/order-update/order-update.schema'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useOrderAgencyCreateOrUpdate } from '../../../../../services/order-agency-update/order-agency-update.service'
import { useOrderShopifyStore } from '../../../../../services/order-shopify/order-shopify.store'
import { IS_NOT_AVAILABLE_STATUS, IS_SUCCESS_STATUS } from '../../../../../services/pre-order/pre-order.params'
import { useOrderCreate } from '../../../../../services/order-create/order.service'
import { EnvironmentOutlined, PhoneOutlined, TagOutlined, UserOutlined } from '@ant-design/icons'

const OrderShopifyAgencyContainer = () => {
  const { orderAgencyCreateOrUpdate, orderUpdated, loading } = useOrderAgencyCreateOrUpdate()
  const { closeDrawer } = useDrawer()
  const { orderShopifySelected } = useOrderShopifyStore()
  const {
    deleteOrderShopify,
  } = useOrderCreate()

  const handleSubmit = (data: IOrderUpdateSchema) => {
    if (
      IS_NOT_AVAILABLE_STATUS.includes(data.callStatus as string) ||
        IS_SUCCESS_STATUS.includes(data.callStatus as string)
    ) {
      orderAgencyCreateOrUpdate(data)
    }
  }

  useEffect(() => {
    if (orderUpdated && orderShopifySelected) {
      deleteOrderShopify(orderShopifySelected)
      closeDrawer()
    }
  }, [orderUpdated])

  return <>
    {orderShopifySelected ? (
      <>
        <Space align="baseline" style={{ marginBottom: '.5rem' }}>
          <Title level={5}>#{orderShopifySelected.orderNumber}</Title>
          <span>{formatDate(orderShopifySelected.createdAt)}</span>
        </Space>

        <div style={{ marginBottom: '0.35rem' }}>
          <Space>
            <div><TagOutlined /></div>
            <div>
              <div>{orderShopifySelected.items.map(i =>
                <span key={i.productShopifyId}>{i.quantity} {i.name}<br/></span>)}
              </div>
            </div>
          </Space>
        </div>

        <div style={{ marginBottom: '0.35rem' }}>
          <Space>
            <div><UserOutlined /></div>
            <div>
              {orderShopifySelected.customer.firstName} {orderShopifySelected.customer.lastName}
            </div>
          </Space>
        </div>

        <div style={{ marginBottom: '0.35rem' }}>
          <Space>
            <div><PhoneOutlined /></div>
            <div>
              {orderShopifySelected.shipping.phone}
            </div>
          </Space>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <Space>
            <div><EnvironmentOutlined /></div>
            <div>
              {orderShopifySelected.shipping.address} - {orderShopifySelected.shipping.city}
               - {orderShopifySelected.shipping.province}
            </div>
          </Space>
        </div>
      </>
    ) : (
      <Space align="baseline">
        <Title level={4}>Nueva orden</Title>
      </Space>
    )}

    <Spin spinning={loading}>
      <OrderShopifyAgencyForm
        initialValues={orderShopifySelected}
        onSubmit={handleSubmit}
      />
    </Spin>
  </>
}

export default OrderShopifyAgencyContainer
