import { IPreOrderItemResponse } from './pre-order.interface'

/* eslint-disable max-len */
export const STAGE_STATUS = {
  NEW: 'NEW',
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  WORKING: 'WORKING',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  CONFIRMED: 'CONFIRMED',
}

export const CALL_STATUS_LIST = {
  CONFIRMED: 'CONFIRMED',
  FOLLOW: 'FOLLOW',
  IMPORTED: 'IMPORTED',
  CALL_LATER: 'CALL_LATER',
  ANNULLED: 'ANNULLED',
  NOT_RESPOND: 'NOT_RESPOND',
  DUPLICATE: 'DUPLICATE',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  NO_COVERAGE: 'NO_COVERAGE',
  FAKE: 'FAKE',
  TESTING: 'TESTING',
  CONTACTED: 'CONTACTED',
}

export const ORDER_STATUS_LIST_CONFIRMED = [
  { value: CALL_STATUS_LIST.CONFIRMED, label: 'CONFIRMADO' },
]

export const ORDER_STATUS_LIST_WORKING = [
  { value: CALL_STATUS_LIST.FOLLOW, label: 'SEGUIMIENTO' },
  { value: CALL_STATUS_LIST.CALL_LATER, label: 'LLAMAR DESPUES' },
]

export const ORDER_STATUS_LIST_NOT_AVAILABLE = [
  { value: CALL_STATUS_LIST.IMPORTED, label: 'IMPORTADO' },
  { value: CALL_STATUS_LIST.ANNULLED, label: 'ANULADO' },
  { value: CALL_STATUS_LIST.NOT_RESPOND, label: 'NO CONTESTA' },
  { value: CALL_STATUS_LIST.CONTACTED, label: 'CONTACTADO' },
  { value: CALL_STATUS_LIST.DUPLICATE, label: 'REPETIDO' },
  { value: CALL_STATUS_LIST.OUT_OF_STOCK, label: 'SIN STOCK' },
  { value: CALL_STATUS_LIST.NO_COVERAGE, label: 'SIN COBERTURA' },
  { value: CALL_STATUS_LIST.FAKE, label: 'FAKE' },
  { value: CALL_STATUS_LIST.TESTING, label: 'TESTEO' }
]

export const IS_WORKING_STATUS = [
  CALL_STATUS_LIST.FOLLOW,
  CALL_STATUS_LIST.CALL_LATER,
]

export const IS_NOT_AVAILABLE_STATUS = [
  CALL_STATUS_LIST.ANNULLED,
  CALL_STATUS_LIST.NOT_RESPOND,
  CALL_STATUS_LIST.CONTACTED,
  CALL_STATUS_LIST.DUPLICATE,
  CALL_STATUS_LIST.OUT_OF_STOCK,
  CALL_STATUS_LIST.NO_COVERAGE,
  CALL_STATUS_LIST.FAKE,
  CALL_STATUS_LIST.TESTING,
]

export const IS_SUCCESS_STATUS = [
  CALL_STATUS_LIST.CONFIRMED,
]

export const CALL_STATUS_LABEL: { [key: string]: { label: string, color: string } } = {
  CONFIRMED: {
    label: 'CONFIRMADO',
    color: '#009688'
  },
  FOLLOW: {
    label: 'SEGUIMIENTO',
    color: '#FF5722'
  },
  CALL_LATER: {
    label: 'LLAMAR DESPUES',
    color: '#03A9F4'
  },
  IMPORTED: {
    label: 'IMPORTADO',
    color: '#ff0066'
  },
  ANNULLED: {
    label: 'ANULADO',
    color: '#ff0066'
  },
  NOT_RESPOND: {
    label: 'NO CONTESTA',
    color: '#ff0066'
  },
  CONTACTED: {
    label: 'CONTACTADO',
    color: '#ff0066'
  },
  DUPLICATE: {
    label: 'REPETIDO',
    color: '#ff0066'
  },
  OUT_OF_STOCK: {
    label: 'SIN STOCK',
    color: '#ff0066'
  },
  NO_COVERAGE: {
    label: 'SIN COBERTURA',
    color: '#ff0066'
  },
  FAKE: {
    label: 'FAKE',
    color: '#ff0066'
  },
  TESTING: {
    label: 'TESTING',
    color: '#ff0066'
  },
}


export const messagesContent = (preOrder: IPreOrderItemResponse) => {
  const follow = `${preOrder.firstName}, 👋 le saludamos del area de seguimiento de la empresa *ALIPERU*, estamos intentando comunicarnos pero no tenemos respuesta alguna por lo que agradeceremos nos confirme la programación del pedido ✅ o procederemos a anularlo ❌

  *Codigo:* ${preOrder.orderNumber}
  *Productos:* ${preOrder.productName}
  *Dirección:* ${preOrder.address1} ${preOrder.address2}

  - *Mil gracias por su atención y que tenga un maravilloso día!!!*`

  return {
    follow,
  }
}
