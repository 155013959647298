/* eslint-disable max-len */
import React, { FC } from 'react'
import { Badge, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import useMeasureY from '../../../../../hooks/use-measurey'
import { OrdersTableLevel2 } from './parts'
//import { blue } from '@ant-design/colors'
import { IOrdersResponse } from '../../../../../services/order-list/order.interface'

interface IOrdersTableProps {
  data: IOrdersResponse[] | undefined
  loading: boolean
  handlePagination: (page: number) => void
  count: number
  currentPage: number
}

const OrdersTable: FC<IOrdersTableProps> = ({ data, loading, handlePagination, count, currentPage }) => {
  const { windowSize } = useMeasureY()

  const columnsLevel1: ColumnsType<IOrdersResponse> = [
    {
      title: 'Fecha entrega', dataIndex: 'date', width: 200,
    },
    {
      title: 'Pedidos', dataIndex: 'totalOrder', width: 100, align: 'right',
      render: (_, order) => <>
        <Badge count={order.totalOrder} style={{ backgroundColor: '#888' }} overflowCount={999}/>
      </>,
      sorter: (a, b) => a.totalOrder - b.totalOrder,
    },
    {
      title: 'Total', dataIndex: 'totalAmount', align: 'left',
      render: (_, order) => <>
        <b>{order.currency?.symbol} {order.totalAmount.toFixed()}</b>
        {order.totalPayment > 0 ? ` - ${order.currency?.symbol} ${order.totalPayment.toFixed()}` : ''}
        {order.totalCash > 0 ? ` - ${order.currency?.symbol} ${order.totalCash.toFixed()}` : ''}
        {/*<Progress
          style={{ marginLeft: '.5rem', width: '50px' }}
          percent={order.ratioAmount}
          size="small"
          showInfo={false}
          strokeColor={blue[5]}
        />*/}
      </>,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
  ]

  return (
    <>
      <Table
        rowKey="date"
        columns={columnsLevel1}
        dataSource={data}
        pagination={{ pageSize: 800, current: currentPage, total: count, simple: true, onChange(page, _) {
          handlePagination(page)
        }, }}
        scroll={{
          y: windowSize.height - 200,
        }}
        size="small"
        loading={loading}
        expandable={{
          expandedRowRender: OrdersTableLevel2,
          rowExpandable: record => !!record.orders?.length,
        }}
      />
    </>
  )
}

export default OrdersTable
