import { useAuthStore } from '../auth/auth.service'

export const useUserCreatedEdit = () => {
  const { roleUser } = useAuthStore()
  let userId = 0

  if (roleUser?.name === 'ADMIN_STORE') {
    userId = 1
  } else if (roleUser?.name === 'ADMIN') {
    userId = 2
  } else if (roleUser?.name === 'SUP_SELLER') {
    userId = 3
  } else if (roleUser?.name === 'SUP_MOTORIZED') {
    userId = 4
  }
  
  return { userId }
}
