import React, { useEffect } from 'react'
import { Spin } from 'antd'
import { useCreateUser } from '../../../../../services/user-create/user-create.service'
import { UserEditForm } from '../../forms'
import { useUserStore } from '../../../../../services/user/user.service'
import { useDrawer } from '../../../../../context/DrawerContext'
import { IUserCreateSchema } from '../../../../../services/user-create/user-create.schema'

const UserEditContainer = () => {
  const { createUser, userCreated, loading } = useCreateUser()
  const { setUserUpdated } = useUserStore()
  const { closeDrawer } = useDrawer()

  const handleSubmit = (data: IUserCreateSchema) => {
    createUser(data)
  }

  useEffect(() => {
    if (userCreated) {
      closeDrawer()
      setUserUpdated(userCreated)
    }
  }, [userCreated])

  return (
    <>
      <Spin spinning={loading}>
        <UserEditForm
          onSubmit={handleSubmit}
        />
      </Spin>
    </>
  )
}

export default UserEditContainer
