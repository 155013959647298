/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './order.actions'
import { IOrderUpdateResponse } from '../../services/order-update/order-update.interface'
import { IOrderItemResponse } from '../../services/order-list/order.interface'
import { IOrderDeliveryItemResponse } from '../../services/order-motorized/order-motorized.interface'
import { IOrderDeliveryCreateResponse } from '../../services/order-delivery-create/order-delivery-create.interface'
import { IUpdateEvidenceResponse } from '../../services/evidence-update/evidence-update.interface'

export interface IOrderState {
  orderSelected: IOrderItemResponse | null
  orderUpdated: IOrderUpdateResponse | null
  orderDeliveryChecked: IOrderDeliveryItemResponse[]
  orderDeliveryUpdated: IOrderDeliveryCreateResponse | null
  orderDeliveries: IOrderDeliveryItemResponse | null
  orderEvidence: IUpdateEvidenceResponse | null
}

const initialState: IOrderState = {
  orderSelected: null,
  orderUpdated: null,
  orderDeliveryChecked: [],
  orderDeliveryUpdated: null,
  orderDeliveries: null,
  orderEvidence: null,
}

export const orderReducer = (
  state: IOrderState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_ORDER_SELECTED:
    return {
      ...state,
      orderSelected: action.payload,
    }
  case actionTypes.SET_ORDER_UPDATED:
    return {
      ...state,
      orderUpdated: action.payload,
    }
  case actionTypes.SET_ORDER_DELIVERY_CHECKED:
    return {
      ...state,
      orderDeliveryChecked: action.payload,
    }
  case actionTypes.SET_ORDER_DELIVERY_UPDATED:
    return {
      ...state,
      orderDeliveryUpdated: action.payload,
    }
  case actionTypes.SET_ORDER_DELIVERIES: 
    return {
      ...state,
      orderDeliveries: action.payload,
    }
  case actionTypes.SET_ORDER_EVIDENCE:
    return {
      ...state,
      orderEvidence: action.payload,
    }
  default:
    return state
  }
}
