import React, { FC, useEffect } from 'react'
import { Button, Form, Input, List, Upload, message } from 'antd'
import { CameraOutlined, SendOutlined } from '@ant-design/icons'
import { IChatMessageSchema } from '../../../../../services/chat-order/chat-order.schema'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useChatMessages } from '../../../../../services/chat-order/chat-order.service'
import { formatDateUTCHour } from '../../../../../utils/date'
import { ImagePreview } from '../../../../DispatchModule/DispatchGuideMainView/components'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../../libs/firebase'

interface IChatEditFormProps {
  orderNumber?: string,
}
  
const ChatForm: FC<IChatEditFormProps> = ({
  orderNumber
}) => {

  const [form] = Form.useForm<IChatMessageSchema>()
  const { authUser } = useAuthStore()
  const messageChat = Form.useWatch(['message'], form)
  const { getChatMessage, loading, messageAll, updateChatMessage } = useChatMessages()

  useEffect(() => {

    if (orderNumber) getChatMessage(orderNumber)

    form.setFieldsValue({
      message: '',
      userId: authUser.user.id,
      userName: authUser.user.email,
      currentMillis: `${Date.now()}`
    })
    
  }, [])

  const uploadImage = (file: any) => {

    const isPNG = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'application/pdf' ||
      file.type === 'image/jpeg'

    if (!isPNG) {
      message.error(`${file.name} no es archivo válido solo son (.png, .jpg, .jpeg, .pdf)`)

      return
    }

    if (!file) {
      message.success('Debe seleccionar un archivo')

      return
    }

    const storageRef = ref(storage, `/chat/${orderNumber}/${file?.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        //none
      }, (_) => {
        //console.log('error', error)
      }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {

          const typeDoc = file ?
            (file?.type as string).split('/') : ''

          if (orderNumber) {
            const data: IChatMessageSchema = {
              message: '',
              urlImage: url,
              orderNumber: orderNumber,
              currentMillis: `${Date.now()}`,
              userId: authUser.user.id,
              userName: authUser.user.email,
              createdAt: '',
              active: true,
              type: typeDoc[1]
            }
      
            updateChatMessage(orderNumber, data)

            form.setFieldValue(['message'], '')

          }

        })
      }
    )

    return false
  }

  const sendMessage = () => {

    if (orderNumber) {

      if (messageChat) {
        const data: IChatMessageSchema = {
          message: messageChat,
          orderNumber: orderNumber,
          currentMillis: `${Date.now()}`,
          userId: authUser.user.id,
          userName: authUser.user.email,
          createdAt: '',
          active: true,
          type: 'text'
        }
  
        updateChatMessage(orderNumber, data)

        form.setFieldValue(['message'], '')

      } else {
        form.validateFields(['message'])
      }

    }
  }
  
  return <div style={{ height: '100%', margin: 0, overflow: 'hidden' }}>
    <div style={{ position: 'relative', minHeight: '100vh' }}>

      <List
        size="small"
        header={null}
        footer={null}
        loading={loading}
        dataSource={messageAll}
        renderItem={item => <List.Item>
          {item.userId === authUser.user.id ? <>
            <div style={{ width: '100%' }}>
              { item.urlImage ? <div style={{ textAlign: 'right' }}>
                { item.type && item.type === 'png' || item.type && item.type === 'jpg' || 
                  item.type && item.type === 'jpeg' ? <>
                    <ImagePreview url={item.urlImage}></ImagePreview>
                  </> : <>
                    <a 
                      href={item.urlImage} 
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'underline' }}
                    >Ver link</a>
                  </>}
              </div> : <>
                <h5 style={{ textAlign: 'right', marginBottom: '-16px' }}>{item.message}</h5><br/>
              </>}
              <h6 style={{ textAlign: 'right', marginTop: '0px' }}>{formatDateUTCHour(item.createdAt)}</h6>
            </div>
          </> : <>
            <div style={{ width: '100%' }}>
              <h6 style={{ textAlign: 'left', marginBottom: '-20px' }}>{item.userName}</h6><br/>
              { item.urlImage ? <div style={{ textAlign: 'left' }}>
                { item.type && item.type === 'png' || item.type && item.type === 'jpg' || 
                  item.type && item.type === 'jpeg' ? <>
                    <ImagePreview url={item.urlImage}></ImagePreview>
                  </> : <>
                    <a 
                      href={item.urlImage} 
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'underline' }}
                    >Ver link</a>
                  </>}
              </div> : <>
                <h5 style={{ textAlign: 'left', marginBottom: '-16px' }}>{item.message}</h5><br/>
              </>}
              <h6 style={{ textAlign: 'left', marginTop: '0px' }}>{formatDateUTCHour(item.createdAt)}</h6>
            </div>
          </>}
        </List.Item>}
        style={{ height: '80vh', overflow: 'auto', marginBottom: '10px' }}
      />
      
      <div style={{ position: 'fixed', bottom: '20px', right: '10px', width: '30%' }}>
        <Form
          layout='inline'
          size='middle'
          name="orderChat"
          form={form}
          labelWrap
        >

          <Form.Item name={['userId']} hidden>
            <Input />
          </Form.Item>

          <Form.Item name={['userName']} hidden>
            <Input />
          </Form.Item>

          <Form.Item name={['currentMillis']} hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label=''
            name="message"
            style={{ flex: 1 }}
          >
            <Input placeholder='Escriba un mensaje' style={{ borderRadius: '8px', borderColor: '#ff9800' }}/>
          </Form.Item>

          <Upload 
            name='file'
            beforeUpload={uploadImage}
            maxCount={1}
            fileList={[]}
          >
            <Button type="primary" size='middle' shape='round' style={{ marginRight: '4px' }}>
              <CameraOutlined />
            </Button>
          </Upload>
          
          <Button type="primary" onClick={() => sendMessage()} size='middle' shape='round' >
            <SendOutlined />
          </Button>

        </ Form>

      </div>
    </div>
  </div>

}
  
export default ChatForm
