import { datadogLogs } from '@datadog/browser-logs'
import { useAxios } from '../../hooks'
import { ICatalogPrivate, ICatalogStore, ICompanySku } from './catalog.interface'
import { ICatalogSchema } from './catalog.schema'
import { useAuthStore } from '../auth/auth.service'
import { useEffect } from 'react'
import { useCompanyStore } from '../company-create/company-create.service'

export const useCatalogsByCompany = () => {
  const { execute, response, loading: loadingCatalogsStore } = useAxios<ICatalogStore[]>()
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()

  const getCatalogsByCompany = () => {
    execute({
      method: 'GET',
      url: '/catalog/company/all',
      params: {
        companyId: companyGlobal ? companyGlobal.id : authUser.user.company.id,
      },
    })
  }
  
  return {
    getCatalogsByCompany,
    loadingCatalogsStore,
    catalogs: response ? response : []
  }
}

export const useCatalogs = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading: loadingCatalogs } = useAxios<ICatalogPrivate[]>()

  const getCatalogs = () => {
    execute({
      method: 'GET',
      url: '/catalog/all',
      params: {
        companyId: authUser.user.role.name === 'ADMIN_STORE' ? authUser.user.company.id : ''
      }
    })
  }
  
  return {
    getCatalogs,
    loadingCatalogs,
    catalogs: response ? response : []
  }
}

export const useCatalogCreate = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ICatalogPrivate>()
  
  const createNewCatalog = (data: ICatalogSchema) => {
      
    if (data.id === null) {
      const request = {
        name: data.name,
        description: data.description,
        isPrivate: data.isPrivate,
        managingSupplierId: parseInt(`${data.managingSupplierId}`),
        companyIds: data.companyIds
      }
  
      datadogLogs.logger.info('CATALOG_CREATE', { action: 'CATALOG_CREATE', request: request,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
  
      execute({
        method: 'POST',
        url: '/catalog/create',
        data: request,
      })
    
    } else {
  
      const request = {
        name: data.name,
        description: data.description,
        isPrivate: data.isPrivate,
        companyIds: data.companyIds
      }

      datadogLogs.logger.info('CATALOG_UPDATE', { action: 'CATALOG_UPDATE', request: request,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
        
      execute({
        method: 'PATCH',
        url: `/catalog/update/${data.id}`,
        data: request,
      })
    }
      
  }
  
  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('CATALOG:POST', { action: 'CATALOG:POST',
        response: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])
  
  return {
    createNewCatalog,
    loading,
    catalogCreated: response
  }

}

export const useSuppliers = () => {
  const { execute, response, loading: loadingSuppliers } = useAxios<ICompanySku[]>()
  
  const getSuppliers = () => {
    execute({
      method: 'GET',
      url: '/warehouse-sku/suppliers',
      params: {
        countryCode: 'PER',
      },
    })
  }
    
  return {
    getSuppliers,
    loadingSuppliers,
    suppliers: response ? response : []
  }
}

export const useCatalogDelete = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ICatalogPrivate>()
  
  const deleteCatalog = (catalogId: number) => {
  
    datadogLogs.logger.info('CATALOG_DELETE', { action: 'CATALOG_DELETE', request: {
      catalogId: catalogId,
    },
    user: {
      id: authUser.user.id,
      email: authUser.user.email,
      fullName: authUser.user.fullname,
      role: authUser.user.role.name,
      permissions: authUser.user.role.permissions
    },
    company: {
      id: authUser.user.company.id,
      name: authUser.user.company.name,
      code: authUser.user.company.setting.companyPrefix,
    }
    })
        
    execute({
      method: 'PATCH',
      url: `/catalog/delete/${catalogId}`,
    })
      
  }
  
  return {
    deleteCatalog,
    loadingCatalogDelete: loading,
    catalogDeleted: response
  }
  
}
