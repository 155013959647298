/* eslint-disable max-len */
import { formatGeneralDate } from '../../utils/date'
import { useAuthStore } from '../auth/auth.service'
import { TRACKING_STATUS_LABEL } from '../order-create/order.params'
import { IOrderItemResponse } from '../order-list/order.interface'
import { IOrderShopify } from '../order-shopify/order-shopify.interface'
import { IPreOrderItemResponse } from '../pre-order/pre-order.interface'
import { TEMPLATE_2, TEMPLATE_0, TEMPLATE_3, TEMPLATE_NEW_1, TEMPLATE_1, TEMPLATE_5, TEMPLATE_4, TEMPLATE_6, TEMPLATE_7, TEMPLATE_8 } from './template.params'

export const useTemplateMsg = () => {
  const { authUser } = useAuthStore()

  const getPreOrderFollow = (preOrder: IPreOrderItemResponse) => {
    return TEMPLATE_0
      .replace('{name}', preOrder?.firstName)
      .replace('{businessName}', authUser.user.company.name)
      .replace('{orderNumber}', preOrder?.orderNumber)
      .replace('{product}', preOrder?.productName)
      .replace('{fullAddress}', `${preOrder?.address1} ${preOrder?.address2} ${preOrder?.province} ${preOrder?.city}`)
  }

  const getOrderShopifyFollow = (order: IOrderShopify) => {
    const productDetails = order?.items?.map(o =>
      `${o.quantity} ${o.name}`
    )

    return TEMPLATE_NEW_1
      .replace('{name}', order.customer.firstName)
      .replace('{orderNumber}', order.orderNumber)
      .replace('{fullAddress}', order.shipping.address)
      .replace('{product}', `${productDetails.join(', ')}`)
      .replace('{businessName}', authUser.user.company.name)
  }

  const getOrderFollow = (order: IOrderItemResponse) => {
    const productDetails = order?.orderDetails?.map(o =>
      `${o.quantity} ${o.sku.product?.name} ${o.sku.skuOptions?.map(o => o.option?.name).join('')}`
    )

    const customerName = order?.customerHistory?.name

    return TEMPLATE_1
      .replace('{name}', customerName.replace('#', ''))
      .replace('{businessName}', authUser.user.company.name)
      .replace('{orderNumber}', order?.orderNumber)
      .replace('{product}', `${productDetails.join(', ')}`)
      .replace('{fullAddress}', `${order?.preOrderHistory?.address1 || ''} ${order?.preOrderHistory?.address2 || ''} ${order?.preOrderHistory?.province || ''} ${order?.preOrderHistory?.city || ''}`)
  }

  const getOrderSupport = (order: IOrderItemResponse) => {
    return TEMPLATE_3
      .replace('{seller}', order?.user.fullname)
      .replace('{orderNumber}', order?.orderNumber)
      .replace('{businessName}', order?.companyHistory?.name || '')
  }

  const getOrderConfirm = (order: IOrderItemResponse) => {
    const shipping = order.shipping
    const productDetails = order?.orderDetails?.map(o =>
      `${o.quantity} ${o.sku.product?.name} ${o.sku.skuOptions?.map(o => o.option?.name).join('')}`
    )

    const temp = ', Favor agradeceré mucho me comparta su ubicación por este medio 📍'

    const isGps = order.shipping.lat && order.shipping.lng

    const customerName = order?.customerHistory?.name

    return TEMPLATE_2
      .replace('{name}', customerName.replace('#', ''))
      .replace('{businessName}', authUser.user.company.name)
      .replace('{orderNumber}', order?.orderNumber)
      .replace('{scheduleDate}', shipping?.scheduleDate ? formatGeneralDate(shipping.scheduleDate) : '')
      .replace('{total}', `${order?.total}`)
      .replace('{product}', `${productDetails.join(', ')}`)
      .replace('{fullAddress}', `${shipping?.address1} ${shipping?.address2} ${shipping?.districtName} ${shipping?.provinceName} ${shipping?.departmentName}`)
      .replace('{seller}', `${order?.user?.fullname}`)
      .replace('{location}', !isGps ? temp : '.')
  }

  const getMessageOrderAgencyPayIncomplete = () => {
    const business = authUser.user.company.name

    return TEMPLATE_5.replace('{ecom}', business)
  }

  const getMessageOrderAgencyPayComplete = () => {
    const business = authUser.user.company.name

    return TEMPLATE_4.replace('{ecom}', business)
  }

  const getMessageAgencyRegistered = (order: IOrderItemResponse) => {
    const ecom = authUser.user.company.name
    const productDetails = order?.orderDetails?.map(o =>
      `${o.quantity} ${o.sku.product?.name} ${o.sku.skuOptions?.map(o => o.option?.name).join('')}`
    )
    const address = order.shipping.addressPickUp && order.shipping.addressPickUp.length > 0 ? order.shipping.addressPickUp : order.shipping.address1
    const customer = order.customerHistory.name + ' ' + order.customerHistory.lastName

    const amount = order.currency?.symbol + ' ' + `${order.totalPayment > 0 ? (order.total - order.totalPayment).toFixed(2) : '-' }`

    return TEMPLATE_6
      .replace('{products}', `${productDetails.join(', ')}`)
      .replace('{ecommerce}', ecom)
      .replace('{address}', address)
      .replace('{customer}', customer)
      .replace('{orderNumber}', order.orderNumber)
      .replace('{amount}', amount)
      .replace('{status}', TRACKING_STATUS_LABEL[order.trackingStatus || '']?.label)
  }

  const getMessageAgencyInAgency = (order: IOrderItemResponse) => {
    const ecom = authUser.user.company.name
    const productDetails = order?.orderDetails?.map(o =>
      `${o.quantity} ${o.sku.product?.name} ${o.sku.skuOptions?.map(o => o.option?.name).join('')}`
    )
    const address = order.shipping.addressPickUp && order.shipping.addressPickUp.length > 0 ? order.shipping.addressPickUp : order.shipping.address1
    const customer = order.customerHistory.name + ' ' + order.customerHistory.lastName

    const amount = order.currency?.symbol + ' ' + `${order.totalPayment > 0 ? (order.total - order.totalPayment).toFixed(2) : '-' }`

    return TEMPLATE_7
      .replace('{products}', `${productDetails.join(', ')}`)
      .replace('{ecommerce}', ecom)
      .replace('{address}', address)
      .replace('{customer}', customer)
      .replace('{orderNumber}', order.orderNumber)
      .replace('{amount}', amount)
      .replace('{status}', TRACKING_STATUS_LABEL[order.trackingStatus || '']?.label)
  }

  const getMessageAgencyInDestination = (order: IOrderItemResponse) => {
    const ecom = authUser.user.company.name
    const productDetails = order?.orderDetails?.map(o =>
      `${o.quantity} ${o.sku.product?.name} ${o.sku.skuOptions?.map(o => o.option?.name).join('')}`
    )
    const address = order.shipping.addressPickUp && order.shipping.addressPickUp.length > 0 ? order.shipping.addressPickUp : order.shipping.address1
    const customer = order.customerHistory.name + ' ' + order.customerHistory.lastName

    const amount = order.currency?.symbol + ' ' + `${order.totalPayment > 0 ? (order.total - order.totalPayment).toFixed(2) : '-' }`

    return TEMPLATE_8
      .replace('{products}', `${productDetails.join(', ')}`)
      .replace('{ecommerce}', ecom)
      .replace('{address}', address)
      .replace('{customer}', customer)
      .replace('{orderNumber}', order.orderNumber)
      .replace('{amount}', amount)
      .replace('{status}', TRACKING_STATUS_LABEL[order.trackingStatus || '']?.label)
  }

  return {
    getPreOrderFollow,
    getOrderFollow,
    getOrderConfirm,
    getOrderSupport,
    getOrderShopifyFollow,
    getMessageOrderAgencyPayIncomplete,
    getMessageOrderAgencyPayComplete,
    getMessageAgencyRegistered,
    getMessageAgencyInAgency,
    getMessageAgencyInDestination,
  }
}
