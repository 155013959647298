import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { ITkState } from '../../state/ticket-support/ticket-support.reducer'
import { ITicketSupport } from '../ticket-support-list/ticket-support-list.interface'
import { ticketActions } from '../../state/ticket-support/ticket-support.actions'

export const useTicketStore = () => {
  const dispatch = useDispatch()
  const {
    tkSelected, tkUpdated,
  } = useSelector((state: IRootState) => state.ticket as ITkState)

  const setTkSelected = (data: ITicketSupport | null) => {
    dispatch(ticketActions.setTkSelected(data))
  }

  const setTkUpdated = (data: ITicketSupport) => {
    dispatch(ticketActions.setTkUpdated(data))
  }

  return {
    setTkSelected,
    setTkUpdated,
    tkSelected,
    tkUpdated,
  }
}
