import { ref, onValue, update, remove, query, orderByKey } from 'firebase/database'
import { useState } from 'react'
import { dbFirebase } from '../libs/firebase'

export const useFirebase = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState()

  const getFromDatabase = (refName: string) => {
    setLoading(true)
    const starCountRef = ref(dbFirebase, refName)

    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val()

      setLoading(false)
      setResponse(data)
    })
  }

  const getFromDatabaseQuery = (refName: string) => {
    setLoading(true)
    const starCountRef = ref(dbFirebase, refName)

    onValue(query(starCountRef, orderByKey()), (snapshot) => {
      const data = snapshot.val()

      setLoading(false)
      setResponse(data)
    })
  }

  const updateFromDatabase = (refName: string, value: any) => {
    const starCountRef = ref(dbFirebase, refName)

    update(starCountRef, value)

  }

  const deleteFromDatabase = (refName: string) => {
    const starCountRef = ref(dbFirebase, refName)

    remove(starCountRef)

  }

  return {
    getFromDatabase,
    getFromDatabaseQuery,
    updateFromDatabase,
    deleteFromDatabase,
    response,
    loading,
  }
}
