import { Alert, Button, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { usePreOrderDuplicate } from '../../../../../services/pre-order-duplicate/pre-order-duplicate.service'
import { usePreOrders } from '../../../../../services/pre-order/pre-order.service'
import { usePreOrderStore } from '../../../../../services/pre-order/pre-order.store'

const PreOrderDuplicates = () => {
  const { preOrderDuplicate, preOrderUpdated, loading } = usePreOrderDuplicate()
  const { preOrderSelected } = usePreOrderStore()
  const { getPreOrders, preOrdersAll, preOrdersByUser } = usePreOrders()
  const [duplicateGlobal, setDuplicateGlobal] = useState<number>(0)
  const [duplicateByUser, setDuplicateByUser] = useState<number>(0)

  const handlePreOrderDuplicate = () => {
    preOrderDuplicate({
      phone: preOrderSelected?.phone as string,
    })
  }

  useEffect(() => {
    getPreOrders()
  }, [])

  useEffect(() => {
    const countsAll: { [key: string]: number } = {}
    const countsByUser: { [key: string]: number } = {}

    preOrdersAll.forEach(po => {
      countsAll[po.phone] = (countsAll[po.phone] || 0) + 1
    })

    preOrdersByUser.forEach(po => {
      countsByUser[po.phone] = (countsByUser[po.phone] || 0) + 1
    })

    if (preOrderSelected) {
      setDuplicateGlobal(countsAll[preOrderSelected.phone])
      setDuplicateByUser(countsByUser[preOrderSelected.phone])
    }

  }, [preOrderSelected, preOrdersAll])

  useEffect(() => {
    if (preOrderUpdated) {
      getPreOrders()
    }
  }, [preOrderUpdated])

  return <>
    <div>
      {(duplicateGlobal !== duplicateByUser && duplicateGlobal > 1) ? (
        <Alert
          style={{ marginBottom: '.5rem' }}
          showIcon
          type="warning"
          message={`COLA GLOBAL: Total ${duplicateGlobal} pedidos similares`}
          action={
            <Space>
              <Button
                loading={loading}
                size="small"
                type="ghost"
                onClick={handlePreOrderDuplicate}
              >
                Tomar los {duplicateGlobal} pedidos
              </Button>
            </Space>
          }
        />
      ) : null}

      {duplicateByUser > 1 ? (
        <Alert
          style={{ marginBottom: '1rem' }}
          showIcon
          type="info"
          message={`COLA ACTUAL: Total ${duplicateByUser} pedidos similares`}
        />
      ) : null}

    </div>
  </>
}

export default PreOrderDuplicates
