import { IAuthResponse } from '../auth/auth.interface'
import { ICompanyItemResponse } from '../company-list/company-list.interface'
import { IMovementCreateSchema } from './movement.schema'
import { IMovementCreateRequest } from './movement.interface'

const mapToMovementCreateRequest = (
  movement: IMovementCreateSchema,
  authUser: IAuthResponse,
  companyGlobal: ICompanyItemResponse | null,
): IMovementCreateRequest => {

  return {
    warehouseId: movement.warehouseId,
    operation: movement.operation,
    companyId: companyGlobal?.id || authUser.user.company.id,        
    skuId: movement.skuId,
    quantity: parseInt(movement.quantity),
    warehouseDestinationId: movement.warehouseDestinationId,
    comment: movement.comment
  }
}

export {
  mapToMovementCreateRequest
}
