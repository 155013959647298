/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './order-shopify.actions'
import { IOrderShopify } from '../../services/order-shopify/order-shopify.interface'

export interface IOrderShopifyState {
  orderShopifySelected: IOrderShopify | null
}

const initialState: IOrderShopifyState = {
  orderShopifySelected: null,
}

export const orderShopifyReducer = (
  state: IOrderShopifyState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_ORDER_SHOPIFY_SELECTED:
    return {
      ...state,
      orderShopifySelected: action.payload,
    }
  default:
    return state
  }
}
