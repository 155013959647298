import React from 'react'
import { DispatchGuideMaintContainer } from './containers'

const DispatchGuideMaintView = () => {
  return (
    <>
      <DispatchGuideMaintContainer/>
    </>
  )
}

export default DispatchGuideMaintView
