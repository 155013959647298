import { useAxios } from '../../hooks'
import { ILiquidationEditRequest, ILiquidationEditResponse, IVoucherDeleteResponse } from './liquidation-edit.interface'
import { ILiquidationSchema } from './liquidation.schema'
import { datadogLogs } from '../../hooks/use-datadog'
import { useAuthStore } from '../auth/auth.service'
import { useEffect } from 'react'

export const useLiquidationCreate = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ILiquidationEditResponse>()

  const updateLiquidation = (data: ILiquidationSchema) => {
    
    const request: ILiquidationEditRequest = {
      commentLiquidated: data.commentLiquidated,
      commentMotorized: data.commentMotorized,
      status: data.status,
      paysNew: []
    }

    datadogLogs.logger.info('LIQUIDATION_UPDATE', { action: 'LIQUIDATION_UPDATE',
      liquidation: request,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      }
    })
    
    execute({
      method: 'PATCH',
      url: `/liquidation/${data.id}`,
      data: request,
    })
    
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('LIQUIDATION_UPDATE:POST', { action: 'LIQUIDATION_UPDATE:POST',
        liquidationResponse: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])

  return {
    updateLiquidation,
    loading,
    liquidationEditUpdated: response
  }
}

export const useVoucherDelete = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IVoucherDeleteResponse>()

  const deleteVoucher = (id: number) => {


    datadogLogs.logger.info('VOUCHER_DELETE', { action: 'VOUCHER_DELETE',
      params: id,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      }
    })
    
    execute({
      method: 'PATCH',
      url: `/liquidation/evidence/delete/${id}`,
    })
    
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('VOUCHER_DELETE:POST', { action: 'VOUCHER_DELETE:POST',
        voucherDelete: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])

  return {
    deleteVoucher,
    loading,
    voucherDeleted: response
  }
}
