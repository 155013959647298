import React from 'react'
import { CatalogsContainer } from './containers'

const ManageCatalogView = () => {
  return (
    <>
      <CatalogsContainer/>
    </>
  )
}

export default ManageCatalogView
