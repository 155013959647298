import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { 
  ICompanyDrop,
  ICurrentStockReport,
  IDispatchReport,
  IDropShalomReport,
  IMovementsReport,
  IOrderDeliveryHistoryReport,
  IOrderReportCreated,
  IStockReport 
} from './order-report-created.interface'

export const useCurrentStockReport = () => {
  const { execute, response, loading } = useAxios<ICurrentStockReport[]>()

  const getCurrentStockReport = (
    warehouseId: number
  ) => {
    execute({
      method: 'GET',
      url: '/warehouse-sku/current-stock',
      params: {
        warehouseId: warehouseId,
      },
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
    })
  }
  
  return {
    getCurrentStockReport,
    loadingStocks: loading,
    currentStocks: response || [],
  }
}

export const useMovementsReport = () => {
  const { execute, response, loading } = useAxios<IMovementsReport[]>()

  const getMovementsReport = (
    startDate: string, endDate: string, warehouseId: number, countryCode?: string
  ) => {
    execute({
      method: 'GET',
      url: '/transaction/reports-movements',
      params: {
        warehouseId: warehouseId === -1 ? '' : warehouseId,
        createdAtTo: startDate,
        createdAtFrom: endDate,
        countryCode: countryCode,
      },
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
    })
  }
  
  return {
    getMovementsReport,
    loadingMovements: loading,
    movements: response || [],
  }
}

export const useOrderReportCreated = () => {
  const { execute, response, loading } = useAxios<IOrderReportCreated[]>()
  
  const getOrderReportCreated = (
    startDate: string, endDate: string, companyId: number, type: string, countryCode?: string
  ) => {
    execute({
      method: 'GET',
      url: '/order/order-delivery-report',
      params: {
        companyId: companyId === -1 ? '' : companyId,
        startDate: startDate,
        endDate: endDate,
        countryCode: countryCode,
        type: type,
      },
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
    })
  }
  
  return {
    getOrderReportCreated,
    loadingOrders: loading,
    ordersCreated: response || [],
  }

}

export const useOrderDeliveryReport = () => {
  const { execute, response, loading } = useAxios<IOrderDeliveryHistoryReport[]>()
  
  const getOrderDeliveryHistory = (
    startDate: string, endDate: string, companyId: number, type: string, countryCode?: string
  ) => {
    execute({
      method: 'GET',
      url: '/order/report/order-delivery/history',
      params: {
        companyId: companyId === -1 ? '' : companyId,
        startDate: startDate,
        endDate: endDate,
        countryCode: countryCode,
        type: type,
      },
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
    })
  }
  
  return {
    getOrderDeliveryHistory,
    loadingOrderDelivery: loading,
    orderDeliveries: response || [],
  }

}

export const useStockReport = () => {
  const { execute, response, loading } = useAxios<IStockReport[]>()
  
  const getStockReport = (
    startDate: string, endDate: string, companyId: number, countryCode?: string
  ) => {
    execute({
      method: 'GET',
      url: '/order/stock-report',
      params: {
        companyId: companyId === -1 ? '' : companyId,
        startDate: startDate,
        endDate: endDate,
        countryCode: countryCode,
      },
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com'
    })
  }
  
  return {
    getStockReport,
    loadingStockReports: loading,
    responseSkReports: response || [],
  }

}

export const useCompanyDrop = () => {
  const { execute, response, loading } = useAxios<ICompanyDrop[]>()
  const { authUser } = useAuthStore()
  
  const getCompaniesDrop = () => {
    execute({
      method: 'GET',
      url: '/maintenance/company/drop',
      params: {
        countryCode: authUser.user.company.countryCode,
      },
    })
  }
  
  return {
    getCompaniesDrop,
    loadingCompanies: loading,
    companies: response || [],
  }

}

export const useDispatchReport = () => {
  const { execute, response, loading } = useAxios<IDispatchReport[]>()
  
  const getDispatchReport = (
    startDate: string, endDate: string, warehouseId: number, type: string, countryCode?: string
  ) => {
    execute({
      method: 'GET',
      url: '/order/dispatch-report',
      params: {
        warehouseId: warehouseId === -1 ? '' : warehouseId,
        startDate: startDate,
        endDate: endDate,
        countryCode: countryCode,
        type: type,
      },
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
    })
  }
  
  return {
    getDispatchReport,
    loadingDispatchReports: loading,
    dispatchReports: response || [],
  }

}

export const useDropShalomReport = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IDropShalomReport[]>()
  
  const getDropShalomReport = (
    startDate: string, endDate: string
  ) => {
    execute({
      method: 'GET',
      url: '/order/drop-shalom-report',
      params: {
        startDate: startDate,
        endDate: endDate,
        countryCode: authUser.user.company.countryCode,
      },
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
    })
  }
  
  return {
    getDropShalomReport,
    loading: loading,
    reports: response || [],
  }

}
