import React, { FC } from 'react'
import { IOrderResumen } from '../../../../../services/order-list/order.interface'
import './OrderResumen.css'

interface IOrderResumenProps {
  resumen: IOrderResumen
}

const OrderResumen: FC<IOrderResumenProps> = ({ resumen }) => {
  return (
    <>
      <div className='OrderResumen'>
        <div>
          <span className='OrderResumen__label'>Cant:</span>
          <b>{resumen?.totalOrder}</b>
        </div>

        <div>
          <span className='OrderResumen__label'>Total:</span>
          <b>{resumen?.currency?.symbol} {resumen?.amount?.toFixed(2)}</b>
        </div>

      </div>
    </>
  )
}

export default OrderResumen
