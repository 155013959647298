import React from 'react'
import { LandingContainer } from './containers'
import background from './../SignIn/background_login.png'

const LandingView = () => {

  return (
    <>
      <div style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat',
        height: '100vh', width: '100%', backgroundPosition: 'center', backgroundSize: 'cover' }}>
        <LandingContainer />
      </div>
    </>
  )
}

export default LandingView
