import { useAxios } from '../../hooks'
import { ICoverageItemResponse } from '../coverage-create/coverage-create.interface'

export const useCoverageUpdate = () => {
  const { execute, response, loading } = useAxios<ICoverageItemResponse>()

  const updateCoverage = (id: number) => {
    execute({
      method: 'PATCH',
      url: `/maintenance/coverage/${id}`,
      data: {
        isActive: false
      },
    })
  }

  return {
    updateCoverage,
    loading,
    coverageUpdated: response
  }
}
