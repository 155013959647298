import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { returnActions } from '../../state/returnGuide/return-guide.actions'
import { ISummaryProductEditResponse } from '../summary-products/summary-products.interface'
import { IReturnResponse } from '../return-list/return-list.interface'
import { IReturnState } from '../../state/returnGuide/return.reducer'
import { ICreateReturnResponse } from './return-create.interface'

export const useReturnStore = () => {
  const dispatch = useDispatch()
  const {
    returnSelected, returnUpdated, summaryProductEdited,
  } = useSelector((state: IRootState) => state.return as IReturnState)

  const setReturnSelected = (data: IReturnResponse | null) => {
    dispatch(returnActions.setReturnSelected(data))
  }

  const setReturnUpdated = (data: ICreateReturnResponse) => {
    dispatch(returnActions.setReturnUpdated(data))
  }

  const setSummaryProductEdited = (data: ISummaryProductEditResponse[]) => {
    dispatch(returnActions.setSummaryProductEdited(data))
  }

  return {
    setReturnSelected,
    setReturnUpdated,
    setSummaryProductEdited,
    returnSelected,
    returnUpdated,
    summaryProductEdited,
  }
}
