import { combineReducers } from 'redux'
import { userReducer } from './user/user.reducer'
import { preOrderReducer } from './pre-order/pre-order.reducer'
import { productReducer } from './product/product.reducer'
import { orderReducer } from './order/order.reducer'
import { companyReducer } from './company/company.reducer'
import { coverageReducer } from './coverage/coverage.reducer'
import { movementReducer } from './movement/movement.reducer'
import { warehouseReducer } from './warehouse/warehouse.reducer'
import { paymentReducer } from './payment/payment.reducer'
import { dispatchGuideReducer } from './dispatch-guide/dispatch-guide.reducer'
import { liquidationReducer } from './liquidation/liquidation.reducer'
import { returnReducer } from './returnGuide/return.reducer'
import { orderShopifyReducer } from './order-shopify/order-shopify.reducer'
import { tkReducer } from './ticket-support/ticket-support.reducer'
import { catalogReducer } from './catalog/catalog.reducer'

export const rootReducer = combineReducers({
  user: userReducer,
  preOrder: preOrderReducer,
  order: orderReducer,
  product: productReducer,
  company: companyReducer,
  coverage: coverageReducer,
  movement: movementReducer,
  warehouse: warehouseReducer,
  payment: paymentReducer,
  dispatchGuider: dispatchGuideReducer,
  liquidation: liquidationReducer,
  return: returnReducer,
  orderShopify: orderShopifyReducer,
  ticket: tkReducer,
  catalog: catalogReducer
})

export type IRootState = ReturnType<typeof rootReducer>

