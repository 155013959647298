/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Table, TableColumnsType, Select, Divider } from 'antd'
import { ISummaryProductItem } from '../../../../../services/dispatch-guide-list/dispatch-guide-list.interface'
import { useSummaryProductsAccumulated } from '../../../../../services/summary-products/summary-products.service'
import { useGuideAvailablesList } from '../../../../../services/dispatch-guide-availables/dispatch-guide-availables.service'
import { useAuthStore } from '../../../../../services/auth/auth.service'

const SummaryProductAccumulatedContainer = () => {
  const { getSummaryProductsAcc, summaryProductsAcc, loading } = useSummaryProductsAccumulated()
  const { getGuides, guides } = useGuideAvailablesList()
  const { authUser } = useAuthStore()
  const [ selectedGuides, setSelectedGuides ] = useState<string[]>([])

  useEffect(() => {
    getGuides({
      companyId: authUser.user.company.id
    })
  }, [])

  useEffect(() => {
    if (selectedGuides.length > 0) {
      const ids = selectedGuides.map(i => parseInt(i))

      getSummaryProductsAcc(ids)
    }
  }, [selectedGuides])

  const columns: TableColumnsType<ISummaryProductItem> = [
    {
      title: 'Producto entregado',
      dataIndex: 'productDelivered',
      key: 'productDelivered',
      align: 'center',
      render: (_, d) => <>{d.productDelivered} {d.quantityDelivered > 0 ? <>
      - {d.companyName}
      </> : ''}</>
    },
    {
      title: 'Bodega origen',
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      align: 'center',
      render: (_, d) => <>{d.warehouseName}</>
    },
    {
      title: 'Cantidad entregada',
      dataIndex: 'quantityDelivered',
      key: 'quantityDelivered',
      align: 'center',
      render: (_, d) => <>{d.quantityDelivered}</>
    },
    {
      title: 'Producto restante',
      dataIndex: 'productRemaining',
      key: 'productRemaining',
      align: 'center',
      render: (_, d) => <>{d.productRemaining} {d.quantityDelivered == 0 ? <>
      - {d.companyName}
      </> : ''}</>
    },
    {
      title: 'Cantidad restante',
      dataIndex: 'quantityRemaining',
      key: 'quantityRemaining',
      align: 'center',
      render: (_, d) => <>{d.quantityRemaining}</>
    },
  ]

  return <div style={{ overflow: 'auto' }}>
    
    <>

      <Select
        mode="multiple"
        placeholder="Buscar guía"
        value={selectedGuides}
        onChange={setSelectedGuides}
        style={{ width: '100%' }}
        options={guides.map((item) => ({
          value: item.id,
          label: item.guideNumber,
        }))}
      />

      <Divider />

      <Table
        rowKey="id"
        size='small'
        dataSource={summaryProductsAcc}
        loading={loading}
        columns={columns}
      />
    </>
    
  </div>
}

export default SummaryProductAccumulatedContainer
