import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { IMovementState } from '../../state/movement/movement.reducer'
import { movementActions } from '../../state/movement/movement.actions'
import { IMovementResponse } from '../movement-list/movement-list.interface'
import { IMovementCreateResponse } from './movement.interface'

export const useMovementStore = () => {
  const dispatch = useDispatch()
  const {
    movementSelected, movementUpdated,
  } = useSelector((state: IRootState) => state.movement as IMovementState)

  const setMovementSelected = (data: IMovementResponse | null) => {
    dispatch(movementActions.setMovementSelected(data))
  }

  const setMovementUpdated = (data: IMovementCreateResponse) => {
    dispatch(movementActions.setMovementUpdated(data))
  }

  return {
    setMovementSelected,
    setMovementUpdated,
    movementSelected,
    movementUpdated
  }
}
