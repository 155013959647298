import { Button, Divider, Form, Input, Select, Switch } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { ICompanyCreateRequest } from '../../../../../services/company-create/company-create.interface'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'

const { Option } = Select

interface IStoresEditFormProps {
  onSubmit: (data: ICompanyCreateRequest) => void
}

const StoresEditForm: FC<IStoresEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ICompanyCreateRequest>()
  const { companySelected } = useCompanyStore()
  const [enable, setEnable] = useState(false)

  useEffect(() => {
    if (companySelected) {
      form.setFieldsValue({
        id: companySelected.id,
        code: companySelected.code,
        name: companySelected.name,
        type: companySelected.type,
        isActive: true,
      })

      setEnable(true)
    }
  }, [companySelected])

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='UserForm'
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item hidden name={['id']}>
          <Input />
        </Form.Item>

        <Form.Item
          label='Nombre'
          name={['name']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Codigo'
          name={['code']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Input disabled={enable}/>
        </Form.Item>

        <Form.Item
          label='Compañia'
          name={['type']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <Select placeholder="Seleccione una opción">
            <Option value="NORMAL">NORMAL</Option>
            <Option value="PRINCIPAL">PRINCIPAL</Option>
          </Select>
        </Form.Item>

        {companySelected ? (
          <Form.Item
            label="Activo"
            name={['isActive']}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        ) : null}

        <Divider />

        <Button type='primary' htmlType='submit'>
          Guardar
        </Button>

      </Form>
    </>
  )
}

export default StoresEditForm
