import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { IProductState } from '../../state/product/product.reducer'
import { IProductSearchSchema } from './product.schema'
import { productActions } from '../../state/product/product.actions'
import { IProductResponse, ISkuItemResponse } from './product.interface'
import { IProductCreateResponse } from '../product-create/product-create.interface'

export const useProductStore = () => {
  const dispatch = useDispatch()
  const {
    productSearch, productSelected, productUpdated, skuSelected
  } = useSelector((state: IRootState) => state.product as IProductState)

  const setProductEmpty = () => {
    dispatch(productActions.setProductEmpty())
  }

  const setProductSearch = (product: IProductSearchSchema) => {
    dispatch(productActions.setProductSearch(product))
  }

  const productDelete = (productId: number) => {
    dispatch(productActions.productDelete(productId))
  }

  const setProductSelected = (data: IProductResponse | null) => {
    dispatch(productActions.setProductSelected(data))
  }

  const setProductUpdated = (data: IProductCreateResponse) => {
    dispatch(productActions.setProductUpdated(data))
  }

  const setSkuSelected = (data: ISkuItemResponse) => {
    dispatch(productActions.setSkuSelected(data))
  }

  return {
    setProductEmpty,
    setProductSearch,
    setProductSelected,
    setProductUpdated,
    productDelete,
    setSkuSelected,
    productSearch,
    productSelected,
    productUpdated,
    skuSelected
  }
}
