import React, { FC } from 'react'

// Redux
import { Provider } from 'react-redux'
import store from '../state/store'

// Router
import { BrowserRouter } from 'react-router-dom'

// Custom provider
import { DrawerProvider } from './DrawerContext'
import { ModalProvider } from './ModalContext'
import { ConfigProvider } from 'antd'

import locale from 'antd/es/locale/es_ES'

interface IContextManagerProps {
  children: React.ReactNode;
}

export const ContextManager: FC<IContextManagerProps> = ({ children }) => (
  <BrowserRouter>
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <DrawerProvider>
          <ModalProvider>
            {children}
          </ModalProvider>
        </DrawerProvider>
      </Provider>
    </ConfigProvider>
  </BrowserRouter>
)
