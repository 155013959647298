import React from 'react'
import { CatalogListContainer } from './containers'

const CatalogView = () => {
  return (
    <>
      <CatalogListContainer/>
    </>
  )
}

export default CatalogView
