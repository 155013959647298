import { IPaymentCreateResponse } from '../../services/payment-create/payment-create.interface'
import { IPaymentResponse } from '../../services/payment-list/payment-list.interface'

export const actionTypes = {
  SET_PAYMENT_SELECTED: 'SET_PAYMENT_SELECTED',
  SET_PAYMENT_UPDATED: 'SET_PAYMENT_UPDATED',
}

const setPaymentSelected = (data: IPaymentResponse | null) => ({
  type: actionTypes.SET_PAYMENT_SELECTED,
  payload: data,
})

const setPaymentUpdated = (data: IPaymentCreateResponse) => ({
  type: actionTypes.SET_PAYMENT_UPDATED,
  payload: data,
})

export const paymentActions = {
  setPaymentSelected,
  setPaymentUpdated
}
