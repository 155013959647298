import React from 'react'
import { HomeContainer } from './containers'

const HomeView = () => {
  return <>
    <HomeContainer/>
  </>
}

export default HomeView
