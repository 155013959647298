import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IReturnBaseResponse } from './return-list.interface'

export const useReturnList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IReturnBaseResponse>()

  const getReturn = (startDate: string, endDate: string, page?: number, search?: string, motorizedId?: string) => {
    execute({
      method: 'GET',
      url: '/return/all',
      params: {
        companyId: authUser.user.company.id,
        page: page ? page : '',
        search: search ? search : '',
        motorizedId: motorizedId === 'ALL' ? '' : motorizedId,
        type: 'DIARY_HISTORY',
        createdAtTo: startDate,
        createdAtFrom: endDate
      },
    })
  }

  return {
    getReturn,
    loading,
    returnGuides: response ? response?.result : [],
    count: response ? response?.count : 0,
    currentPage: response ? response?.page : 1,
  }
}
