/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { Spin } from 'antd'
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store'
import { SummaryProductEditForm } from '../../forms'
import { useSummaryProductEdit } from '../../../../../services/summary-products/summary-products.service'
import { ISummaryProductCreateSchema } from '../../../../../services/summary-products/summary-products.schema'
import { useModal } from '../../../../../context/ModalContext'

const SummaryProductEditContainer = () => {
  const { dispatchGuideSelected, setDispatchGuideSelected } = useDispatchGuideStore()
  const { closeModal } = useModal()
  const { summaryProductsEdit, summaryProductEdits, loading } = useSummaryProductEdit()
  const { setSummaryProductEdited } = useDispatchGuideStore()

  const handleSubmit = (data: ISummaryProductCreateSchema) => {
    summaryProductsEdit(data)
  }

  useEffect(() => {
    if (summaryProductEdits && summaryProductEdits.length > 0) {
      setDispatchGuideSelected(null)
      setSummaryProductEdited(summaryProductEdits)
      closeModal()
    }
  }, [summaryProductEdits])

  return <div style={{ overflow: 'auto' }}>
    {dispatchGuideSelected ? (
      <>
        <Spin spinning={loading}>
          <SummaryProductEditForm
            onSubmit={handleSubmit}
          />
        </Spin>
      </>
    ) : null}

  </div>

}

export default SummaryProductEditContainer
