import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useDrawer } from '../../../../../context/DrawerContext'
import { IMovementCreateSchema } from '../../../../../services/movement-create/movement.schema'
import { MovementEditForm } from '../../forms'
import { useMovementCreate } from '../../../../../services/movement-create/movement.service'
import { useMovementStore } from '../../../../../services/movement-create/movement.store'

const MovementEditContainer = () => {
  const { movementCreate, movementCreated, loading } = useMovementCreate()
  const { closeDrawer } = useDrawer()
  const { setMovementUpdated } = useMovementStore()

  const handleSubmit = (data: IMovementCreateSchema) => {
    movementCreate(data)
  }

  useEffect(() => {
    if (movementCreated) {
      setMovementUpdated(movementCreated)
      closeDrawer()
    }
  }, [movementCreated])

  return (
    <>
      <Spin spinning={loading}>
        <MovementEditForm
          onSubmit={handleSubmit}
        />
      </Spin>
    </>
  )
}

export default MovementEditContainer
