import React, { useEffect } from 'react'
import { Collapse, Spin, Tag } from 'antd'
import { PageHeader } from '../../../../components'
import { useTaskList } from '../../../../services/task-list/task-list.service'
import { ITaskItemResponse } from '../../../../services/task-list/task-list.interface'

const { Panel } = Collapse

const ImportOrderContainer = () => {
  const { getTask, tasks, loading } = useTaskList()

  useEffect(() => {
    getTask()
  }, [])

  return <>
    <PageHeader />

    <Spin spinning={loading}>
      <Collapse accordion ghost>
        {tasks.map((task: ITaskItemResponse) => (
          <Panel
            key={task.id}
            header={<>
              <Tag color={task.success ? '#108ee9' : '#f50'}>{task.success ? 'OK' : 'ERROR'}</Tag> ~
              {new Date(task.createdAt).toLocaleString()} ~ {task.name}
            </>}
          >
            <pre style={{
              background: '#eee', padding: '1rem', fontSize: '12px', maxHeight: '600px',
            }}>
              <code>
                {JSON.stringify(task.data, null, 2)}
                <br />
              </code>
            </pre>
          </Panel>
        ))}
      </Collapse>
    </Spin>
  </>
}

export default ImportOrderContainer
