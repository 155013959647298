import { useAxios } from '../../hooks'
import { IRoleItemResponse } from './role-list.interface'
import { useUserCreatedEdit } from './../user-create/user.edit'

export const useRolesList = () => {
  const { userId } = useUserCreatedEdit()
  const { execute, response, loading } = useAxios<IRoleItemResponse[]>()

  const getRoles = () => {
    execute({
      method: 'GET',
      url: '/maintenance/role',
    })
  }

  let rolesList: IRoleItemResponse[] = []

  if (userId === 1) {
    rolesList = response?.filter(r => r.name === 'ADMIN_STORE' || r.name === 'SELLER') || []
  } else if (userId === 2) {
    rolesList = response || []
  } else if (userId === 3) {
    rolesList = response?.filter(r => r.name === 'SUP_SELLER' || r.name === 'SELLER') || []
  } else if (userId === 4) {
    rolesList = response?.filter(r => r.name === 'SUP_MOTORIZED' || r.name === 'MOTORIZED') || []
  } else {
    rolesList = []
  }

  return {
    getRoles,
    loading,
    roles: rolesList || []
  }
}
