import React, { useEffect, useState } from 'react'
import { Button, Select, Typography } from 'antd'
import { PageHeader } from '../../../../../components'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { IOrderFilter } from '../../../../../services/order-list/order.interface'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { DeliveryTable } from '../../components'
import { DeliveryFilterForm } from '../../forms'
import { useDrawer } from '../../../../../context/DrawerContext'
import { getProvinceList } from '../../../../../services/location/location.service'
import { useOrderDispatchedList } from '../../../../../services/order-dispatched/order-dispatched.service'
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store'
import {
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_DISPATCH_STATUS_LIST
} from '../../../../../services/order-create/order.params'
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service'

const { Title } = Typography
const { Option } = Select

const OrdersAssignmentListContainer = () => {
  const { orders, loading, getOrders } = useOrderDispatchedList()
  const { warehouses, loadingWarehouses, getWarehouses } = useWarehouseByCountry()
  const { orderUpdated, orderDeliveryChecked, setOrderDeliveryChecked } = useOrderStore()
  const { guideAssignUpdated, setDispatchGuideSelected, dispatchGuideUpdated } = useDispatchGuideStore()
  const { authUser } = useAuthStore()
  const [filterValue, setFilterValue] = useState<IOrderFilter>()
  const { openDrawer } = useDrawer()
  const provinceList = getProvinceList()
  const [defaultSelected, setDefaultSelected] = useState<string>('ALL')
  const [dispatchSelected, setDispatchSelected] = useState<string>('ALL')
  const [warehouseSelected, setWarehouseSelected] = useState<string>('ALL')
  const [defaultSend] = useState<string>('0')

  const handleSubmit = (filter: IOrderFilter) => {
    setFilterValue(filter)
    getOrders({
      ...filter,
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      warehouseName: warehouseSelected,
    })
  }

  const handleGuideAssignChange = () => {
    openDrawer('ASSIGN_DISPATCH_EDIT', 'Asignar Guía')
  }

  const handleCreateGuideChange = () => {
    setDispatchGuideSelected(null)
    openDrawer('DISPATCH_GUIDE_EDIT', 'Crear guía')
  }

  const handleChangeProvince = (ubigeId: string) => {
    getOrders({
      ...filterValue,
      provinceCode: ubigeId,
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      warehouseName: warehouseSelected,
    })
    setDefaultSelected(ubigeId)
  }

  const handleChangeWarehouse = (value: string) => {

    getOrders({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: defaultSend,
      dispatchStatus: dispatchSelected,
      warehouseName: value,
    })
    setWarehouseSelected(value)
  
  }

  const handleChangeDispatch = (value: string) => {
    getOrders({
      ...filterValue,
      provinceCode: defaultSelected,
      isOrderAgency: defaultSend,
      dispatchStatus: value,
      warehouseName: warehouseSelected,
    })
    setDispatchSelected(value)
  }

  useEffect(() => {
    if (orderUpdated || guideAssignUpdated || dispatchGuideUpdated) {
      getOrders({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
      })
    }
  }, [orderUpdated, guideAssignUpdated, dispatchGuideUpdated])

  useEffect(() => {

    if (authUser.user.role.name === 'STORE' || authUser.user.role.name === 'ADMIN' 
      || authUser.user.role.name === 'MASTER') {

      getOrders({
        ...filterValue,
        provinceCode: defaultSelected,
        isOrderAgency: defaultSend,
        dispatchStatus: dispatchSelected,
        warehouseName: warehouseSelected,
      })

    }

  }, [])

  useEffect(() => {
    setOrderDeliveryChecked([])
    getWarehouses()
  }, [orders])

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex', justifyContent: 'space-between', gap: '1rem', alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
            {/*authUser.user.role?.permissions?.order_company_change ? (
              <CompanyChangeContainer type='PRINCIPAL' isAll={false} />
            ) : null*/}
            <DeliveryFilterForm onChange={handleSubmit} />

            <Button
              type="primary"
              onClick={handleCreateGuideChange}
              disabled={!orderDeliveryChecked?.length}
            >
              Crear guía
            </Button>

            <Button
              type="primary"
              onClick={handleGuideAssignChange}
              disabled={!orderDeliveryChecked?.length}
            >
              Asignar guía
            </Button>

            { authUser.user.warehouse ? <>
            </> : <>
              <Title level={5} style={{ marginBottom: '0' }}>Almacén</Title>

              <Select
                placeholder="Almacén"
                showSearch
                loading={loadingWarehouses}
                optionFilterProp="label"
                style={{ minWidth: '150px' }}
                onChange={handleChangeWarehouse}
                value={warehouseSelected}
                filterOption={(input, option) =>
                  (option?.label as string).toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option key='ALL' value='ALL' label={'TODOS'}>
                    TODOS
                </Option>
                {warehouses.map(key => (
                  <Option
                    key={key.id}
                    value={key.name}
                    label={key.name}
                  >
                    {key.name}
                  </Option>
                ))}
              </Select>

            </>}
            <Title level={5} style={{ marginBottom: '0' }}>Estado Despacho</Title>
            <Select
              placeholder='Despacho'
              showSearch
              optionFilterProp='label'
              style={{ minWidth: '130px' }}
              onChange={handleChangeDispatch}
              value={dispatchSelected}
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
            >

              <Option key='ALL' value='ALL' label={'TODOS'}>
                  TODOS
              </Option>
              
              <Option
                key={ORDER_DISPATCH_STATUS_LIST.TO_PREPARE}
                value={ORDER_DISPATCH_STATUS_LIST.TO_PREPARE}
                label={ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.TO_PREPARE].label}
              >
                {ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.TO_PREPARE].label}
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.PREPARED}
                value={ORDER_DISPATCH_STATUS_LIST.PREPARED}
                label={ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.PREPARED].label}
              >
                {ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.PREPARED].label}
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT}
                value={ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT}
                label={ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT].label}
              >
                {ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.IN_TRANSIT].label}
              </Option>

              <Option
                key={ORDER_DISPATCH_STATUS_LIST.PICKED}
                value={ORDER_DISPATCH_STATUS_LIST.PICKED}
                label={ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.PICKED].label}
              >
                {ORDER_DISPATCH_STATUS_LABEL[ORDER_DISPATCH_STATUS_LIST.PICKED].label}
              </Option>
              
            </Select>
            <Title level={5} style={{ marginBottom: '0' }}>Provincia</Title>
            <Select
              placeholder="Provincia"
              showSearch
              optionFilterProp="label"
              style={{ minWidth: '130px' }}
              onChange={handleChangeProvince}
              value={defaultSelected}
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option key='ALL' value='ALL' label={'TODOS'}>
                  TODOS
              </Option>
              {provinceList.map(key => (
                <Option
                  key={key.id_ubigeo}
                  value={key.id_ubigeo}
                  label={key.nombre_ubigeo}
                >
                  {key.nombre_ubigeo}
                </Option>
              ))}
            </Select>
          </div>

        </div>
      </PageHeader>

      {/*orders?.length ? (
        <DeliveryResumen orders={orders} />
      ) : null*/}

      <DeliveryTable
        data={orders}
        loading={loading}
      />
    </>
  )
}

export default OrdersAssignmentListContainer
