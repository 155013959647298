/* eslint-disable max-len */
import { useEffect, useState } from 'react'
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IOrderFilter, IOrderAgencyResponse, IOrderResumen, IOrderAgencyDispatch } from '../order-list/order.interface'
import { useCompanyStore } from '../company-create/company-create.service'

export const useOrdersAgencyDispatch = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IOrderAgencyDispatch[]>()
  const { companyGlobal } = useCompanyStore()

  const getOrdersAgencyDispatch = (startDate: string, endDate: string) => {
    execute({
      method: 'GET',
      url: '/order/agency/dispatch',
      baseUrl: 'https://aliclik-report-api-484c2fcb43c4.herokuapp.com',
      params: {
        companyId: companyGlobal?.id === 0 ? '' : (companyGlobal?.id || authUser.user.company.id),
        startDate: startDate,
        endDate: endDate,
      },
    })
  }

  return {
    ordersAgencyDispatch: response || [],
    loadingOrders: loading,
    getOrdersAgencyDispatch,
  }

}

export const useOrderAgencyList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IOrderAgencyResponse>()
  const [resumen, setResumen] = useState<IOrderResumen>()

  const getOrdersAgency = (filter?: IOrderFilter) => {

    execute({
      method: 'GET',
      url: '/order/agency/all',
      params: {
        callStatus: filter?.callStatus ? (filter?.callStatus === 'ALL' ? '' : filter?.callStatus) : 'CONFIRMED',
        companyId: filter?.companyId === 0 ? '' : (filter?.companyId || authUser.user.company.id),
        trackingStatus: filter?.trackingStatus ? (filter?.trackingStatus === 'ALL' ? '' : filter?.trackingStatus) : 'TO_REGISTER',
        countryCode: authUser.user.company.countryCode,
        page: filter?.page ? filter.page : '',
        search: filter?.search ? filter.search : '',
        paymentType: filter?.paymentType === 'ALL' ? '' : filter?.paymentType,
        departmentName: filter?.departmentName === 'ALL' ? '' : filter?.departmentName,
        startDate: filter?.startDate,
        endDate: filter?.endDate,
        warehouseId: filter?.warehouseId === 'ALL' ? '' : (filter?.warehouseId || authUser.user.warehouse?.id),
        managementType: filter?.managementType === 'ALL' ? '' : filter?.managementType,
      },
    })
  }

  useEffect(() => {
    if (response && response.result.length > 0) {
      const totalAmount = response.result.length > 0 ? response.result.map(o => o.total).reduce((a, b) => a + b) : 0
      const totalPayment = response.result.length > 0 ? response.result.map(o => o.totalPayment).reduce((a, b) => a + b) : 0

      const resumen: IOrderResumen = {
        totalOrder: response.count,
        totalAmount,
        totalCash: 0,
        totalPayment,
        currency: response.result[0]?.currency
      }

      setResumen(resumen)

    } else {
      const resumen: IOrderResumen = {
        totalOrder: 0,
        totalAmount: 0,
        totalCash: 0,
        totalPayment: 0,
        currency: {
          code: '',
          name: '',
          symbol: ''
        }
      }

      setResumen(resumen)
    }
  }, [response])

  return {
    response,
    loading,
    getOrdersAgency,
    count: response ? response.count : 0,
    page: response ? response.page : 1,
    resumen
  }

}
