import { IOrderDeliveryCreateResponse } from '../../services/order-delivery-create/order-delivery-create.interface'
import { IOrderDeliveryItemResponse } from '../../services/order-motorized/order-motorized.interface'
import { IOrderItemResponse } from '../../services/order-list/order.interface'
import { IOrderUpdateResponse } from '../../services/order-update/order-update.interface'
import { IUpdateEvidenceResponse } from '../../services/evidence-update/evidence-update.interface'

export const actionTypes = {
  SET_ORDER_SELECTED: 'SET_ORDER_SELECTED',
  SET_ORDER_UPDATED: 'SET_ORDER_UPDATED',
  SET_ORDER_DELIVERY_CHECKED: 'SET_ORDER_DELIVERY_CHECKED',
  SET_ORDER_DELIVERY_UPDATED: 'SET_ORDER_DELIVERY_UPDATED',
  SET_ORDER_DELIVERIES: 'SET_ORDER_DELIVERIES',
  SET_ORDER_EVIDENCE: 'SET_ORDER_EVIDENCE'
}

const setOrderSelected = (order: IOrderItemResponse | null) => ({
  type: actionTypes.SET_ORDER_SELECTED,
  payload: order,
})

const setOrderUpdated = (order: IOrderUpdateResponse) => ({
  type: actionTypes.SET_ORDER_UPDATED,
  payload: order,
})

const setOrderDeliveryChecked = (order: IOrderDeliveryItemResponse[]) => ({
  type: actionTypes.SET_ORDER_DELIVERY_CHECKED,
  payload: order,
})

const setOrderDeliveryUpdated = (order: IOrderDeliveryCreateResponse) => ({
  type: actionTypes.SET_ORDER_DELIVERY_UPDATED,
  payload: order,
})

const setOrderDeliveries = (order: IOrderDeliveryItemResponse | null) => ({
  type: actionTypes.SET_ORDER_DELIVERIES,
  payload: order,
})

const setOrderEvidence = (order: IUpdateEvidenceResponse | null) => ({
  type: actionTypes.SET_ORDER_EVIDENCE,
  payload: order,
})

export const orderActions = {
  setOrderSelected,
  setOrderUpdated,
  setOrderDeliveryChecked,
  setOrderDeliveryUpdated,
  setOrderDeliveries,
  setOrderEvidence,
}
