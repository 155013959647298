import { useAxios } from '../../hooks'
import { ITaskItemResponse } from './task-list.interface'

export const useTaskList = () => {
  const { execute, response, loading } = useAxios<ITaskItemResponse[]>()

  const getTask = () => {
    execute({
      method: 'GET',
      url: '/maintenance/task',
    })
  }

  return {
    getTask,
    loading,
    tasks: response?.reverse() || []
  }
}
