import { IAuthResponse } from '../auth/auth.interface'
import { formatInTimeZone } from 'date-fns-tz'
import { ICreateReturnRequest, IReturnProductRequest, IUpdateReturnRequest } from './return-create.interface'
import { ICreateReturnSchema } from './return-create.schema'

export const mapToReturnUpdateRequest = (
  authUser: IAuthResponse,
  schema: ICreateReturnSchema
): IUpdateReturnRequest => {
  return {
    shippingCost: parseFloat(`${schema.shippingCost || 0}`),
    originCost: parseFloat(`${schema.originCost || 0}`),
    destinationCost: parseFloat(`${schema.destinationCost || 0}`),
    photoMerchandiseDestination: schema.photoMerchandiseDestination,
    photoMerchandiseOrigin: schema.photoMerchandiseOrigin,
    photoReturnDestination: schema.photoReturnDestination,
    photoReturnOrigin: schema.photoReturnOrigin,
    returnDate: schema.returnDate,
    commentDestination: schema.commentDestination,
    urlGuideDocument: schema.urlGuideDocument,
    updatedBy: authUser.user.id,
    returnStatus: schema.returnStatus,
    guideCode: schema.guideCode,
    comment: schema.comment,
    urlVoucher: schema.urlVoucher,
    isCalculateProduct: schema.returnStatus === 'IN_AGENCY'
  }
}

export const mapToReturnCreateRequest = (
  authUser: IAuthResponse,
  schema: ICreateReturnSchema
): ICreateReturnRequest => {

  const today = formatInTimeZone(new Date(), 'America/Lima', 'yyMMdd')
  const dptoOriginAbrev = schema.departmentOriginName.length <= 3 ? schema.departmentOriginName.toUpperCase() : 
    schema.departmentOriginName.replace(/\s/g, '').trim().substring(0, 3).toUpperCase()
  const dptoDestinationAbrev = schema.departmentName.length <= 3 ? schema.departmentName.toUpperCase() : 
    schema.departmentName.replace(/\s/g, '').trim().substring(0, 3).toUpperCase()
  const motorizedAbrev = schema.motorizedRecipient.name.replace(/\s/g, '').trim().toUpperCase()
  const returnNumber = `${dptoOriginAbrev}-${dptoDestinationAbrev}-${motorizedAbrev}-${today}`
  const ids = schema.ordersId.length > 0 ? schema.ordersId.map(o => o.id) : []
  
  return {
    returnNumber: returnNumber,
    motorizedRecipient: schema.motorizedRecipient,
    motorizedSender: schema.motorizedSender,
    motorizedRecipientId: schema.motorizedRecipientId,
    motorizedSenderId: schema.motorizedSenderId,
    shippingCost: parseFloat(`${schema.shippingCost || 0}`),
    urlGuideDocument: schema.urlGuideDocument,
    createdBy: authUser.user.id,
    companyId: authUser.user.company.id,
    departmentName: schema.departmentName,
    departmentCode: `${schema.departmentCode}`,
    provinceName: schema.provinceName,
    provinceCode: `${schema.provinceCode}`,
    returnStatus: schema.returnStatus,
    guideCode: schema.guideCode,
    orderIds: ids,
    comment: schema.comment,
    urlVoucher: schema.urlVoucher,
    commentDestination: schema.commentDestination,
    departmentOriginCode: `${schema.departmentOriginCode}`,
    departmentOriginName: schema.departmentOriginName,
    provinceOriginCode: `${schema.provinceOriginCode}`,
    provinceOriginName: schema.provinceOriginName,
    originCost: schema.originCost,
    destinationCost: schema.destinationCost,
    products: schema.products.map(p => {
      const value: IReturnProductRequest = {
        ean: p.ean,
        quantity: p.quantity,
        skuName: p.productName,
        codeSku: p.ean,
        warehouseId: p.WarehouseId,
        warehouseName: p.warehouseName,
        companyId: p.companyId,
        companyName: p.companyName,
        skuId: p.skuId,
        orderId: p.id,
        orderNumber: p.orderNumber,
      }

      return value
    })
  }

}
