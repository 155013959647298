import React, { } from 'react'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import ChatForm from '../../forms/ChatForm/ChatForm'

const OrderChatContainer = () => {
  const { orderSelected } = useOrderStore()

  return <>
    { orderSelected ? (
      <>
        <ChatForm
          orderNumber={orderSelected?.orderNumber}
        />
        
      </>
    ) : null}
  </>
}

export default OrderChatContainer
