import React, { useEffect } from 'react'
import { useFrequentQuestions } from '../../../../services/frequent-question-list/frequent-question.service'
import { QuestionsTable } from '../../components'
import { Typography } from 'antd'

const { Title } = Typography

const FrequentQuestionContainer = () => {
  const { getQuestions, loading, questions } = useFrequentQuestions()

  useEffect(() => {
    getQuestions()
  }, [])
  
  return <div 
    style={{ marginLeft: '15%', marginRight: '15%', marginTop: '24px', marginBottom: '24px' }}
  >
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Title level={3} style={{ color: '#ff9800' }}>Preguntas Frecuentes</Title>
      <Title level={5}>Guía completa de Aliclik: Todo lo que necesitas saber y vender más.</Title>
    </div>
    
    <br></br>
    <QuestionsTable loading={loading} data={questions} />
  </div>
}

export default FrequentQuestionContainer
