import { IPreOrderItemResponse } from '../../services/pre-order/pre-order.interface'

export const actionTypes = {
  SET_PRE_ORDER_SELECTED: 'SET_PRE_ORDER_SELECTED',
}

const setPreOrderSelected = (preOrder: IPreOrderItemResponse) => ({
  type: actionTypes.SET_PRE_ORDER_SELECTED,
  payload: preOrder,
})

export const preOrderActions = {
  setPreOrderSelected,
}
