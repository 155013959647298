import React, { FC, useEffect } from 'react'
import { Button, Divider, Form, Input, InputNumber, Popover, Select, Typography } from 'antd'
import { IWarehouseCreateSchema } from '../../../../../services/warehouse-create/warehouse-create.schema'
import { useWarehouseStore } from '../../../../../services/warehouse-create/warehouse.store'
import { WarehouseLocationPart } from './parts'
import { useUsersList } from '../../../../../services/user-list/user-list.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import TextArea from 'antd/lib/input/TextArea'
import { BANK_LIST } from '../../../../../config/data.params'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useAuthStore } from '../../../../../services/auth/auth.service'

interface IWarehouseEditFormProps {
  onSubmit: (data: IWarehouseCreateSchema) => void
}

const { Title } = Typography

const WarehouseEditForm: FC<IWarehouseEditFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<IWarehouseCreateSchema>()
  const { warehouseSelected } = useWarehouseStore()
  const { getStores } = useUsersList()
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()

  useEffect(() => {
    if (warehouseSelected) {
      form.setFieldsValue({
        id: warehouseSelected.id,
        name: warehouseSelected.name,
        userId: warehouseSelected.userId,
        reference: warehouseSelected.reference,
        phone: warehouseSelected.phone,
        cci: warehouseSelected.cci,
        accountNumber: warehouseSelected.accountNumber,
        codeBank: warehouseSelected.codeBank,
        holderName: warehouseSelected.holderName,
        businessName: warehouseSelected.businessName,
        businessDocument: warehouseSelected.businessDocument,
        emailTransfer: warehouseSelected.emailTransfer,
        documentHolderAccount: warehouseSelected.documentHolderAccount,
      })
    }
  }, [warehouseSelected])

  const content = (
    <div>
      <h4>Cuenta soles</h4>
      <p>N° de cuenta: 47505215317055</p>
      <p>CCI: 00247510521531705520</p>
    </div>
  )

  useEffect(() => {
    if (companyGlobal) {
      getStores(companyGlobal ? `${companyGlobal.id}` : '')
    }
  }, [companyGlobal])

  return (
    <>
      <Form
        layout='horizontal'
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 17 }}
        form={form}
        name='UserForm'
        labelWrap
        onFinish={onSubmit}
      >
        <Form.Item hidden name={['id']}>
          <Input />
        </Form.Item>
        
        { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' ? <>
        
          <Form.Item
            label={<span style={{ fontSize: '13px' }}>
            Nombre Almacén
            </span>}
            name={['name']}
            rules={[{ required: true, message: 'Obligatorio' }]}
          >
            <Input style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}/>
          </Form.Item>

        </> : null }

        <Form.Item
          label={<span style={{ fontSize: '13px' }}>
          Telf. Soporte Almacén
          </span>}
          name={['phone']}
          rules={[{ required: true, message: 'Obligatorio' }]}
        >
          <InputNumber
            controls={false}
            maxLength={11}
            style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}
          />
        </Form.Item>

        { authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' ? <>

          <Form.Item
            label={<span style={{ fontSize: '13px' }}>
            Contraentrega en
            </span>}
            name={['reference']} >
            <TextArea style={{ display: 'inline-block', width: '100%', borderRadius: '8px' }}/>
          </Form.Item>

        </> : null }

        <Title level={5}>Datos bancarios</Title>

        <Form.Item
          label="Banco"
          name={['codeBank']}
          required={true}
        >
          <Select
            placeholder="Seleccione una entidad bancaria"
            allowClear
            options={BANK_LIST.map(e => ({
              label: `${e.value} / ${e.code}`,
              value: e.code,
            }))}
          />
        </Form.Item>

        <Form.Item
          label={<span>
            <div>
              Número de cuenta <Popover content={content} title='Cuentas'>
                <QuestionCircleOutlined />
              </Popover>
            </div>
          </span>}
          name={['accountNumber']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={<span>
            <div>
              Número de cuenta interbancaria (CCI) <Popover content={content} title='Cuentas'>
                <QuestionCircleOutlined />
              </Popover>
            </div>
          </span>}
          name={['cci']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Nombre completo del titular"
          name={['holderName']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Correo para constancia transferencia"
          name={['emailTransfer']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="RUC/DNI titular de la cuenta"
          name={['documentHolderAccount']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Title level={5}>Datos para facturación</Title>

        <Form.Item
          label="RUC (DNI si no cuentas RUC)"
          name={['businessDocument']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Razón social"
          name={['businessName']}
          required={true}
        >
          <Input type="text" />
        </Form.Item>


        { !warehouseSelected ? <>
          <Title level={5}>Datos ubicación</Title>
          <WarehouseLocationPart form={form} />
        </> : null }

        <Divider />

        <Button type='primary' htmlType='submit'>
          Guardar
        </Button>
      </Form>
    </>
  )
}

export default WarehouseEditForm
