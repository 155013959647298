/* eslint-disable max-len */
import React from 'react'
import { Table, TableColumnsType } from 'antd'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { IOrderDeliveryDeliveryResponse } from '../../../../../services/order-motorized/order-motorized.interface'
import { formatDateUTC, validateUrlDelivery } from '../../../../../utils/date'
import { ImagePreview } from '../../../../DispatchModule/DispatchGuideMainView/components'

const DeliveriesContainer = () => {
  const { orderDeliveries } = useOrderStore()
  const LINK = 'https://www.appsheet.com/template/gettablefileurl?appName=Alidriver-284814394&tableName=OrderDelivery&fileName='

  const columns: TableColumnsType<IOrderDeliveryDeliveryResponse> = [
    {
      title: 'Motorizado',
      dataIndex: 'user',
      key: 'user',
      render: (_, delivery) => <>
        {(delivery.deliveryStatus === 'PENDING_DELIVERY' && delivery.isAssigned === false) ? 
          'No asignado' : delivery.user?.email}</>
    },
    {
      title: 'Estado de entrega',
      dataIndex: 'deliveryStatus',
      key: 'deliveryStatus', align: 'center',
    },
    {
      title: 'Último estado antes Reprogramar',
      dataIndex: 'lastRescheduledStatus', align: 'center',
      key: 'lastRescheduledStatus',
    },
    {
      title: 'Fecha',
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      render: (_, d) => <>{formatDateUTC(d.deliveryDate)}</>
    },
    {
      title: 'Comentario',
      dataIndex: 'comment',
      key: 'comment',
      render: (_, d) => <>{d.comment}</>
    },
    {
      title: 'Evidencia Chat',
      dataIndex: 'evidenceChat',
      key: 'evidenceChat',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceChat && delivery.evidenceChat.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceChat) ? `${LINK}${delivery.evidenceChat}` : `${delivery.evidenceChat}`}
          ></ImagePreview>
          : null}
      </>
    },
    {
      title: 'Evidencia llamada',
      dataIndex: 'evidenceCall',
      key: 'evidenceCall',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceCall && delivery.evidenceCall.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceCall) ? 
              `${LINK}${delivery.evidenceCall}` : `${delivery.evidenceCall}`}
          ></ImagePreview>
          : null}
      </>
    },
    {
      title: 'Evidencia llamada por chat',
      dataIndex: 'evidenceCallChat',
      key: 'evidenceCallChat',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceCallChat && delivery.evidenceCallChat.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceCallChat) ? 
              `${LINK}${delivery.evidenceCallChat}` : `${delivery.evidenceCallChat}`}
          ></ImagePreview>
          : null}
      </>
    },
    {
      title: 'Evidencia entrega',
      dataIndex: 'evidenceDelivery',
      key: 'evidenceDelivery',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceDelivery && delivery.evidenceDelivery.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceDelivery) ? 
              `${LINK}${delivery.evidenceDelivery}` : `${delivery.evidenceDelivery}`}
          ></ImagePreview>
          : null}
      </>
    },
    {
      title: 'Evidencia soporte',
      dataIndex: 'evidenceSupport',
      key: 'evidenceSupport',
      align: 'center',
      render: (_, delivery) => <>
        {delivery.evidenceSupport && delivery.evidenceSupport.length > 0 ?
          <ImagePreview 
            url={validateUrlDelivery(delivery.evidenceSupport) ? 
              `${LINK}${delivery.evidenceSupport}` : `${delivery.evidenceSupport}`}
          ></ImagePreview>
          : null}
      </>
    },
  ]

  return <div style={{ overflow: 'auto' }}>
    { orderDeliveries ? (
      <>
        <Table
          rowKey="id"
          size='small'
          dataSource={orderDeliveries.orderDeliveries}
          columns={columns}
        />
      </>
    ) : null }
  </div>
}

export default DeliveriesContainer
