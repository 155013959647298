import React, { useEffect } from 'react'
import { Spin } from 'antd'
import { useGetSetting, useSaveSetting } from '../../../../services/setting/setting.service'
import { ConfigOrderForm } from '../../forms'
import { ISettingSchema } from '../../../../services/setting/setting.schema'
import { CompanyChangeContainer } from '../../../../containers'
import { PageHeader } from '../../../../components'
import { useAuthStore } from '../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../services/company-create/company-create.service'

const ConfigOrderContainer = () => {
  const { authUser } = useAuthStore()
  const { getSetting, setting, loading: loadingGet } = useGetSetting()
  const { saveSetting, loading: loadingSave } = useSaveSetting()
  const { companyGlobal } = useCompanyStore()

  const handleSubmit = (setting: ISettingSchema) => {
    saveSetting(setting)
  }

  useEffect(() => {
    getSetting(companyGlobal ? `${companyGlobal.id}` : '')
  }, [companyGlobal])

  return <>
    <PageHeader>
      {authUser.user.role?.permissions?.setting_company_change ? (
        <CompanyChangeContainer isAll={false} />
      ) : null}
    </PageHeader>

    <Spin spinning={loadingGet || loadingSave}>
      <div className="container">
        <ConfigOrderForm
          initialValues={setting}
          onSubmit={handleSubmit}
        />
      </div>
    </Spin>
  </>
}

export default ConfigOrderContainer
