/* eslint-disable max-len */
import React, { FC } from 'react'
import { Tooltip } from 'antd'
import { MessageOutlined, PhoneOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { useTemplateMsg } from '../../../../../services/templateMsg/template.service'
import './PreOrderActionIcons.css'
import { IPreOrderItemResponse } from '../../../../../services/pre-order/pre-order.interface'

interface IPreOrderActionIconsProps {
  order: IPreOrderItemResponse
}

const PreOrderActionIcons: FC<IPreOrderActionIconsProps> = ({ order }) => {
  const { getPreOrderFollow } = useTemplateMsg()

  return <>
    <div className="PreOrderActionIcons">
      <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=${order.phone}`}>
        <Tooltip placement="bottom" title="WhatsApp">
          <WhatsAppOutlined
            className='PreOrderActionIcons-green'
          />
        </Tooltip>
      </a>

      <a
        target="_blank"
        rel="noreferrer"
        href={`https://api.whatsapp.com/send?phone=${order.phone}&text=${getPreOrderFollow(order)}`}>
        <Tooltip placement="bottom" title="Mensaje">
          <MessageOutlined
            className='PreOrderActionIcons-blue'
          />
        </Tooltip>
      </a>
      <a href={`tel:+${order.phone}`}>
        <Tooltip placement="bottom" title="Llamar">
          <PhoneOutlined
            className='PreOrderActionIcons-gray'
          />
        </Tooltip>
      </a>  
    </div>
  </>

}

export default PreOrderActionIcons
