/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { useOrderStore } from '../order-list/order.store'
import { useCompanyStore } from '../company-create/company-create.service'
import { message } from 'antd'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'
import { IOrderUpdateResponse } from '../order-update/order-update.interface'
import { IOrderUpdateSchema } from '../order-update/order-update.schema'
import { mapToOrderCreateRequest, mapToOrderUpdateRequest } from '../order-update/order-update.mapper'

export const useOrderAgencyCreateOrUpdate = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { orderSelected } = useOrderStore()
  const { execute, response, loading } = useAxios<IOrderUpdateResponse>()

  const orderAgencyCreateOrUpdate = (order: IOrderUpdateSchema) => {

    if (order.orderDetails.length === 0 && order.callStatus === 'CONFIRMED') {
      message.warning('Agregar por lo menos un producto')

      return
    }

    const method = orderSelected?.id ? 'PATCH' : 'POST'
    const url = orderSelected?.id ? `/order/agency/${orderSelected?.id}` : '/order/agency/create'
    let orderRequest = null

    if (orderSelected?.id) {
      orderRequest = mapToOrderUpdateRequest(
        authUser,
        order,
      )

      datadogLogs.logger.info('ORDER_AGENCY_UPDATE', { action: 'ORDER_AGENCY_UPDATE', order: orderRequest,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        orderStatus: orderRequest.status,
        orderId: orderSelected?.id
      })

    } else {
      orderRequest = mapToOrderCreateRequest(
        authUser,
        order,
        companyGlobal
      )

      datadogLogs.logger.info('ORDER_AGENCY_CREATE', { action: 'ORDER_AGENCY_CREATE', order: orderRequest,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        orderStatus: orderRequest.status,
        orderNumber: orderRequest.orderNumber
      })

    }

    execute({
      method,
      url,
      data: orderRequest,
    })
  }

  useEffect(() => {
    if (response) {
      const action = orderSelected?.id ? 'ORDER_AGENCY_UPDATE:POST' : 'ORDER_AGENCY_CREATE:POST'

      datadogLogs.logger.info(action, { action: action,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        orderResponse: response,
        orderId: response?.id
      })

    }
  }, [response])

  return {
    orderAgencyCreateOrUpdate,
    orderUpdated: response,
    loading,
  }
}
