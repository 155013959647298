/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Space, Spin } from 'antd'
import Title from 'antd/lib/typography/Title'
import { formatDate } from '../../../../../utils/date'
import { OrderEditForm } from '../../forms'
import { IOrderUpdateSchema } from '../../../../../services/order-update/order-update.schema'
import { useOrderUpdate } from '../../../../../services/order-update/order-update.service'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { useOrderConfirmedEdit } from '../../../../../services/order-create/order.edit'
import { TagOutlined } from '@ant-design/icons'

const OrderEditContainer = () => {
  const { orderSelected: order, setOrderUpdated } = useOrderStore()
  const { orderUpdate, orderUpdated, loading } = useOrderUpdate()
  const { closeDrawer } = useDrawer()
  const [disabled, setDisabled] = useState<boolean>(false)
  const [required, setRequired] = useState<boolean>(true)
  const { companyUser, isEditable } = useOrderConfirmedEdit()

  const handleSubmit = (data: IOrderUpdateSchema) => {
    orderUpdate(data)
  }

  useEffect(() => {
    if (order) {
      if (companyUser === 1) { // PRINCIPAL
        if (order.callStatus === 'CONFIRMED') {
          if (order.status === 'PENDING_DELIVERY' ||
            order.status === 'RESCHEDULED') {
            if (isEditable) {
              setDisabled(false)
            } else {
              setDisabled(true)
            }
          } else {
            setDisabled(true)
          }
        } else {
          setDisabled(false)
        }
      } else if (companyUser == 2) { //NORMAL
        if (order.callStatus === 'CONFIRMED') {
          if (order.isAssigned) {
            setDisabled(true)
          } else {
            setDisabled(false)
          }
        } else {
          setDisabled(false)
          setRequired(false)
        }
      } else if (companyUser === 3) {
        setDisabled(false)
        setRequired(false)
      }
    }
  }, [])

  useEffect(() => {
    if (orderUpdated) {
      closeDrawer()
      setOrderUpdated(orderUpdated)
    }
  }, [orderUpdated])

  return <>
    {order ? (
      <>
        <Space align="baseline">
          <Title level={3}>Orden #{order?.orderNumber}</Title>
        </Space>

        <div style={{ marginBottom: '1.5rem' }}>
          <div>
            <Space>
              <div><b>Fecha de pedido:</b></div>
              <div>
                {formatDate(order?.createdAt)}
              </div>
            </Space>
          </div>
          <div>
            <Space>
              <div><b>Vendedor:</b></div>
              <div>
                {order.user?.fullname}
              </div>
            </Space>
          </div>
          { order.callStatus !== 'CONFIRMED' ? <>
            <div style={{ marginBottom: '0.35rem' }}>
              <Space>
                <div><TagOutlined /></div>
                <div>
                  <div>{order.productShopifyDetail}</div>
                </div>
              </Space>
            </div>
          </> : null }
        </div>
      </>
    ) : (
      <Space align="baseline">
        <Title level={4}>Nueva orden</Title>
      </Space>
    )}

    <Spin spinning={loading}>
      <OrderEditForm
        initialValues={order}
        onSubmit={handleSubmit}
        disabled={disabled}
        required={required}
      />
    </Spin>
  </>
}

export default OrderEditContainer
