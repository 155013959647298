import { IAuthResponse } from '../auth/auth.interface'
import { ICompanyItemResponse } from '../company-list/company-list.interface'
import { 
  ITicketSupportRequest,
  ITicketSupportUpdateRequest
} from '../ticket-support-list/ticket-support-list.interface'
import { ITicketSchema } from '../ticket-support-list/ticket-support.schema'

export const mapToTicketCreateRequest = (
  auth: IAuthResponse,
  company: ICompanyItemResponse | null,
  schema: ITicketSchema
): ITicketSupportRequest => {
    
  return {
    title: schema.title,
    reason: schema.reason,
    createdBy: schema.createdBy,
    servedAt: schema.servedAt,
    servedBy: schema.servedBy,
    orderNumber: schema.orderNumber,
    phone: `${schema.phone}`,
    topicId: parseInt(`${schema.topic}`),
    userServed: schema.userServed,
    companyId: company ? company.id : auth.user.company.id,
    comment: schema.comment,
    status: schema.status,
    urlEvidence01: schema.urlImage1,
    urlEvidence02: schema.urlImage2,
    urlEvidence03: schema.urlImage3,
  }
  
}

export const mapToTicketUpdateRequest = (
  auth: IAuthResponse,
  schema: ITicketSchema
): ITicketSupportUpdateRequest => {

  if (auth.user.role.name === 'ADMIN') {
    return {
      reason: schema.reason,
      comment: schema.comment,
      servedAt: new Date().toISOString(),
      servedBy: schema.servedBy,
      userServed: schema.userServed,
      status: schema.status
    }
  }

  return {
    reason: schema.reason,
    comment: schema.comment,
    status: schema.status,
    phone: `${schema.phone}`,
    urlEvidence01: schema.urlImage1,
    urlEvidence02: schema.urlImage2,
    urlEvidence03: schema.urlImage3,
  }
}
