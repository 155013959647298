import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import { ICodeAuthRequest, IPasswordSchema, IResetPasswordSchema } from '../../../../services/auth/auth.interface'
import { useSendCodeAuthResetPassword, useVerifyCodeAuth } from '../../../../services/auth/auth.service'

interface IPassVerifyFormProps {
  loadingChangePassword: boolean
  resetPassword: (request: IResetPasswordSchema) => void
}

const PassVerifyForm: FC<IPassVerifyFormProps> = ({ loadingChangePassword, resetPassword }) => {

  const [form] = Form.useForm<IResetPasswordSchema>()
  const emailText = Form.useWatch(['email'], form)
  const userText = Form.useWatch(['userEmail'], form)
  const codeAuthText = Form.useWatch(['codeAuth'], form)
  const { sendCodeAuth, response, loading } = useSendCodeAuthResetPassword()
  const { verifyCodeAuth, responseCodeAuth, loadingCodeAuth } = useVerifyCodeAuth()
  const [sendCode, setSendCode] = useState(false)
  const [validateCode, setValidateCode] = useState(false)

  const onSendCodeAuth = () => {
    
    if (userText && emailText) {
      const request: IPasswordSchema = {
        userName: userText,
        email: emailText
      }

      sendCodeAuth(request)
      
    } else {

      form.validateFields(['userEmail'])
      form.validateFields(['email'])
    }

  }

  const verifyCode = () => {

    if (codeAuthText) {
      const request: ICodeAuthRequest = {
        userId: response?.userId,
        codeAuth: codeAuthText
      }
  
      verifyCodeAuth(request)
    } else {
      form.validateFields(['codeAuth'])
    }
    
  }

  useEffect(() => {
    if (response) {
      setSendCode(true)
      form.setFieldValue('userId', response.userId)
      message.info(`Se le envio el código de recuperación de contraseña a su correo ${response.email}.`)
    }
  }, [response])

  useEffect(() => {
    if (responseCodeAuth) {
      if (responseCodeAuth.isVerify) {
        setValidateCode(true)
        message.info('Código de recuperación verificado exitósamente.')
      }
    }
  }, [responseCodeAuth])

  return <>
    <Form
      layout='horizontal'
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17 }}
      form={form}
      name='ResetPasswordForm'
      labelWrap
      onFinish={resetPassword}
    >

      <Form.Item
        name='userId'
        hidden
      >
        <Input hidden/>
      </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <h2 style={{ color: '#ff9800', fontWeight: 'bold', justifyContent: 'center', display: 'flex',
          marginBottom: '18px'
        }}>
          Restablecer contraseña
        </h2>
      </div>

      <Form.Item
        label={<span style={{ fontSize: '13px' }}>
          Usuario Aliclik
        </span>}
        name="userEmail"
        rules={[{ required: true, message: 'Ingrese su usuario' }]}
      >
        <Input style={{ borderRadius: '8px', borderColor: '#ff9800' }}/>
      </Form.Item>
                  
      <Form.Item
        label={<span style={{ fontSize: '13px' }}>
          Correo electrónico
        </span>}
        name="email"
        rules={[{ required: true, message: 'Ingrese su correo electrónico', type: 'email' }]}
      >
        <Input style={{ borderRadius: '8px', borderColor: '#ff9800' }}/>
      </Form.Item>

      { sendCode ? <>

        { validateCode ? <>
        
          <Form.Item
            label={<span style={{ fontSize: '13px' }}>
            Nueva contraseña
            </span>}
            name={['password']}
            rules={[{ required: true, message: 'Ingrese su nueva contraseña' }]}
          >
            <Input style={{ borderRadius: '8px', borderColor: '#ff9800' }}/>
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type='primary'
              loading={loadingChangePassword}
              htmlType="submit"
              shape='round'
              style={{ backgroundColor: '#ff9800', borderColor: '#ff9800', fontWeight: 'bold', marginTop: '14px' }}
            >
              Actualizar contraseña
            </Button>
          </div>
        
        </> : <>
        
          <Form.Item
            label={<span style={{ fontSize: '13px' }}>
            Ingrese el código de recuperación
            </span>}
            name="codeAuth"
            rules={[{ required: true, message: 'Ingrese su código recuperación enviado a su correo' }]}
          >
            <Input style={{ borderRadius: '8px', borderColor: '#ff9800' }}/>
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type='primary'
              loading={loadingCodeAuth}
              onClick={() => verifyCode()}
              shape='round'
              style={{ backgroundColor: '#ff9800', borderColor: '#ff9800', fontWeight: 'bold', marginTop: '14px' }}
            >
              Validar código de recuperación de contraseña
            </Button>
          </div>
        
        </> }
      
      </> : <>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
        
          <Button
            type='primary'
            loading={loading}
            onClick={() => onSendCodeAuth()}
            shape='round'
            style={{ backgroundColor: '#ff9800', borderColor: '#ff9800', fontWeight: 'bold', marginTop: '14px' }}
          >
            Enviar código de recuperación de contraseña
          </Button>

        </div>
      
      </> }
      
    </Form>
  </>
}

export default PassVerifyForm
