/* eslint-disable max-len */
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Input, Select, Space } from 'antd'

const { Option } = Select

interface IOrderSearchProps {
  onSearch: (filter: { [key: string]: string }) => void
  provinceList: string[]
}

const OrderSearch: FC<IOrderSearchProps> = ({ onSearch, provinceList }) => {
  const [optionSelect, setOptionSelect] = useState<string>('')
  const [filterValue, setFilterValue] = useState<{ [key: string]: string }>()

  const handleChange = (value: string) => {
    setOptionSelect(value)
  }

  const handleSearch = (value: string) => {
    if (!optionSelect || !value) {
      return
    }

    setFilterValue({
      [optionSelect]: value,
    })
  }

  const handleSearchAllChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const text = evt.target.value

    if (text) {
      setFilterValue({
        [optionSelect]: text,
      })
    } else {
      setFilterValue({
        reset: '1',
      })
    }
  }

  useEffect(() => {
    if (filterValue) {
      onSearch(filterValue)
    }
  }, [filterValue])

  return (
    <div style={{
      display: 'flex', alignItems: 'center', gap: '0.25rem',
    }}>
      <Space>
        <Select placeholder="Buscar por" style={{ width: '130px' }} onChange={handleChange}>
          <Option value="all">Todos</Option>
          <Option value="orderNumber">Número orden</Option>
          <Option value="phone">Teléfono</Option>
          <Option value="address">Dirección</Option>
          <Option value="province">Provincia</Option>
          <Option value="warehouseName">Almacén</Option>
          {/*<Option value="name">Nombre</Option>
          <Option value="lastName">Apellidos</Option>
          <Option value="note">Notas</Option>
          <Option value="reference">Referencia</Option>
          <Option value="province">Provincia</Option>
          <Option value="fullname">Vendedor</Option>
          <Option value="email">Motorizado</Option>
          <Option value="totalPayment">Total cobrado</Option>
          <Option value="shippingCost">Costo envío</Option>*/}
        </Select>

        {optionSelect === 'province' ? (
          <Select placeholder="Provincia" style={{ width: '100px' }} onChange={handleSearch}>
            {provinceList?.map(e => (
              <Option key={e} value={e}>{e}</Option>
            ))}
          </Select>
        ) : (
          <Input
            placeholder="Búsqueda"
            style={{ width: 120 }}
            onChange={handleSearchAllChange}
          />
        )}
      </Space>
    </div>
  )
}

export default OrderSearch
