import React from 'react'
import { Table, Tag } from 'antd'
import { IAuthUserResponse } from '../../../../../services/auth/auth.interface'
import { ColumnsType } from 'antd/lib/table'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useUserStore } from '../../../../../services/user/user.service'
import CoverageByUserTable from './parts/CoverageByUserTable'

interface IUsersListTableProps {
  users: IAuthUserResponse[]
  loading: boolean
}

const CoveragesListTable: React.FC<IUsersListTableProps> = ({ users, loading }) => {
  const { openDrawer } = useDrawer()
  const { setUserSelected } = useUserStore()

  const handleSelected = (data: IAuthUserResponse) => {
    setUserSelected(data)
    openDrawer('USER_EDIT', 'Editar usuario')
  }

  const handleCoverage = (data: IAuthUserResponse) => {
    setUserSelected(data)
    openDrawer('COVERAGE_EDIT', 'Agregar cobertura')
  }

  const columns: ColumnsType<IAuthUserResponse> = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: '11%',
    },
    {
      title: 'Usuario',
      dataIndex: 'email',
      width: '11%',
    },
    {
      title: 'Nombres',
      dataIndex: 'fullname',
      width: '11%',
    },
    {
      title: 'Telefono',
      dataIndex: 'phone',
      width: '11%',
    },
    {
      title: 'Status', dataIndex: 'status', width: 120,
      render: (_, user) => <>
        <Tag color={user.status === 'INACTIVE' ? 'red' : 'geekblue' }>{user.status}</Tag>
      </>,
    },
    {
      title: 'Compañía', dataIndex: 'companyId', width: 120,
      render: (_, user) => <>{user.company.name}</>,
    },
    {
      title: 'Tipo compañía', dataIndex: 'companyId', width: 120,
      render: (_, user) => <>{user.company.type}</>,
    },
    {
      title: 'Rol', dataIndex: 'rolId', width: 120,
      render: (_, user) => <>{user.role.description}</>,
    },
    {
      title: 'Editar', dataIndex: 'edit', width: 120, align: 'center',
      render: (_, user) => <a onClick={() => handleSelected(user)}>Editar</a>,
    },
    {
      title: 'Coberturas', dataIndex: 'coverage', width: 120, align: 'center',
      render: (_, user) => <a onClick={() => handleCoverage(user)}>+ Cobertura</a>,
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={users}
      columns={columns}
      rowClassName="editable-row"
      loading={loading}
      size="small"
      expandable={{
        expandedRowRender: (record) => <CoverageByUserTable record={record} />,
        rowExpandable: record => !!record.coverages?.length,
      }}
    />
  )
}

export default CoveragesListTable

