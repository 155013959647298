/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { Table, TableColumnsType } from 'antd'
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store'
import { ISummaryProductItem } from '../../../../../services/dispatch-guide-list/dispatch-guide-list.interface'
import { useSummaryProductsDispatch } from '../../../../../services/summary-products/summary-products.service'

const DispatchGuideSummaryContainer = () => {
  const { dispatchGuideSelected } = useDispatchGuideStore()
  const { getSummaryProductsDispatch, summaryProductsDispatch, loading } = useSummaryProductsDispatch()
  const [ summaryProducts, setSummaryProducts ] = useState<ISummaryProductItem[]>()

  useEffect(() => {
    if (dispatchGuideSelected) {
      getSummaryProductsDispatch(dispatchGuideSelected.id)
    }
  }, [dispatchGuideSelected])

  useEffect(() => {
    if (summaryProductsDispatch.length > 0 && dispatchGuideSelected && dispatchGuideSelected.summaryDispatchProduct.length > 0) {
      
      let summaryProducts: ISummaryProductItem[] = []

      const products = dispatchGuideSelected.summaryDispatchProduct.map(p => {
        const summaryDelivered = summaryProductsDispatch.find(t => t.sku === p.codeSku)
        
        if (summaryDelivered) {
          const value: ISummaryProductItem = {
            quantityDelivered: summaryDelivered.quantity,
            productDelivered: summaryDelivered.name,
            quantityRemaining: p.quantity - summaryDelivered.quantity,
            productRemaining: p.skuName,
            sku: p.codeSku,
            warehouseName: summaryDelivered.warehouseName,
            companyName: summaryDelivered.companyName
          }

          return value
        } else {
          const value: ISummaryProductItem = {
            quantityDelivered: 0,
            productDelivered: '-',
            quantityRemaining: p.quantity,
            productRemaining: p.skuName,
            sku: p.codeSku,
            warehouseName: p.warehouseName,
            companyName: p.companyName,
          }

          return value

        }
      })

      summaryProducts = products

      if (summaryProductsDispatch.length > 0) {

        summaryProductsDispatch.forEach(p => {

          const pd = summaryProducts.find(ps => ps.sku === p.sku)

          if (!pd) {
            const value: ISummaryProductItem = {
              quantityDelivered: p.quantity,
              productDelivered: p.name,
              quantityRemaining: 0,
              productRemaining: '-',
              sku: p.sku,
              warehouseName: p.warehouseName,
              companyName: p.companyName
            }

            summaryProducts.push(value)
          }

        })

      }

      setSummaryProducts(summaryProducts)
    }
  }, [summaryProductsDispatch])

  const columns: TableColumnsType<ISummaryProductItem> = [
    {
      title: 'Producto entregado',
      dataIndex: 'productDelivered',
      key: 'productDelivered',
      align: 'center',
      render: (_, d) => <>{d.productDelivered} {d.quantityDelivered > 0 ? <>
      - {d.companyName}
      </> : ''}</>
    },
    {
      title: 'Bodega origen',
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      align: 'center',
      render: (_, d) => <>{d.warehouseName}</>
    },
    {
      title: 'Cantidad entregada',
      dataIndex: 'quantityDelivered',
      key: 'quantityDelivered',
      align: 'center',
      render: (_, d) => <>{d.quantityDelivered}</>
    },
    {
      title: 'Producto restante',
      dataIndex: 'productRemaining',
      key: 'productRemaining',
      align: 'center',
      render: (_, d) => <>{d.productRemaining} {d.quantityDelivered == 0 ? <>
      - {d.companyName}
      </> : ''}</>
    },
    {
      title: 'Cantidad restante',
      dataIndex: 'quantityRemaining',
      key: 'quantityRemaining',
      align: 'center',
      render: (_, d) => <>{d.quantityRemaining}</>
    },
  ]

  return <div style={{ overflow: 'auto' }}>
    {dispatchGuideSelected ? (
      <>
        <Table
          rowKey="id"
          size='small'
          dataSource={summaryProducts}
          loading={loading}
          columns={columns}
        />
      </>
    ) : null}
  </div>
}

export default DispatchGuideSummaryContainer
