/* eslint-disable max-len */
import { useAxios } from '../../hooks'
import { IWarehouseCreateSchema } from './warehouse-create.schema'
import { IWarehouseCreateResponse } from './warehouse-create.interface'
import { mapToWarehouseCreateRequest } from './warehouse-create.mapper'
import { useCompanyStore } from '../company-create/company-create.service'
import { useAuthStore } from '../auth/auth.service'

export const useWarehouseCreate = () => {
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()
  const { execute, response: warehouseCreated, loading } = useAxios<IWarehouseCreateResponse>()

  const warehouseCreate = (warehouse: IWarehouseCreateSchema) => {
    
    const request = mapToWarehouseCreateRequest(
      warehouse, authUser, companyGlobal
    )

    const method = warehouse.id ? 'PATCH' : 'POST'
    const url = warehouse.id ? `/warehouse/${warehouse.id}` : '/warehouse'

    execute({
      method: method,
      url: url,
      data: request,
    })
  }

  return {
    warehouseCreate,
    warehouseCreated,
    loading,
  }
}
