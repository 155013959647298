import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IProductWarResponse, IWarehouseSkuAllResponse } from './warehouse-sku-all.interface'

export const useWarehouseSkuAllList = () => {
  const { execute, response, loading } = useAxios<IWarehouseSkuAllResponse[]>()

  const getWarehouseSkuAll = (skuId?: string) => {
    execute({
      method: 'GET',
      url: '/warehouse-sku/all',
      params: {
        skuId: skuId
      }
    })
  }

  return {
    getWarehouseSkuAll,
    loading,
    warehouseSkus: response ? response : []
  }
}

export const userProductsByWarehouseList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IProductWarResponse[]>()

  const getProductsWar = (warId: string, companyId: string) => {
    execute({
      method: 'GET',
      url: 'warehouse-sku/products',
      params: {
        warehouseId: warId,
        companyId: companyId ? companyId : authUser.user.company.id, 
      }
    })
  }

  return {
    getProductsWar,
    warProducts: response ? response : [],
    loading
  }

}

export const userProductStock = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IWarehouseSkuAllResponse>()

  const getProductStock = (warId: string, skuId: string, companyId: string) => {
    execute({
      method: 'GET',
      url: 'warehouse-sku/product-stock',
      params: {
        warehouseId: warId,
        companyId: companyId ? companyId : authUser.user.company.id,
        skuId: skuId, 
        countryCode: authUser.user.company.countryCode
      }
    })
  }

  return {
    getProductStock,
    productStock: response,
    loadingStock: loading,
  }

}
