import { datadogLogs } from '@datadog/browser-logs'
import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IDispatchGuideDeleteResponse } from './dispatch-guide-delete.interface'
import { useEffect } from 'react'

export const useDispatchGuideDelete = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IDispatchGuideDeleteResponse>()
  
  const deleteDispatchGuide = (dispatchGuideId: number) => {
    
    datadogLogs.logger.info('DISPATCH_GUIDE_DELETE', { action: 'DISPATCH_GUIDE_DELETE',
      dispatchGuideId: dispatchGuideId,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      }
    })

    execute({
      method: 'PATCH',
      url: `/dispatch-guide/delete/${dispatchGuideId}`,
    })
      
  }
  
  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('DISPATCH_GUIDE_DELETE:POST', { action: 'DISPATCH_GUIDE_DELETE:POST',
        response: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])
  
  return {
    deleteDispatchGuide,
    loadingDelete: loading,
    dispatchGuideDeleted: response
  }
}
