import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip, Select, Input, DatePicker, Badge, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import { CompanyChangeContainer } from '../../../../../containers'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import {
  CALL_STATUS_LIST,
  ORDER_STATUS_LIST_CONFIRMED,
  ORDER_STATUS_LIST_NOT_AVAILABLE,
} from '../../../../../services/pre-order/pre-order.params'
import { useOrdersCall } from '../../../../../services/order-call/order-call.service'
import { usePreOrderEdit } from '../../../../../services/pre-order/pre-order.edit'
import OrdersTableLevel from '../../components/OrdersTableLevel/OrdersTableLevel'
import moment from 'moment'
import { subDays } from 'date-fns'
import { 
  ORDER_DISPATCH_STATUS_LABEL,
  ORDER_DISPATCH_STATUS_LIST,
  ORDER_STATUS_LABEL,
} from '../../../../../services/order-create/order.params'
import { useLocationLevel1 } from '../../../../../services/location/location.service'
import { OrderResumen } from '../../components'
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service'
import { useSellerList } from '../../../../../services/user-seller-list/user-seller-list.service'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../../routes/params'

const { Option } = Select
const { Search } = Input
const { RangePicker } = DatePicker
const { confirm } = Modal

const OrdersListContainer = () => {
  const {
    getOrdersCallAll, response, loadingOrder, count, page, resumen,
  } = useOrdersCall()
  const { getLocationsGeoLevel1, loadingGeo1, locationsGeolevel1 } = useLocationLevel1()
  const { getSellers, loading, sellers } = useSellerList()
  const { orderUpdated, setOrderSelected } = useOrderStore()
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()
  const { openDrawer } = useDrawer()
  const { userCompany } = usePreOrderEdit()
  const dateFormat = 'YYYY-MM-DD'
  const [selectedDates, setSelectedDates] = useState([
    moment(subDays(new Date(), 30), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])

  const [sellerSelected, setSellerSelected] = useState<string>('ALL')
  const [defaultSelected, setDefaultSelected] = useState<string[]>([CALL_STATUS_LIST.CONFIRMED])
  const [statusSelected, setStatusSelected] = useState<string[]>([])
  const [dispatchSelected, setDispatchSelected] = useState<string>('ALL')
  const [department, setDepartment] = useState<string>('ALL')
  const [filterDate, setFilterDate] = useState<string>('create')
  const [warSelected, setWarSelected] = useState<string>('ALL')
  const { getWarehouses, loadingWarehouses, warehouses } = useWarehouseByCountry()
  const [hiddenWar, setHiddenWar] = useState(false)
  const navigate = useNavigate()

  const handleOrderCreate = () => {

    if (authUser.user.role.name === 'ADMIN_STORE' || authUser.user.role.name === 'SELLER') {

      if (authUser.user.company.configBankAccount === true) {
        setOrderSelected(null)
        openDrawer('ORDER_EDIT', 'Agregar orden')
      } else {
        confirm({
          title: '',
          content: <span>Para que puedas subir tu pedido primero tienes que configurar tus datos bancarios.<br/><br/>
            Mira el video de como hacerlo <a target='_blank' rel='noreferrer' href='https://bit.ly/3Ux8r7d'>
              https://bit.ly/3Ux8r7d
            </a>.</span>,
          okText: 'Ir Configuración',
          cancelText: null,
          onCancel: undefined,
          onOk() {
            navigate(ROUTES.SETTING.PATH, { replace: true })
          },
        })
      }

    } else {
      setOrderSelected(null)
      openDrawer('ORDER_EDIT', 'Agregar orden')
    }
    
  }

  const showWarehouseDefault = () => {
    if (authUser.user.role.name === 'STORE') {
      setWarSelected(`${authUser.user.warehouse?.id}`)
      setHiddenWar(true)
    } else {
      setHiddenWar(false)
      getWarehouses()
    }
  }

  useEffect(() => {

    if (orderUpdated) {
      getOrdersCallAll({
        companyId: companyGlobal?.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('yyyy-MM-DD'),
        endDate: selectedDates[1].format('yyyy-MM-DD'),
        page: 1,
        userId: sellerSelected,
        dispatchStatus: dispatchSelected,
        warehouseId: warSelected,
      })
    }
  }, [orderUpdated])

  useEffect(() => {

    showWarehouseDefault()

    if (companyGlobal && (userCompany === 1 || userCompany === 3)) {
      setSellerSelected('ALL')
      getSellers(`${companyGlobal?.id === 0 ? '' : companyGlobal?.id}`)
      getLocationsGeoLevel1()
      setDefaultSelected([CALL_STATUS_LIST.CONFIRMED])
      getOrdersCallAll({
        companyId: companyGlobal?.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('yyyy-MM-DD'),
        endDate: selectedDates[1].format('yyyy-MM-DD'),
        page: 1,
        userId: 'ALL',
        dispatchStatus: dispatchSelected,
        warehouseId: authUser.user.role.name === 'STORE' ? `${authUser.user.warehouse?.id}` : warSelected,
      })
    } else if (userCompany === 2) {
      getSellers(`${authUser.user.company.id}`)
      getLocationsGeoLevel1()
      setDefaultSelected([CALL_STATUS_LIST.CONFIRMED])
      getOrdersCallAll({
        companyId: authUser.user.company.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('yyyy-MM-DD'),
        endDate: selectedDates[1].format('yyyy-MM-DD'),
        page: 1,
        userId: sellerSelected,
        dispatchStatus: dispatchSelected,
        warehouseId: warSelected,
      })
    }

  }, [companyGlobal])

  const handleChangeWarehouse = (warId: string) => {

    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      dispatchStatus: dispatchSelected,
      warehouseId: warId,
      page: 1
    })
    
    setWarSelected(warId)
  }

  const handleChange = (callStatus: string[]) => {
    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: callStatus,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      page: 1,
      userId: sellerSelected,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
    })
    setDefaultSelected(callStatus)
  }

  const handleStatusChange = (status: string[]) => {
    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: status,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      page: 1,
      userId: sellerSelected,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
    })
    setStatusSelected(status)
  }

  const handleSellerChange = (sellerId: string) => {

    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      page: 1,
      userId: sellerId,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
    })

    setSellerSelected(sellerId)
      
  }

  const handleStatusDispatchChange = (dispatchStatus: string) => {

    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      page: 1,
      userId: sellerSelected,
      dispatchStatus: dispatchStatus,
      warehouseId: warSelected,
    })
    
    setDispatchSelected(dispatchStatus)
  }

  const handlePagination = (page: number) => {

    getOrdersCallAll({
      companyId: companyGlobal?.id,
      callStatusList: defaultSelected,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      page: page,
      userId: sellerSelected,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
    })
  }

  const onSearch = (value: string) => {

    if (value.length > 0) {

      getOrdersCallAll({
        companyId: companyGlobal?.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('yyyy-MM-DD'),
        endDate: selectedDates[1].format('yyyy-MM-DD'),
        search: value,
        userId: sellerSelected,
        dispatchStatus: dispatchSelected,
        warehouseId: warSelected,
      })

    } else {
      getOrdersCallAll({
        companyId: companyGlobal?.id,
        callStatusList: defaultSelected,
        statusList: statusSelected,
        departmentName: department,
        filterDate: filterDate,
        startDate: selectedDates[0].format('yyyy-MM-DD'),
        endDate: selectedDates[1].format('yyyy-MM-DD'),
        page: 1,
        search: value,
        userId: sellerSelected,
        dispatchStatus: dispatchSelected,
        warehouseId: warSelected,
      })
    }
  }

  const handleCalendarChange = (dates: any, _: any) => {

    getOrdersCallAll({
      callStatusList: defaultSelected,
      companyId: companyGlobal?.id,
      statusList: statusSelected,
      departmentName: department,
      filterDate: filterDate,
      startDate: dates[0].format('yyyy-MM-DD'),
      endDate: dates[1].format('yyyy-MM-DD'),
      page: 1,
      userId: sellerSelected,
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
    })
    setSelectedDates(dates)
  }

  const handleChangeDepartment = (name: string) => {

    getOrdersCallAll({
      callStatusList: defaultSelected,
      statusList: statusSelected,
      companyId: companyGlobal?.id,
      departmentName: name,
      filterDate: filterDate,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      page: 1,
      userId: sellerSelected,
    })
    setDepartment(name)
  }

  const handleChangeFilterDate = (filter: string) => {

    getOrdersCallAll({
      callStatusList: defaultSelected,
      statusList: statusSelected,
      companyId: companyGlobal?.id,
      departmentName: department,
      filterDate: filter,
      startDate: selectedDates[0].format('yyyy-MM-DD'),
      endDate: selectedDates[1].format('yyyy-MM-DD'),
      dispatchStatus: dispatchSelected,
      warehouseId: warSelected,
      page: 1,
      userId: sellerSelected,
    })

    setFilterDate(filter)
  }

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex', justifyContent: 'space-between', gap: '.5rem', alignItems: 'center', flexWrap: 'wrap'
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center', flexWrap: 'wrap' }}>
            {authUser.user.role?.permissions?.order_company_change ? (
              <CompanyChangeContainer isAll={true} />
            ) : null}

            <Search placeholder="" onSearch={onSearch} enterButton style={{ width: '200px' }} />
            <div>|</div>

            <h5>Estado llamada</h5>
            
            <Select
              placeholder="Estados llamadas"
              style={{ minWidth: '250px' }}
              onChange={handleChange}
              value={defaultSelected}
              mode='multiple'
              allowClear
              maxTagCount='responsive'
            >
              
              {ORDER_STATUS_LIST_CONFIRMED.map(status => (
                <Option key={status.value} value={status.value}>{status.label}</Option>
              ))}
              
              {ORDER_STATUS_LIST_NOT_AVAILABLE.map(status => (
                <Option key={status.value} value={status.value}>{status.label}</Option>
              ))}
              
            </Select>

            <h5>Fechas</h5>

            <RangePicker format={dateFormat} 
              defaultValue={[moment(subDays(new Date(), 30), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
              onCalendarChange={handleCalendarChange} clearIcon={false}/>

            { !hiddenWar ? <>
              <h5>Almacén</h5>
              <Select placeholder='Almacén' value={warSelected} onChange={handleChangeWarehouse} 
                loading={loadingWarehouses} style={{ display: 'flex', width: '200px' }}
                showSearch
                optionFilterProp='label'
                filterOption={(input, option) =>
                  (option?.label as string).toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option key={'ALL'} value={'ALL'} label={'TODOS'}>TODOS</Option>
                {warehouses.map(c => (
                  <Option key={c.id} value={`${c.id}`} label={c.name}>{c.name}</Option>
                ))}
              </Select>

            </> : null }
      
          </div>

        </div>

        <div
          style={{
            display: 'flex', justifyContent: 'start', gap: '.5rem', alignItems: 'center', marginTop: '10px',
            flexWrap: 'wrap'
          }}
        >
          
          <h5>Estado entrega</h5>

          <Select
            placeholder="Estados de entrega"
            style={{ minWidth: '300px' }}
            onChange={handleStatusChange}
            value={statusSelected}
            mode='multiple'
            allowClear
            maxTagCount='responsive'
          >
            
            {Object.keys(ORDER_STATUS_LABEL).map(key => (
              <Option
                key={key}
                value={key}
              >
                <Badge style={{ marginRight: '.5rem' }} color={ORDER_STATUS_LABEL[key].color} text="" />
                {ORDER_STATUS_LABEL[key].label}
              </Option>
            ))}
          </Select>

          <h5>Estado despacho</h5>

          <Select
            placeholder="Estado de despacho"
            style={{ minWidth: '150px' }}
            onChange={handleStatusDispatchChange}
            value={dispatchSelected}
          >
            <Option key='ALL' value='ALL'>
              TODOS
            </Option>
            {Object.keys(ORDER_DISPATCH_STATUS_LIST).map(key => (
              <Option
                key={key}
                value={key}
              >
                {ORDER_DISPATCH_STATUS_LABEL[key].label}
              </Option>
            ))}
          </Select>

          <h5 style={{ alignContent: 'center' }}>Vendedor</h5>
          <Select
            placeholder='Vendedor'
            style={{ minWidth: '100px' }}
            onChange={handleSellerChange}
            value={sellerSelected}
            loading={loading}
          >
            <Option key='ALL' value='ALL'>
              <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                TODOS
              </Badge>
            </Option>
            {sellers.map(key => (
              <Option
                key={key.id}
                value={key.id}
              >
                {key.fullname} ({key.email})
              </Option>
            ))}
          </Select>

          <h5>Departamento</h5>

          <Select
            onChange={handleChangeDepartment}
            showSearch
            optionFilterProp="key"
            loading={loadingGeo1}
            value={department}
            style={{ minWidth: '120px' }}
            filterOption={(input, option) =>
              (option?.key as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key='ALL' value='ALL'>
              <Badge style={{ marginRight: '.5rem' }} color={'#000000'} text=''>
                TODOS
              </Badge>
            </Option>
            {locationsGeolevel1?.map(key => (
              <Option
                key={key.name}
                value={key.name}
              >
                {key.name}
              </Option>
            ))}
          </Select>

          <h5>Filtrar por</h5>
          <Select
            onChange={handleChangeFilterDate}
            optionFilterProp='key'
            value={filterDate}
            style={{ minWidth: '100px' }}
          >
            <Option key='create' value='create'>
              Fecha Creación
            </Option>
            <Option key='dispatch' value='dispatch'>
              Fecha Despacho
            </Option>
            <Option key='delivery' value='delivery'>
              Fecha Entrega
            </Option>
          </Select>

          <div style={{ display: 'flex', gap: '.5rem' }}>

            {authUser.user.role?.permissions?.order_create ? (
              <Tooltip placement="bottom" title="Agregar orden">
                <Button type="primary" onClick={handleOrderCreate}>
                  Nuevo Pedido<PlusCircleOutlined />
                </Button>
              </Tooltip>
            ) : null}

          </div>
          
          {resumen ? <OrderResumen resumen={resumen} /> : null}

        </div>

      </PageHeader>

      
      <OrdersTableLevel
        record={response?.result}
        loading={loadingOrder}
        handlePagination={handlePagination}
        count={count}
        currentPage={page}
      />
      
    </>
  )
}

export default OrdersListContainer
