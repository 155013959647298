/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './payment.actions'
import { IPaymentCreateRequest } from '../../services/payment-create/payment-create.interface'
import { IPaymentResponse } from '../../services/payment-list/payment-list.interface'

export interface IPaymentState {
  paymentSelected: IPaymentResponse | null
  paymentUpdated: IPaymentCreateRequest | null
}

const initialState: IPaymentState = {
  paymentUpdated: null,
  paymentSelected: null,
}

export const paymentReducer = (
  state: IPaymentState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_PAYMENT_SELECTED:
    return {
      ...state,
      paymentSelected: action.payload,
    }
  case actionTypes.SET_PAYMENT_UPDATED:
    return {
      ...state,
      paymentUpdated: action.payload,
    }
  default:
    return state
  }
}
