/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from '..'

const LOCALSTORAGE_KEY = 'wkPlatform'

export const saveToLocalStorage = (state: IRootState) => {
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify({
    // user: state.user,
  }))
}

export const loadFromLocalStorage = () => {
  try {
    const serialisedState = localStorage.getItem(LOCALSTORAGE_KEY)

    if (serialisedState === null) {
      return undefined
    }

    return JSON.parse(serialisedState)
  } catch (error) {
    return undefined
  }
}
