import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { orderActions } from '../../state/order/order.actions'
import { IOrderState } from '../../state/order/order.reducer'
import { IOrderDeliveryCreateResponse } from '../order-delivery-create/order-delivery-create.interface'
import { IOrderDeliveryItemResponse } from '../order-motorized/order-motorized.interface'
import { IOrderUpdateResponse } from '../order-update/order-update.interface'
import { IOrderItemResponse } from './order.interface'
import { IUpdateEvidenceResponse } from '../evidence-update/evidence-update.interface'

export const useOrderStore = () => {
  const dispatch = useDispatch()
  const {
    orderSelected, orderUpdated, orderDeliveryChecked, orderDeliveryUpdated,
    orderDeliveries, orderEvidence
  } = useSelector((state: IRootState) => state.order as IOrderState)

  const setOrderSelected = (order: IOrderItemResponse | null) => {
    dispatch(orderActions.setOrderSelected(order))
  }

  const setOrderUpdated = (order: IOrderUpdateResponse) => {
    dispatch(orderActions.setOrderUpdated(order))
  }

  const setOrderDeliveryChecked = (orders: IOrderDeliveryItemResponse[]) => {
    dispatch(orderActions.setOrderDeliveryChecked(orders))
  }

  const setOrderDeliveryUpdated = (orders: IOrderDeliveryCreateResponse) => {
    dispatch(orderActions.setOrderDeliveryUpdated(orders))
  }

  const setOrderDeliveries = (order: IOrderDeliveryItemResponse) => {
    dispatch(orderActions.setOrderDeliveries(order))
  }

  const setOrderEvidence = (orderEvidence: IUpdateEvidenceResponse) => {
    dispatch(orderActions.setOrderEvidence(orderEvidence))
  }

  return {
    setOrderSelected,
    setOrderUpdated,
    setOrderDeliveryChecked,
    setOrderDeliveryUpdated,
    setOrderDeliveries,
    setOrderEvidence,
    orderSelected,
    orderEvidence,
    orderUpdated,
    orderDeliveryChecked,
    orderDeliveryUpdated,
    orderDeliveries,
  }
}
