import React, { FC, useState } from 'react'
import moment, { Moment } from 'moment'
import { Button, Card, DatePicker, DatePickerProps, Divider, Dropdown, Space } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import { IOrderFilter } from '../../../../../services/order-list/order.interface'

moment.locale('es-PE')

interface IOrderFilterFormProps {
  onChange: (filter: IOrderFilter) => void;
}

const OrderFilterForm: FC<IOrderFilterFormProps> = ({ onChange }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = useState<Moment>()
  const [dateTo, setDateTo] = useState<Moment>()

  const options = [
    { value: moment().subtract(0, 'days').startOf('day').toJSON(), label: 'Hoy' },
    { value: moment().subtract(1, 'days').startOf('day').toJSON(), label: 'Ayer' },
    { value: moment().subtract(7, 'days').startOf('day').toJSON(), label: 'Últimos 7 días' },
    { value: moment().subtract(14, 'days').startOf('day').toJSON(), label: 'Últimos 14 días' },
    { value: moment().subtract(30, 'days').startOf('day').toJSON(), label: 'Últimos 30 días' },
    { value: moment().startOf('week').startOf('day').toJSON(), label: 'Esta semana' },
    { value: moment().startOf('month').startOf('day').toJSON(), label: 'Este mes' },
  ]

  const handleDateFromChange: DatePickerProps['onChange'] = (date) => {
    if (date) {
      setDateFrom(date.endOf('day'))
    }
  }

  const handleDateToChange: DatePickerProps['onChange'] = (date) => {
    if (date) {
      setDateTo(date.startOf('day'))
    }
  }

  const handleSelectChange = (date: string) => {
    onChange({
      createdAtFrom: null,
      createdAtTo: date,
    })
    setOpen(false)
  }

  const handleAccept = () => {
    onChange({
      createdAtFrom: dateFrom?.toISOString(),
      createdAtTo: dateTo?.toISOString(),
    })
    setOpen(false)
  }

  const menu = (
    <Card
      title="Filtro por fechas"
      extra={<a onClick={() => setOpen(false)}>Cerrar</a>}
      style={{ width: 320, boxShadow: '2px 2px 16px rgba(0,0,0,.15)' }}
      bodyStyle={{ padding: '10px' }}
    >
      <div>
        {options.map((o, index) => (
          <div
            key={index}
            style={{ padding: '8px 16px', cursor: 'pointer' }}
            onClick={() => handleSelectChange(o.value)}
          >{o.label}</div>
        ))}
        <Divider style={{ margin: '12px 0' }} />
        <div style={{ padding: '0 12px' }}>
          <p>Filtro personalizado:</p>
          <Space style={{ marginBottom: '.5rem' }}>
            <DatePicker
              placeholder="Desde"
              onChange={handleDateToChange}
              format={'DD/MM/YYYY'}
            />
            <DatePicker
              placeholder="Hasta"
              onChange={handleDateFromChange}
              format={'DD/MM/YYYY'}
            />
          </Space>
          <Button type="primary" onClick={handleAccept}>
            Aceptar
          </Button>
        </div>
      </div>
    </Card>
  )

  return <>

    <Dropdown
      overlay={menu}
      placement='topRight'
      trigger={['click']}
      open={open}
    >
      <Button onClick={() => setOpen(true)}>
        <Space>
          Filtrar por fecha
          <FilterOutlined />
        </Space>
      </Button>
    </Dropdown>
  </>
}

export default OrderFilterForm
