import React, { FC } from 'react'
import { IOrderResumen } from '../../../../../services/order-list/order.interface'
import './OrderResumen.css'

interface IOrderResumenProps {
  resumen: IOrderResumen
}

const OrderResumen: FC<IOrderResumenProps> = ({ resumen }) => {
  return (
    <>
      <div className='OrderResumen'>
        <div style={{ textAlign: 'center', display: 'flex', fontSize: '12px' }}>
          <span className='OrderResumen__label'>Pedidos:</span>
          <b>{resumen?.totalOrder || 0}</b>
        </div>
        <div style={{ textAlign: 'center', display: 'flex', fontSize: '12px' }}>
          <span className='OrderResumen__label'>Entregados:</span>
          <b>{resumen?.totalOrderDelivered || 0}</b>
        </div>
        <div style={{ textAlign: 'center', display: 'flex', fontSize: '12px' }}>
          <span className='OrderResumen__label'>Total:</span>
          <b>{resumen?.currency?.symbol} {resumen?.amount?.toFixed(2) || 0}</b>
        </div>
        <div style={{ textAlign: 'center', display: 'flex', fontSize: '12px' }}>
          <span className='OrderResumen__label'>Recaudo:</span>
          <b>{resumen?.currency?.symbol} {resumen?.totalPayment?.toFixed(2) || 0}</b>
        </div>

      </div>
    </>
  )
}

export default OrderResumen
