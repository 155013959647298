import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IGuideFilter, IGuideResponse } from './dispatch-guide-availables.interface'

export const useGuideAvailablesList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IGuideResponse[]>()

  const getGuides = (filter?: IGuideFilter) => {
    execute({
      method: 'GET',
      url: '/dispatch-guide/availables',
      params: {
        dispatchStatus: filter?.dispatchStatus || undefined,
        companyId: filter?.companyId || authUser.user.company.id,
        warehouseId: authUser.user.warehouse ? authUser.user.warehouse.id : ''
      },
    })
  }

  return {
    getGuides,
    loading,
    guides: response ? response : []
  }
}

export const useGuideAvailablesReturnList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IGuideResponse[]>()

  const guidesReturn = (province?: string, dpto?: string) => {
    execute({
      method: 'GET',
      url: '/dispatch-guide/available-return',
      params: {
        companyId: authUser.user.company.id,
        prov: province,
        dpto: dpto,
      },
    })
  }

  return {
    guidesReturn,
    loadingAvailables: loading,
    guidesAvailables: response ? response : []
  }
}
