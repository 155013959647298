import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Select, Spin, Typography } from 'antd'
import moment from 'moment'
import { downloadExcel } from 'react-export-table-to-excel'
import { useAuthStore } from '../../../../services/auth/auth.service'
import { useCompanyDrop, useStockReport } from '../../../../services/order-report-created/order-report-created.service'

const { Title } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const StockReport = () => {
  const { authUser } = useAuthStore()
  const dateFormat = 'YYYY-MM-DD'
  const { getStockReport, responseSkReports, loadingStockReports } = useStockReport()
  const [ecomSelected, setEcomSelected] = useState<string>('')
  const { getCompaniesDrop, loadingCompanies, companies } = useCompanyDrop()
  const [hiddenEcom, setHiddenEcom] = useState(false)
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    moment(new Date(), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])
  
  const headerOrder = [
    'PRODUCTO', 'VARIANTE', 'PRECIO DROP', 'TOTAL', 'CANTIDAD', 'NRO. PEDIDO', 'ALMACÉN', 
    'FECHA ENTREGA', 'ESTADO ENTREGA', 'DROPSHALOM', 'TIPO', 'CORREO DROPSHIPPER', 'DOCUMENTO DROPSHIPPER', 
    'NOMBRE DROPSHIPPER'
  ]

  function handleDownloadExcelOrders() {

    getStockReport(
      selectedDatesOrder[0].format('yyyy-MM-DD'),
      selectedDatesOrder[1].format('yyyy-MM-DD'),
      parseInt(ecomSelected),
      authUser.user.company.countryCode
    )

  }

  useEffect(() => {
    if (responseSkReports && responseSkReports.length > 0) {
      downloadExcel({
        fileName: 
          `stock-report-${selectedDatesOrder[0].format('yyyy-MM-DD')}-to-${selectedDatesOrder[1].format('yyyy-MM-DD')}`,
        sheet: 'react-export-table-to-excel',
        tablePayload: {
          header: headerOrder,
          // accept two different data structures
          body: responseSkReports.map(o => {
            return { 
              product: o.product,
              variant: o.variant,
              dropPrice: o.dropPrice,
              total: o.total,
              quantity: o.quantity,
              orderNumber: o.orderNumber,
              warehouse: o.warehouse,
              deliveryDate: o.deliveryDate,
              deliveryStatus: o.deliveryStatus,
              dropShalom: o.dropShalom,
              type: o.type,
              emailDropShipper: o.emailDropShipper,
              documentDropShipper: o.documentDropShipper,
              businessDropShipper: o.businessDropShipper,
            }
          })
        }
      })
    }
  }, [responseSkReports])

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    setSelectedDatesOrder(dates)
  }

  /*useEffect(() => {
    if (selectedDatesOrder.length === 2 && selectedDatesOrder[0] && selectedDatesOrder[1] 
      && ecomSelected.length > 0) {

      getStockReport(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        parseInt(ecomSelected),
        authUser.user.company.countryCode
      )

    }
  }, [selectedDatesOrder])*/

  const handleChangeEcom = (ecomId: string) => {

    /*if (ecomId.length > 0) {
      getStockReport(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        parseInt(ecomId),
        authUser.user.company.countryCode
      )
    }*/
    
    setEcomSelected(ecomId)
  }

  useEffect(() => {

    if (authUser.user.role.name === 'ADMIN') {
      setHiddenEcom(false)
      getCompaniesDrop()
    } else if (authUser.user.role.name === 'ADMIN_STORE') {
      setHiddenEcom(true)
      setEcomSelected(`${authUser.user.company.id}`)
    }
    
  }, [])

  useEffect(() => {
    if (companies && companies.length > 0) {

      if (authUser.user.company.countryCode === 'PER') {
        setEcomSelected(`${companies[0].id}`)
      } else if (authUser.user.company.countryCode === 'BOL') {
        setEcomSelected(`${authUser.user.company.id}`)
      }

      
    }
  }, [companies])
  
  return <>
    <Spin spinning={loadingStockReports}>

      <Divider />

      <Title level={5} style={{ marginBottom: '0', alignContent: 'center', padding: '.0rem 1.5rem' }}>
        Reporte de Stock
      </Title>

      <div
        style={{
          padding: '.85rem 1.5rem', display: 'flex',
          gap: '1rem', alignItems: 'center',
        }}
      >

        <Title level={5} style={{ marginBottom: '0' }}>
          Filtrar fecha entrega
        </Title>

        <RangePicker format={dateFormat} 
          defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
          onCalendarChange={handleCalendarChangeOrder} clearIcon={false}/>

        { !hiddenEcom ? <>
        
          <Title level={5} style={{ marginBottom: '0' }}>
            Ecommerce
          </Title>

          <Select placeholder='Ecommerce' value={ecomSelected} onChange={handleChangeEcom} 
            loading={loadingCompanies} style={{ display: 'flex', width: '200px' }}
            showSearch
            optionFilterProp='label'
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
          >

            { authUser.user.company.countryCode === 'PER' ? <>
              <Option key={-1} value={-1} label={'TODOS'}>TODOS</Option>
              {companies.map(c => (
                <Option key={c.id} value={`${c.id}`} label={c.name}>{c.name}</Option>
              ))}
            </> : <>{ authUser.user.company.countryCode === 'BOL' ? <>
              
              <Option key={authUser.user.company.id}
                value={`${authUser.user.company.id}`}
                label={authUser.user.company.name}>
                {authUser.user.company.name}
              </Option>
              
            </> : null } </>}
            
          </Select>

        </> : null }

        <Button type='primary' onClick={handleDownloadExcelOrders} 
          loading={loadingStockReports}>
            Descargar reporte
        </Button>

      </div>

    </Spin>

  </>
}

export default StockReport
