import { ICompanyCreateResponse } from '../../services/company-create/company-create.interface'
import { ICompanyItemResponse } from '../../services/company-list/company-list.interface'

export const actionTypes = {
  SET_COMPANY_SELECTED: 'SET_COMPANY_SELECTED',
  SET_COMPANY_UPDATED: 'SET_COMPANY_UPDATED',
  SET_COMPANY_GLOBAL: 'SET_COMPANY_GLOBAL',
}

const setCompanySelected = (data: ICompanyItemResponse | null) => ({
  type: actionTypes.SET_COMPANY_SELECTED,
  payload: data,
})

const setCompanyUpdated = (data: ICompanyCreateResponse) => ({
  type: actionTypes.SET_COMPANY_UPDATED,
  payload: data,
})

const setCompanyGlobal = (company: ICompanyItemResponse | null) => ({
  type: actionTypes.SET_COMPANY_GLOBAL,
  payload: company,
})

export const companyActions = {
  setCompanySelected,
  setCompanyUpdated,
  setCompanyGlobal,
}
