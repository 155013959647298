import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Button, Divider, Image, Menu, Space, Tag } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { IRoutePath, ROUTES } from '../../routes/params'
import { objectToArray } from '../../utils/object-to-array'
import { UserOutlined } from '@ant-design/icons'
import { useAuthStore, useLogout } from '../../services/auth/auth.service'

interface IMenu {
  key: string
  label: ReactNode
  children?: IMenu[]
}

interface ISidebarMenuProps {
  onSelect: () => void
}

const SidebarMenu: FC<ISidebarMenuProps> = ({ onSelect }) => {
  const { authUser, roleUser } = useAuthStore()
  const { logout, error, response } = useLogout()
  const navigate = useNavigate()
  const location = useLocation()
  const [menu, setMenu] = useState<IMenu[]>([])
  const locationSplit = location.pathname
    .split('/')
    .filter(e => e)
  const selectedKeyModule = locationSplit[0]
  const selectedKeySubmenu = locationSplit[1]

  const handleGoToNavigation = (path: string) => {
    navigate(path)
    onSelect()
  }

  const handleLogout = () => {
    logout()
  }

  useEffect(() => {
    if (response || error) {
      navigate(ROUTES.SIGN_IN.PATH)
    }
  }, [response, error])

  useEffect(() => {
    const menuTemp: IMenu[] = objectToArray<IRoutePath>(ROUTES)
      .filter(mod => mod.TITLE)
      .map((mod) => {
        const myMenu: IMenu = {
          key: mod.PATH,
          label: mod.CHILDREN ? mod.TITLE : 
            <div onClick={() => handleGoToNavigation(mod.PATH)}>
              {mod.TITLE}
              {mod.PATH === '/ticket' || mod.PATH === '/faq' ? 
                <Tag color='#2196F3' style={{ marginLeft: 10 }}>NUEVO</Tag>
                : <></> }
            </div>,
        }

        if (mod.CHILDREN) {
          const items: any[] = []

          objectToArray<IRoutePath>(mod.CHILDREN).forEach(sub => {
            const value = {
              key: sub.PATH,
              label: (
                <div onClick={() => handleGoToNavigation(`${mod.PATH}/${sub.PATH}`)}>
                  {sub.TITLE as string}
                </div>
              ),
            }

            if (authUser.user.company.countryCode === 'PER') {
              if (value.key === 'orders-shopify') {
                if (authUser.user.company.configShopify === true) {
                  items.push(value)
                }
              } else if (value.key === 'pre-orders') {
                // block
              } else if (value.key === 'manage') {
                if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER' 
                  || (authUser.user.isCatalogAdmin && authUser.user.isCatalogAdmin === true)) {
                  items.push(value)
                }
              } else {
                items.push(value)
              }
            } else if (authUser.user.company.countryCode === 'BOL') {
              if (value.key === 'pre-orders' || value.key === 'return-management' || value.key === 'coverages') {
                // block
              } else {
                items.push(value)
              }
            }

          })

          myMenu.children = items

        }

        return myMenu
      })

    // Filter by role and permission
    const menuTempFiltered = menuTemp
      .filter(e => roleUser.permissions?.routes[e.key])
      .map(e => ({
        ...e,
        children: e.children?.filter(f => roleUser.permissions?.routes[e.key].includes(f.key))
      }))

    setMenu(menuTempFiltered)
  }, [])

  return (
    <>
      <div>
        <Space style={{ fontSize: '12px', color: 'black', marginLeft: '25px', marginBottom: '6px' }}>
          { authUser.user.company.level && authUser.user.company.level.urlImage.length > 0 ? <>
            <Image src={authUser.user.company.level.urlImage} width={30} height={30}/>
          </> : <UserOutlined style={{ fontSize: '15px' }} />}
          {authUser?.user.fullname} - {authUser.user.company.level?.name}
        </Space>
        <div style={{
          fontSize: '11px',
          color: '#666',
          marginLeft: '25px',
          marginBottom: '18px'
        }}>
          {authUser.user.company.totalConfirmedOrders || 0} pedidos acumulados<br></br>
          {authUser?.user.role?.description}
        </div>
      </div>
      <Menu
        theme="light"
        mode="inline"
        defaultOpenKeys={[selectedKeySubmenu ? `/${selectedKeyModule}` : '']}
        selectedKeys={[
          selectedKeySubmenu ? selectedKeySubmenu : `/${selectedKeyModule}`,
        ]}
        items={menu}
      />
      {/*<div>
        <Button type='link' href='https://aliclik-catalog.web.app/'
          style={{ fontSize: '14px', color: 'black', marginLeft: '8px', marginTop: '8px' }}>
          Catálogo
        </Button>
      </div>
      */}
      <Divider />
      <div>
        <Button type="link" onClick={handleLogout}
          style={{ fontSize: '15px', color: 'black', marginLeft: '20px', marginTop: '15px' }}>
          Cerrar Sesión
        </Button>
      </div>
    </>
  )
}

export default SidebarMenu
