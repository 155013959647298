import React, { FC, useEffect, useState } from 'react'
import { Button, Form, FormInstance, Input, Modal, Radio, Space, Typography, message } from 'antd'
import { IProductSearchSchema } from '../../../../../../services/product/product.schema'
import { IOrderDetailsResponse } from '../../../../../../services/order-list/order.interface'
import { IOrderUpdateSchema } from '../../../../../../services/order-update/order-update.schema'
import { ProductListSearch } from '../../../components'
import { ProductAgencySearchForm, ProductSupplierAgencySearch } from '../..'
import { useCompanyStore } from '../../../../../../services/company-create/company-create.service'
import { useAuthStore } from '../../../../../../services/auth/auth.service'
import { CLOSING_HOURS_WAREHOUSE } from '../../../../../../services/order-create/order.params'

const { Title } = Typography

interface IPreOrderProductPartProps {
  form: FormInstance<IOrderUpdateSchema>
  orderDetails?: IOrderDetailsResponse[]
  warName?: string
}

const OrderProductPart: FC<IPreOrderProductPartProps> = ({
  orderDetails,
  form,
  warName
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [productExistList, setProductExistList] = useState<IProductSearchSchema[]>([])
  const [total, setTotal] = useState<number>(0)
  const [payAgency, setPayAgency] = useState<number>(0)
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()
  const managementType = Form.useWatch(['managementType'], form)
  const blockFields = Form.useWatch(['blockFields'], form)
  const warehouseToday = Form.useWatch(['warehouseCurrent'], form)

  const warehouseCurrent = (warName: string) => {
    form.setFieldValue('warehouseCurrent', warName)
  }

  const handleSubmit = (data: IProductSearchSchema) => {
    //const prod = productExistList.filter(t => t.action !== 'delete')[0]

    /*if (prod) {
      if (prod.warehouseId === data.warehouseId) {
        setProductExistList([...productExistList, { ...data, action: 'add' }])
        setIsModalVisible(false)
      } else {
        message.warning(`Debe seleccionar un producto del almacén ${prod.warehouseName}`)
      }
    } else {
      setProductExistList([...productExistList, { ...data, action: 'add' }])
      setIsModalVisible(false)
    }*/
    setProductExistList([...productExistList, { ...data, action: 'add' }])
    setIsModalVisible(false)
  }

  const handleSubmitSupplier = (data: IProductSearchSchema) => {
    const prod = productExistList.filter(t => t.action !== 'delete')[0]

    if (prod) {
      if (prod.warehouseId === data.warehouseId) {
        setProductExistList([...productExistList, { ...data, action: 'add' }])
        setIsModalVisible(false)
      } else {
        message.warning(`Debe seleccionar un producto del almacén ${prod.warehouseName}`)
      }
    } else {
      setProductExistList([...productExistList, { ...data, action: 'add' }])
      setIsModalVisible(false)
    }
  }

  const handleDeleteProduct = (item: IProductSearchSchema) => {
    const list: IProductSearchSchema[] = productExistList?.map(e => {
      return e.skuId === item.skuId ?
        { ...e, action: 'delete' } :
        e
    })

    setProductExistList(list)
  }

  useEffect(() => {

    if (productExistList.filter(t => t.action !== 'delete').length > 0) {
      form.setFieldValue('flagManagementType', true)
    } else {
      form.setFieldValue('warehouseCurrent', undefined)
      form.setFieldValue('flagManagementType', false)
    }

    const companyId = companyGlobal ? companyGlobal.id : authUser.user.company.id
    const prodMap = productExistList.filter(t => t.action !== 'delete')?.map(p => p.subtotal)

    if (managementType === 'PARTNER') {
      const prodDropMap = productExistList.filter(t => t.action !== 'delete')?.map(p => {
        return `${companyId}` === `${p.companyId}` ? 0 : p.dropPrice * p.quantity
      })

      const isEcomProdDrop = productExistList.filter(t => t.action !== 'delete' && `${companyId}` === `${t.companyId}`)

      const priceAgency = isEcomProdDrop && isEcomProdDrop.length > 0 ? 5 : 2

      setPayAgency((prodDropMap?.length > 0 ? prodDropMap.reduce((a, b) => a + b) : 0) + priceAgency)

    } else if (managementType === 'OWN') {

      setPayAgency(0)

    }

    setTotal(prodMap?.length ? prodMap.reduce((a, b) => a + b) : 0)
    form.setFieldValue('orderDetails', productExistList)
  }, [productExistList])

  useEffect(() => {
    form.setFieldValue('payAgency', payAgency)
  }, [payAgency])

  useEffect(() => {
    if (orderDetails) {
      const prodMap: IProductSearchSchema[] = orderDetails?.map(p => {
        const productName = p.sku.product?.name + ' / ' + p.sku.skuOptions
          .map(e => ({
            option: e.option?.name,
            variant: e.option?.variant.name
          }))
          .map(e => `${e.variant} ${e.option}`)
          .join(' / ')

        return {
          id: p.id,
          price: p.price,
          quantity: p.quantity,
          subtotal: p.subtotal,
          skuId: p.sku.id,
          productName,
          sku: p.sku,
          warehouseId: p.warehouseId,
          warehouseName: warName || '',
          warCompanyId: -1,
          companyId: p.companyId,
          dropPrice: p.dropPrice,
          action: 'update'
        }
      })

      setProductExistList(prodMap)
    }
  }, [orderDetails])

  const hourWarehouse = (warName?: string) => {
    return warName && warName.length > 0 ? 
      (CLOSING_HOURS_WAREHOUSE[warName] ? CLOSING_HOURS_WAREHOUSE[warName].agencyHour : '') : ''
  }

  return <>
    <Space style={{ marginBottom: '.5rem' }}>
      <Title level={4} style={{ marginBottom: 0 }}>Productos</Title>
      <Button type="link" onClick={() => setIsModalVisible(true)} hidden={blockFields}>+ Agregar</Button>
      { managementType === 'PARTNER' ? <>
        <Title level={5} style={{ marginBottom: 0, fontSize: '12px' }}>
          Almacén: {warehouseToday || warName}<br/>{hourWarehouse(warehouseToday || warName)}
        </Title>
      </> : <></>}
      <Modal
        title="Buscar Producto"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        { managementType === 'PARTNER' ? <>
          <ProductSupplierAgencySearch warhSelected={warehouseCurrent} onSubmit={handleSubmitSupplier} />
        </> : managementType === 'OWN' ? <>
          <ProductAgencySearchForm
            onSubmit={handleSubmit}
          />
        </> : <></>
        }
        
      </Modal>
    </Space>

    <ProductListSearch
      data={productExistList.filter(e => e.action !== 'delete')}
      total={total}
      payAgency={payAgency}
      managementType={managementType}
      disabled={blockFields}
      warName={warehouseToday || warName}
      onDelete={handleDeleteProduct}
    />

    <Form.Item name={['payAgency']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['orderDetails']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['warehouseCurrent']} hidden>
      <Input />
    </Form.Item>

    <Form.Item name={['flagManagementType']} label="Bloquear gestion" hidden>
      <Radio.Group
        options={[
          { label: 'No', value: false },
          { label: 'Si', value: true },
        ]}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>

  </>
}

export default OrderProductPart
