import React from 'react'
import { Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import useMeasureY from '../../../../../hooks/use-measurey'
import { formatDateUTC } from '../../../../../utils/date'
import { SummaryProductListTable } from './parts'
import { IReturnResponse } from '../../../../../services/return-list/return-list.interface'

interface IReturnListTableProps {
  returnGuides: IReturnResponse[]
  loading: boolean;
  handlePagination: (page: number) => void
  count: number
  currentPage: number
}

const ReturnListTable: React.FC<IReturnListTableProps> = (
  { returnGuides, loading, handlePagination, count, currentPage }
) => {
  const { windowSize } = useMeasureY()

  const columns: ColumnsType<IReturnResponse> = [
    {
      title: 'Motorizado',
      dataIndex: 'motorized',
      align: 'center', width: '20%',
      render: (_, guide) => <>
        {guide.motorizedSender ? <>
          {guide.motorizedSender.name}
        </> : null }
      </>
    },
    {
      title: 'Pedidos por devolver',
      dataIndex: 'guidesReturned', align: 'center', width: '40%',
      render: (_, guide) => <>
        { guide.ordersReturned && guide.ordersReturned.length > 0 ? <>
          {guide.ordersReturned.map(o => {
            return (<><Tag color='#03A9F4'>{o.orderNumber}</Tag></>)
          })}
        </> : '-'}
      </>
    },
    {
      title: 'Prov. / Dpto.',
      dataIndex: 'departmentOriginName', align: 'center', width: '20%',
      render: (_, guide) => <>
        {guide.provinceOriginName} / {guide.departmentOriginName}
      </>
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt', align: 'center', width: '20%',
      render: (_, guide) => <>{formatDateUTC(guide?.createdAt)}</>
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={returnGuides}
      columns={columns}
      loading={loading}
      pagination={{ pageSize: 10, current: currentPage, total: count, onChange(page, _) {
        handlePagination(page)
      }, }}
      scroll={{
        y: windowSize.height - 200,
      }}
      size="small"
      expandable={{
        expandedRowRender: (record) => <SummaryProductListTable record={record.summaryReturnProduct} />,
        rowExpandable: record => !!record.summaryReturnProduct?.length,
      }}
    />
  )
}

export default ReturnListTable
