import { Button, Typography } from 'antd'
import React, { useEffect } from 'react'
import { PageHeader } from '../../../../../components'
import { useDrawer } from '../../../../../context/DrawerContext'
import { CatalogTable } from '../../components'
import { useCatalogs } from '../../../../../services/catalog/catalog.service'
import { useCatalogStore } from '../../../../../services/catalog/catalog.store'
import { useAuthStore } from '../../../../../services/auth/auth.service'

const { Title } = Typography

const CatalogsContainer = () => {
  const { openDrawer } = useDrawer()
  const { authUser } = useAuthStore()
  const { catalogs, getCatalogs, loadingCatalogs } = useCatalogs()
  const { setCatalogSelected, catalogUpdated } = useCatalogStore()

  const handleOpenDrawer = () => {
    setCatalogSelected(null)
    openDrawer('CATALOG_CREATE', 'Crear catálogo')
  }

  useEffect(() => {
    getCatalogs()
  }, [catalogUpdated])

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', justifyContent: 'flex-end', alignItems: 'center' }}>

          <Title level={5}>Catálogos #{catalogs.length}</Title>
          
          { authUser.user.role.name === 'MASTER' || authUser.user.role.name === 'ADMIN' ? <>

            <Button type="primary" onClick={handleOpenDrawer}>
              Nuevo catálogo
            </Button>
        
          </> : null }
          
        </div>

      </PageHeader>

      <CatalogTable catalogs={catalogs} loading={loadingCatalogs} />
      
    </>
  )
}

export default CatalogsContainer
