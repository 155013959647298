import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import React, { FC } from 'react'
import { IProductSearchSchema } from '../../../../../services/product/product.schema'
import './ProductListSearch.css'
import { useUserAdmin, useUserMaster } from '../../../../../services/order-create/order.edit'

interface IProductListSearchProps {
  data: IProductSearchSchema[]
  total: number
  onDelete: (item: IProductSearchSchema) => void
  onEdit: (item: IProductSearchSchema) => void
  disabled: boolean
  status: string | any
}

const ProductListSearch: FC<IProductListSearchProps> = ({ 
  data, total, onDelete, disabled, onEdit, status }) => {

  const { isMaster } = useUserMaster()
  const { isAdmin } = useUserAdmin()

  if (!data?.length) {
    return <div className="ProductListSearch__empty">
      Producto vacío
    </div>
  }

  return <>
    <div className="ProductListSearch__grid ProductListSearch__head">
      <div>Cant.</div>
      <div>Producto</div>
      <div className="right">P.U.</div>
      <div className="right">Subtotal</div>
      <div></div>
      <div></div>
    </div>

    {data?.map((item, index) =>
      <div
        key={index}
        className="ProductListSearch__grid"
      >
        <div>{item.quantity}</div>
        <div>{item.productName}</div>
        <div className="right">
          {item.sku?.currency.symbol} {item.price}
        </div>
        <div className="right">
          {item.sku?.currency.symbol} {item.subtotal}
        </div>
        { isMaster ? <>
          <div className="center" hidden={disabled}>
            <DeleteOutlined onClick={() => onDelete(item)} />
          </div>
        </> : null }
        { isMaster ? <>
          <div className="center">
            <EditOutlined onClick={() => onEdit(item)} />
          </div>
        </> : isAdmin && status !== 'DELIVERED' ? <>
          <div className="center">
            <EditOutlined onClick={() => onEdit(item)} />
          </div>
        </> : null }
      </div>
    )}

    <div className="ProductListSearch__footer">
      <div className="right">Total:</div>
      <div className="right">
        <b>
          {total}
        </b>
      </div>
    </div>
  </>
}

export default ProductListSearch
