import { 
  ITicketSupport 
} from '../../services/ticket-support-list/ticket-support-list.interface'
  
export const actionTypes = {
  SET_TK_SELECTED: 'SET_TK_SELECTED',
  SET_TK_UPDATED: 'SET_TK_UPDATED',
}
  
const setTkSelected = (data: ITicketSupport | null) => ({
  type: actionTypes.SET_TK_SELECTED,
  payload: data,
})
  
const setTkUpdated = (data: ITicketSupport) => ({
  type: actionTypes.SET_TK_UPDATED,
  payload: data,
})
  
export const ticketActions = {
  setTkSelected,
  setTkUpdated,
}
