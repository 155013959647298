import { IOrderShopify } from '../../services/order-shopify/order-shopify.interface'

export const actionTypes = {
  SET_ORDER_SHOPIFY_SELECTED: 'SET_ORDER_SHOPIFY_SELECTED',
}

const setOrderShopifySelected = (order: IOrderShopify | null) => ({
  type: actionTypes.SET_ORDER_SHOPIFY_SELECTED,
  payload: order,
})

export const orderShopifyActions = {
  setOrderShopifySelected,
}
