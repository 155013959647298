import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import { CompanyChangeContainer } from '../../../../../containers'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useOrderDeliveryList } from '../../../../../services/order-delivery/order-delivery.service'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { OrderResumen, OrdersTable } from '../../components'
import { Select, Badge, Input } from 'antd'
import { ORDER_STATUS_LABEL } from '../../../../../services/order-create/order.params'

const { Option } = Select
const { Search } = Input

const OrdersListContainer = () => {
  const { orders, resumen, loading, getOrders, count, page } = useOrderDeliveryList()
  const { orderUpdated, orderEvidence } = useOrderStore()
  const { companyGlobal } = useCompanyStore()
  const { authUser } = useAuthStore()
  const [defaultSelected, setDefaultSelected] = useState<string[]>([])

  useEffect(() => {
    if (orderUpdated) {
      getOrders({
        companyId: companyGlobal?.id,
        statusList: defaultSelected,
        page: 1,
      })
    }
  }, [orderUpdated])

  useEffect(() => {
    if (orderEvidence) {
      getOrders({
        companyId: companyGlobal?.id,
        statusList: defaultSelected,
        page: 1,
      })
    }
  }, [orderEvidence])

  useEffect(() => {
    setDefaultSelected([])

    if (companyGlobal) {
      if (authUser.user.role.name === 'ADMIN' || authUser.user.role.name === 'MASTER') {
        getOrders({
          companyId: companyGlobal?.id,
          statusList: defaultSelected,
          page: 1,
        })
      }
    } else if (authUser.user.role.name === 'ADMIN_STORE' || authUser.user.role.name === 'SELLER') {
      getOrders({
        companyId: authUser.user.company.id,
        statusList: defaultSelected,
        page: 1,
      })
    }
    

    

  }, [companyGlobal])

  const handleChange = (status: string[]) => {
    getOrders({
      companyId: companyGlobal?.id,
      statusList: status,
      page: 1,
    })
    setDefaultSelected(status)
  }

  const handlePagination = (page: number) => {
    getOrders({
      companyId: companyGlobal?.id,
      statusList: defaultSelected,
      page: page
    })
  }

  const onSearch = (value: string) => {

    if (value.length > 0) {

      getOrders({
        companyId: companyGlobal?.id,
        statusList: defaultSelected,
        search: value,
        page: 1,
      })

    } else {
      getOrders({
        companyId: companyGlobal?.id,
        statusList: defaultSelected,
        page: 1,
        search: value
      })
    }
  }

  return (
    <>
      <PageHeader>
        <div
          style={{
            display: 'flex', justifyContent: 'space-between', gap: '.5rem', alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
            {authUser.user.role?.permissions?.order_company_change ? (
              <CompanyChangeContainer isAll={true} />
            ) : null}

            {/*<OrderSearch
              onSearch={handleSearch}
              provinceList={provinceList}
            />*/}
            <Search placeholder="" onSearch={onSearch} enterButton style={{ width: 304 }} />
            <div>|</div>
            {resumen ? <OrderResumen resumen={resumen} /> : null}

            <h5 style={{ alignContent: 'center' }}>Seleccione</h5>
            <Select
              placeholder='Estados entrega'
              style={{ minWidth: '250px' }}
              onChange={handleChange}
              value={defaultSelected}
              mode='multiple'
              allowClear
              maxTagCount='responsive'
            >
            
              {Object.keys(ORDER_STATUS_LABEL).map(key => (
                <Option
                  key={key}
                  value={key}
                >
                  <Badge style={{ marginRight: '.5rem' }} color={ORDER_STATUS_LABEL[key].color} text="" />
                  {ORDER_STATUS_LABEL[key].label}
                </Option>
              ))}
            </Select>
          </div>

          {/*<div style={{ display: 'flex', gap: '.5rem' }}>
            <OrderFilterForm onChange={handleSubmit} />
              </div>*/}

        </div>
      </PageHeader>

      <OrdersTable
        data={orders}
        loading={loading}
        handlePagination={handlePagination}
        count={count}
        currentPage={page}
      />
    </>
  )
}

export default OrdersListContainer
