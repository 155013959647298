/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './company.actions'
import { ICompanyItemResponse } from '../../services/company-list/company-list.interface'
import { ICompanyCreateResponse } from '../../services/company-create/company-create.interface'

export interface ICompanyState {
  companySelected: ICompanyItemResponse | null;
  companyUpdated: ICompanyCreateResponse | null;
  companyGlobal: ICompanyItemResponse | null;
}

const initialState: ICompanyState = {
  companySelected: null,
  companyUpdated: null,
  companyGlobal: null,
}

export const companyReducer = (
  state: ICompanyState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_COMPANY_SELECTED:
    return {
      ...state,
      companySelected: action.payload,
    }
  case actionTypes.SET_COMPANY_UPDATED:
    return {
      ...state,
      companyUpdated: action.payload,
    }
  case actionTypes.SET_COMPANY_GLOBAL:
    return {
      ...state,
      companyGlobal: action.payload,
    }
  default:
    return state
  }
}
