import { useAxios } from '../../hooks'
import { datadogLogs } from '../../hooks/use-datadog'
import { useAuthStore } from '../auth/auth.service'
import { useEffect } from 'react'
import { ILiquidationRecalculateRequest, ILiquidationRecalculateResponse } from './liquidation-recalculate.interface'

export const useLiquidationRecalculate = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ILiquidationRecalculateResponse>()

  const recalculateLiquidation = (userId: number, deliveryDate: string) => {
    
    const request: ILiquidationRecalculateRequest = {
      deliveryDate: deliveryDate
    }

    datadogLogs.logger.info('LIQUIDATION_RECALCULATE', { action: 'LIQUIDATION_RECALCULATE',
      liquidation: request,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      }
    })
    
    execute({
      method: 'POST',
      url: `/liquidation/recalculate/${userId}`,
      params: {
        deliveryDate: deliveryDate
      },
    })
    
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('LIQUIDATION_RECALCULATE:POST', { action: 'LIQUIDATION_RECALCULATE:POST',
        liquidationResponse: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])

  return {
    recalculateLiquidation,
    loading,
    liquidation: response
  }
}
