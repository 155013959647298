import React from 'react'
import { ConfigOrderContainer } from './containers'

const SettingsView = () => {
  return <>
    <ConfigOrderContainer />
  </>
}

export default SettingsView
