import { Button } from 'antd'
import React, { useEffect } from 'react'
import { PageHeader } from '../../../../../components'
import { CompanyChangeContainer } from '../../../../../containers'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { WarehousesListTable } from '../../components'
import { useWarehouseModList } from '../../../../../services/warehouse-list/warehouse-list.service'
import { useWarehouseStore } from '../../../../../services/warehouse-create/warehouse.store'

const WarehousesMaintContainer = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { openDrawer } = useDrawer()
  const { getWarehouses, warehouses, loading } = useWarehouseModList()
  const { setWarehouseSelected, warehouseUpdated } = useWarehouseStore()

  const handleOpenDrawer = () => {
    setWarehouseSelected(null)
    openDrawer('WAREHOUSE_EDIT', 'Agregar almacén')
  }

  useEffect(() => {
    if (warehouseUpdated) {
      getWarehouses('')
    }
  }, [warehouseUpdated])

  useEffect(() => {
    getWarehouses('')
  }, [companyGlobal])

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          {authUser.user.role?.permissions?.user_company_change && authUser.user.company.countryCode === 'PER' 
          && authUser.user.role.name !== 'STORE' ? (
              <CompanyChangeContainer isAll={false} />
            ) : null}

          { authUser.user.role.name !== 'STORE' ? <>
            <Button type="primary" onClick={handleOpenDrawer}>
              Agregar Almacén
            </Button>
          </> : null }
          
        </div>
      </PageHeader>
      <WarehousesListTable
        warehouses={warehouses}
        loading={loading}
      />
    </>
  )
}

export default WarehousesMaintContainer
