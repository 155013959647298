import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { ISellerResponse } from './user-seller-list.interface'

export const useSellerList = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ISellerResponse[]>()

  const getSellers = (companyId?: string) => {
    execute({
      method: 'GET',
      url: '/maintenance/user/seller',
      params: {
        companyId: companyId || authUser.user.company.id,
      },
    })
  }

  return {
    getSellers,
    loading,
    sellers: response ? response : []
  }
}
