import { IAuthUserResponse } from '../../services/auth/auth.interface'
import { IUserCreateResponse } from '../../services/user-create/user-create.interface'
import { IUserStatusResponse } from '../../services/user/user.interface'

export const actionTypes = {
  SET_USER_STATUS: 'SET_USER_STATUS',
  SET_USER_SELECTED: 'SET_USER_SELECTED',
  SET_USER_UPDATED: 'SET_USER_UPDATED',
}

const setUserStatus = (userStatus: IUserStatusResponse) => ({
  type: actionTypes.SET_USER_STATUS,
  payload: userStatus,
})

const setUserSelected = (data: IAuthUserResponse | null) => ({
  type: actionTypes.SET_USER_SELECTED,
  payload: data,
})

const setUserUpdated = (data: IUserCreateResponse) => ({
  type: actionTypes.SET_USER_UPDATED,
  payload: data,
})

export const userActions = {
  setUserStatus,
  setUserSelected,
  setUserUpdated,
}
