import React from 'react'
import { PreOrderListContainer } from './containers'

const MyPreOrdersView = () => {
  return <>
    <PreOrderListContainer />
  </>
}

export default MyPreOrdersView
