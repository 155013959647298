/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { Space, Spin } from 'antd'
import Title from 'antd/lib/typography/Title'
import { OrderRecycleForm } from '../../forms'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useOrderStore } from '../../../../../services/order-list/order.store'
import { useOrderRecycle } from '../../../../../services/order-level/order-level.service'
import { IOrderRecycleSchema } from '../../../../../services/order-level/order-recycle.schema'

const OrderRecycleContainer = () => {
  const { orderSelected: order, setOrderUpdated } = useOrderStore()
  const { updateOrderRecycle, orderUpdated, loadingUpdated } = useOrderRecycle()
  const { closeDrawer } = useDrawer()

  const handleSubmit = (data: IOrderRecycleSchema) => {
    updateOrderRecycle(data)
  }

  useEffect(() => {
    if (orderUpdated) {
      closeDrawer()
      setOrderUpdated(orderUpdated)
    }
  }, [orderUpdated])

  return <>
    { order ? (
      <>
        <Space align="baseline">
          <Title level={3}>Orden #{order?.orderNumber}</Title>
          <Title level={4}>{order?.shipping?.provinceName} / {order?.shipping?.departmentName}</Title>
        </Space>
      </>
    ) : null }

    <Spin spinning={loadingUpdated}>
      <OrderRecycleForm
        initialValues={order}
        onSubmit={handleSubmit}
      />
    </Spin>
  </>
}

export default OrderRecycleContainer
