import { useAxios } from '../../hooks'
import { ISummaryProductItem } from '../dispatch-guide-list/dispatch-guide-list.interface'
import {
  ISummaryProductResponse,
  ISummaryProductRequest,
  ISummaryProdyctDispatchRequest,
  IDispatchGuideIdsRequest,
  ISummaryProductEditResponse,
  ISummaryProductEditRequest,
  IAddOrderDispatchResponse,
  IAddOrderToDispatchRequest,
  ISummaryProductCreateRequest
} from './summary-products.interface'
import { ISummaryProductCreateSchema } from './summary-products.schema'
import { datadogLogs } from '../../hooks/use-datadog'
import { useAuthStore } from '../auth/auth.service'
import { useEffect } from 'react'

export const useSummaryProducts = () => {
  const { execute, response, loading } = useAxios<ISummaryProductResponse[]>()

  const getSummaryProducts = (orderIds: number[]) => {

    const request: ISummaryProductRequest = {
      orderIds: orderIds,
    }

    execute({
      method: 'POST',
      url: '/order/summary-products',
      data: request,
    })
  }

  return {
    getSummaryProducts,
    loading,
    summaryProducts: response ? response : []
  }

}

export const useSummaryProductsDispatch = () => {
  const { execute, response, loading } = useAxios<ISummaryProductResponse[]>()

  const getSummaryProductsDispatch = (dispatchGuideId: number) => {

    const request: ISummaryProdyctDispatchRequest = {
      dispatchGuideId: dispatchGuideId,
    }

    execute({
      method: 'POST',
      url: '/order/summary-products/delivered',
      data: request,
    })
  }

  return {
    getSummaryProductsDispatch,
    loading,
    summaryProductsDispatch: response ? response : []
  }

}

export const useSummaryProductsAccumulated = () => {
  const { execute, response, loading } = useAxios<ISummaryProductItem[]>()

  const getSummaryProductsAcc = (ids: number[]) => {

    const request: IDispatchGuideIdsRequest = {
      dispatchGuideIds: ids,
    }

    execute({
      method: 'POST',
      url: '/dispatch-guide/summary-products/accumulated',
      data: request,
    })
  }

  return {
    getSummaryProductsAcc,
    loading,
    summaryProductsAcc: response ? response : []
  }

}

export const useSummaryProductEdit = () => {
  const { execute, response, loading } = useAxios<ISummaryProductEditResponse[]>()
  const { authUser } = useAuthStore()

  const summaryProductsEdit = (schema: ISummaryProductCreateSchema) => {

    if (schema.isEditedProducts === false) {
      const request: ISummaryProductEditRequest = {
        products: schema.products.map(e => {
          return {
            id: e.id,
            skuId: e.skuId,
            quantity: e.quantity
          }
        }),
      }

      datadogLogs.logger.info('DISPATCH_PRODUCTS_EDIT', { action: 'DISPATCH_PRODUCTS_EDIT',
        data: request,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })

      execute({
        method: 'PATCH',
        url: '/dispatch-guide/summary-product/edit',
        data: request,
      })
    } else {

      const request: ISummaryProductCreateRequest = {
        products: schema.products.map(e => {
          return {
            warehouseId: e.warehouseId,
            companyId: e.companyId,
            codeSku: e.codeSku,
            skuName: e.skuName,
            skuId: e.skuId,
            quantity: e.quantity,
            ean: e.ean,
            warehouseName: e.warehouseName,
            companyName: e.companyName,
          }
        }),
      }

      datadogLogs.logger.info('DISPATCH_PRODUCTS_CREATE', { action: 'DISPATCH_PRODUCTS_CREATE',
        data: request,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })

      execute({
        method: 'POST',
        url: `/dispatch-guide/summary-product/${schema.dispatchGuideId}`,
        data: request,
      })

    }
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('DISPATCH_PRODUCTS:POST', { action: 'DISPATCH_PRODUCTS:POST',
        dataResponse: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])

  return {
    summaryProductsEdit,
    loading,
    summaryProductEdits: response ? response : []
  }

}

export const useAddOrderToDispatch = () => {
  const { execute, response, loading } = useAxios<IAddOrderDispatchResponse>()

  const addOrderToDispatch = (schema: ISummaryProductCreateSchema) => {

    const request: IAddOrderToDispatchRequest = {
      orderNumber: schema.orderNumber
    }

    execute({
      method: 'PATCH',
      url: `/dispatch-guide/orders-edit/${schema.dispatchGuideId}`,
      data: request,
    })
  }

  return {
    addOrderToDispatch,
    loading,
    orderToDispatch: response ? response : null
  }

}
