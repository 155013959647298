import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useDrawer } from '../../../../../context/DrawerContext'
import { ICoverageCreateSchema } from '../../../../../services/coverage-create/coverage-create.schema'
import { useCoverageCreate } from '../../../../../services/coverage-create/coverage-create.service'
import { useCoverageStore } from '../../../../../services/coverage-create/coverage-create.store'
import { CoverageEditForm } from '../../forms'

const CoverageEditContainer = () => {
  const { setCoverageUpdated } = useCoverageStore()
  const { saveCoverage, coverageUpdated, loading } = useCoverageCreate()
  const { closeDrawer } = useDrawer()

  const handleSubmit = (data: ICoverageCreateSchema) => {
    saveCoverage(data)
  }

  useEffect(() => {
    if (coverageUpdated) {
      closeDrawer()
      setCoverageUpdated(coverageUpdated)
    }
  }, [coverageUpdated])

  return (
    <>
      <Spin spinning={loading}>
        <CoverageEditForm
          onSubmit={handleSubmit}
        />
      </Spin>
    </>
  )
}

export default CoverageEditContainer
