import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAxios } from '../../hooks'
import { IRootState } from '../../state'
import { userActions } from '../../state/user/user.actions'
import { IUserState } from '../../state/user/user.reducer'
import { IAuthUserResponse, ISessionResponse } from '../auth/auth.interface'
import { IUserCreateResponse } from '../user-create/user-create.interface'
import { IUserEditPasswordRequest, IUserStatusRequest, IUserStatusResponse } from './user.interface'

export const useUserStatusChange = () => {
  const { execute, response: resultChange, loading } = useAxios<IUserStatusResponse>()

  const userStatusChange = (data: IUserStatusRequest) => {
    execute({
      method: 'PATCH',
      url: '/pre-order/update-user',
      data,
    })
  }

  return {
    userStatusChange,
    resultChange,
    loading,
  }
}

export const useUserStatus = () => {
  const { execute, response: userStatus } = useAxios<IUserStatusResponse>()

  useEffect(() => {
    execute({
      method: 'GET',
      url: '/pre-order/user-status',
    })
  }, [])

  return {
    userStatus,
  }
}

export const useUserStore = () => {
  const dispatch = useDispatch()
  const { userStatus, userSelected, userUpdated } = useSelector((state: IRootState) => state.user as IUserState)

  const setUserStatus = (userStatus: IUserStatusResponse) => {
    dispatch(userActions.setUserStatus(userStatus))
  }

  const setUserSelected = (data: IAuthUserResponse | null) => {
    dispatch(userActions.setUserSelected(data))
  }

  const setUserUpdated = (data: IUserCreateResponse | IAuthUserResponse) => {
    dispatch(userActions.setUserUpdated(data))
  }

  return {
    setUserStatus,
    setUserSelected,
    setUserUpdated,
    userStatus,
    userSelected,
    userUpdated,
  }
}


export const useUserLogout = () => {
  const { execute, response, loading } = useAxios<ISessionResponse>()

  const userLogout = (id: number) => {
    execute({
      method: 'POST',
      url: `/auth/logout/${id}`,
    })
  }

  return {
    userLogout,
    response,
    loading
  }
}

export const useUserLogoutUser = () => {
  const { execute, response, loading } = useAxios<ISessionResponse>()

  const userLogoutUser = (id: number, platform?: string) => {
    execute({
      method: 'POST',
      url: `/auth/logout-user/${id}`,
      data: {
        xPlatform: platform
      }
    })
  }

  return {
    userLogoutUser,
    response,
    loading
  }
}

export const useUserEditPassword = () => {
  const { execute, response, loading } = useAxios()

  const userEditPassword = (id: number, data: IUserEditPasswordRequest) => {
    execute({
      method: 'PATCH',
      url: `/maintenance/user/change-password/${id}`,
      data,
    })
  }

  return {
    userEditPassword,
    response,
    loading
  }
}
