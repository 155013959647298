import React, { FC } from 'react'
import { Table, TableColumnsType } from 'antd'
import {
  SummaryProduct
} from '../../../../../../services/dispatch-guide-list/dispatch-guide-list.interface'

interface ISummaryProductTableLevelProps {
  record: SummaryProduct[]
}

const SummaryProductListTable: FC<ISummaryProductTableLevelProps> = ({ record }) => {

  const columnsLevel2: TableColumnsType<SummaryProduct> = [
    {
      title: '', dataIndex: '',
    },
    {
      title: 'EAN', dataIndex: 'ean', align: 'center',
    },
    {
      title: 'Producto', dataIndex: 'skuName', align: 'center',
      render: (_, product) => <>
        {product.skuName}
      </>,
    },
    {
      title: 'Propietario', dataIndex: 'companyName', align: 'center',
      render: (_, product) => <>
        {product.companyName}
      </>,
    },
    {
      title: 'Bodega origen', dataIndex: 'warehouseName', align: 'center',
      render: (_, product) => <>
        {product.warehouseName}
      </>,
    },
    {
      title: 'Cantidad', dataIndex: 'quantity', align: 'center',
      render: (_, product) => <>
        {product.quantity}
      </>,
    }
  ]

  return <>
    <Table
      rowKey="codeSku"
      style={{ width: '99%' }}
      columns={columnsLevel2}
      dataSource={record}
      pagination={false}
    />
  </>
}

export default SummaryProductListTable
