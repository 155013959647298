import React from 'react'
import { StoresMaintContainer } from './containers'

const StoresMaintView = () => {
  return (
    <>
      <StoresMaintContainer/>
    </>
  )
}

export default StoresMaintView
