import React, { useEffect } from 'react'
import { Table, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ILiquidationResponse } from '../../../../../services/liquidation-list/liquidation-list.interface'
import { LIQUIDATION_STATUS_LABEL } from '../../../../../services/liquidation-list/liquidation.params'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useLiquidationStore } from '../../../../../services/liquidation-edit/liquidation-edit.store'
import { CalculatorOutlined, FileAddOutlined } from '@ant-design/icons'
import {
  useLiquidationRecalculate
} from '../../../../../services/liquidation-recalculate/liquidation-recalculate.service'

interface IDepositListTableProps {
  liquidations: ILiquidationResponse[]
  loading: boolean
}

const DepositTable: React.FC<IDepositListTableProps> = ({ liquidations, loading }) => {
  const { openDrawer } = useDrawer()
  const { setLiquidationSelected, setLiquidationCalculated } = useLiquidationStore()
  const { recalculateLiquidation, liquidation } = useLiquidationRecalculate()

  const handleLiquidationEdit = (data: ILiquidationResponse) => {
    setLiquidationSelected(data)
    openDrawer('LIQUIDATION_EDIT', `Editar liquidation - ${data.user.email}`)
  }

  const handleAddVoucher = (data: ILiquidationResponse) => {
    setLiquidationSelected(data)
    openDrawer('ADD_VOUCHER', `Agregar voucher - ${data.user.email}`)
  }

  const handleRecalculateLiquidation = (liquidation: ILiquidationResponse) => {
    recalculateLiquidation(+liquidation.userId, liquidation.deliveryDate)
  }

  useEffect(() => {
    if (liquidation) {
      setLiquidationCalculated(liquidation)
    }
  }, [liquidation])

  const columns: ColumnsType<ILiquidationResponse> = [
    {
      title: 'Motorizado',
      dataIndex: 'userId', align: 'center',
      render: (_, liquidation) => <>
        {liquidation.user.email} { liquidation.status === 'OBSERVED' ? <>
          {'  '}
          <a onClick={() => handleRecalculateLiquidation(liquidation)} style={{ marginLeft: 8 }}>
            <Tooltip placement="left" title="Recalcular">
              <CalculatorOutlined />
            </Tooltip>
          </a>
          <a onClick={() => handleAddVoucher(liquidation)} style={{ marginLeft: 8 }}>
            <Tooltip placement="left" title="Agregar voucher">
              <FileAddOutlined />
            </Tooltip>
          </a>
        </> : null}
      </>,
    },
    {
      title: 'Estado',
      dataIndex: 'status', align: 'center',
      render: (_, liquidation) => <>
        <a onClick={() => handleLiquidationEdit(liquidation)}>
          <Tag color={LIQUIDATION_STATUS_LABEL[liquidation.status].color}>
            {LIQUIDATION_STATUS_LABEL[liquidation.status].label}
          </Tag>
        </a>
      </>
    },
    {
      title: '#Ordenes',
      dataIndex: 'quantityOrder', align: 'center',
      render: (_, liquidation) => <>{liquidation.quantityOrder}</>,
    },
    {
      title: '#Entregados', dataIndex: 'quantityOrderDelivered', align: 'center',
      render: (_, liquidation) => <>{liquidation.quantityOrderDelivered}</>
    },
    {
      title: '%Efect.', align: 'center',
      dataIndex: 'effectiveness',
      render: (_, liquidation) => <>
        {((liquidation.quantityOrderDelivered) * 100 
        / (liquidation.quantityOrder)).toFixed()}%
      </>
    },
    {
      title: 'Efectivo',
      dataIndex: 'cashPayment', align: 'center',
      render: (_, liquidation) => <>{liquidation.currency}{liquidation.cashPayment.toFixed(2)}</>
    },
    {
      title: 'Transferencia',
      dataIndex: 'transferPayment', align: 'center',
      render: (_, liquidation) => <>{liquidation.currency}{liquidation.transferPayment.toFixed(2)}</>
    },
    {
      title: 'Pos Izipay',
      dataIndex: 'posPayment', align: 'center',
      render: (_, liquidation) => <>{liquidation.currency}{liquidation.posPayment.toFixed(2)}</>
    },
    {
      title: 'C. motorizado',
      dataIndex: 'fleteAmount', align: 'center',
      render: (_, liquidation) => <>{liquidation.currency}{liquidation.fleteAmount.toFixed(2)}</>
    },
    {
      title: 'Total Cobrado',
      dataIndex: 'total', align: 'center',
      render: (_, liquidation) => <>
        {(liquidation.posPayment + liquidation.cashPayment + liquidation.transferPayment).toFixed(2)}
      </>
    },
    {
      title: 'A Depositar',
      dataIndex: 'depositAmount', align: 'center',
      render: (_, liquidation) => <>{liquidation.currency}{liquidation.depositAmount.toFixed(2)}</>
    },
    {
      title: 'Depositado', align: 'center',
      render: (_, liquidation) => <>{liquidation.currency}
        {liquidation.evidenceLiquidations.length > 0 ? 
          liquidation.evidenceLiquidations.map(p => p.amount).reduce((a, b) => a + b).toFixed(2) : ''}</>
    },
    {
      title: 'C. courier e.',
      dataIndex: 'courierCommission', align: 'center',
      render: (_, liquidation) => <>{liquidation.currency}{liquidation.courierCommission.toFixed(2)}</>
    },
    {
      title: 'C. courier r.',
      dataIndex: 'courierCommissionRejection', align: 'center',
      render: (_, liquidation) => <>{liquidation.currency}{liquidation.courierCommissionRejection.toFixed(2)}</>
    },
    {
      title: 'C. courier d.',
      dataIndex: 'courierCommissionReturn', align: 'center',
      render: (_, liquidation) => <>{liquidation.currency}{liquidation.courierCommissionReturn.toFixed(2)}</>
    }
  ]

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={liquidations}
      columns={columns}
      rowClassName="editable-row"
      loading={loading}
      pagination={false}
      size="small"
      summary={pageData => {
        let totalQuantityOrder = 0
        let totalQuantityOrderDelivery = 0
        let totalCash = 0
        let totaltransfer = 0
        let totalPos = 0
        let totalFlete = 0
        let totalCharged = 0
        let totalDeposit = 0
        let totalLiquidation = 0
        let totalCCourier = 0
        let totalCReturn = 0
        let totalCRejection = 0

        const currency = pageData.length > 0 ? pageData[0].currency : ''

        pageData.forEach(({ quantityOrder,
          quantityOrderDelivered, cashPayment, transferPayment,
          posPayment, fleteAmount, depositAmount, evidenceLiquidations, courierCommission,
          courierCommissionRejection, courierCommissionReturn
        }) => {
          totalQuantityOrder += (quantityOrder)
          totalQuantityOrderDelivery += (quantityOrderDelivered)
          totalCash += cashPayment
          totalFlete += fleteAmount
          totalCCourier += courierCommission
          totalCRejection += courierCommissionRejection
          totalCReturn += courierCommissionReturn
          totaltransfer += transferPayment
          totalPos += posPayment
          totalCharged += (cashPayment + transferPayment + posPayment)
          totalDeposit += depositAmount
          totalLiquidation += (evidenceLiquidations.length > 0 ? 
            evidenceLiquidations.map(p => p.amount).reduce((a, b) => a + b) : 0)
        })

        return (<>
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1} align='center'>
                <b>Totales</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align='center'>
                {totalQuantityOrder}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align='center'>
                {totalQuantityOrderDelivery}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5} align='center'>
                {currency}{totalCash.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} align='center'>
                {currency}{totaltransfer.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} align='center'>
                {currency}{totalPos.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8} align='center'>
                {currency}{totalFlete.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9} align='center'>
                {currency}{totalCharged.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10} align='center'>
                {currency}{totalDeposit.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={11} align='center'>
                {currency}{totalLiquidation.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={12} align='center'>
                {currency}{totalCCourier.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={13} align='center'>
                {currency}{totalCRejection.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={14} align='center'>
                {currency}{totalCReturn.toFixed(2)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        </>)
      }}
    />
  )
}

export default DepositTable
