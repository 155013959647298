import { useEffect, useState } from 'react'
import { useFirebase } from '../../hooks'
import { objectToArray } from '../../utils/object-to-array'
import { IOrderShopify } from './order-shopify.interface'

export const useShopifyOrders = () => {
  const { getFromDatabaseQuery, response, loading } = useFirebase()
  const [orderAll, setOrderAll] = useState<IOrderShopify[]>([])
  const [ordersAll, setOrdersAll] = useState<IOrderShopify[]>([])
  
  useEffect(() => {
    const listAll: IOrderShopify[] = objectToArray<IOrderShopify>(response)

    setOrderAll(listAll.sort((a, b) => b.id - a.id))
    setOrdersAll(listAll.sort((a, b) => b.id - a.id))
  }, [response])
  
  const getOrderShopify = (companyId: string) => {
    getFromDatabaseQuery(`shopify_orders/company_${companyId}`)
  }

  const getOrderShopifySearch = (search: string) => {
    if (ordersAll.length > 0) {
      const orders: IOrderShopify[] = []

      ordersAll.forEach(o => {
        if (o.shipping.address.toLocaleLowerCase().includes(search) || 
          o.customer.firstName.toLocaleLowerCase().includes(search) ||
          o.customer.lastName.toLocaleLowerCase().includes(search) ||
          o.shipping.phone.toLocaleLowerCase().includes(search) ||
          o.shipping.city.toLocaleLowerCase().includes(search) ||
          o.shipping.zip.toLocaleLowerCase().includes(search) ||
          o.shipping.province.toLocaleLowerCase().includes(search)) {

          orders.push(o)

        }
      })

      setOrderAll(orders)
    }
  }
  
  return {
    getOrderShopify,
    getOrderShopifySearch,
    orderAll,
    loading,
  }
}
