import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useDrawer } from '../../../../../context/DrawerContext'
import { IWarehouseCreateSchema } from '../../../../../services/warehouse-create/warehouse-create.schema'
import { WarehouseEditForm } from '../../forms'
import { useWarehouseCreate } from '../../../../../services/warehouse-create/warehouse-create.service'
import { useWarehouseStore } from '../../../../../services/warehouse-create/warehouse.store'

const WarehouseEditContainer = () => {
  const { warehouseCreate, warehouseCreated, loading } = useWarehouseCreate()
  const { closeDrawer } = useDrawer()
  const { setWarehouseUpdated } = useWarehouseStore()

  const handleSubmit = (data: IWarehouseCreateSchema) => {
    warehouseCreate(data)
  }

  useEffect(() => {
    if (warehouseCreated) {
      setWarehouseUpdated(warehouseCreated)
      closeDrawer()
    }
  }, [warehouseCreated])

  return (
    <>
      <Spin spinning={loading}>
        <WarehouseEditForm
          onSubmit={handleSubmit}
        />
      </Spin>
    </>
  )
}

export default WarehouseEditContainer
