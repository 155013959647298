import React from 'react'
import { OrdersToReturnContainer } from './containers'

const OrdersToReturnView = () => {
  return (
    <>
      <OrdersToReturnContainer />
    </>
  )
}

export default OrdersToReturnView
