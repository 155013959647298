import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Radio, Select, Spin, Typography } from 'antd'
import moment from 'moment'
import { downloadExcel } from 'react-export-table-to-excel'
import { useAuthStore } from '../../../../services/auth/auth.service'
import { 
  useDispatchReport
} from '../../../../services/order-report-created/order-report-created.service'
import { useWarehouseByCountry } from '../../../../services/warehouse-list/warehouse-list.service'
import { formatDateUTC } from '../../../../utils/date'

const { Title } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const DispatchReport = () => {
  const { authUser } = useAuthStore()
  const dateFormat = 'YYYY-MM-DD'
  const { getDispatchReport, dispatchReports, loadingDispatchReports } = useDispatchReport()
  const [ecomSelected, setEcomSelected] = useState<string>('')
  const { getWarehouses, loadingWarehouses, warehouses } = useWarehouseByCountry()
  const [hiddenWar, setHiddenWar] = useState(false)
  const [filter, setFilter] = useState<string>('dispatch')
  const [selectedDatesOrder, setSelectedDatesOrder] = useState([
    moment(new Date(), 'YYYY-MM-DD'),
    moment(new Date(), 'YYYY-MM-DD')
  ])
  
  const headerOrder = [
    'FECHA ENTREGA', 'FECHA DESPACHO', 'PRODUCTO', 'VARIANTE', 'PROVEEDOR', 'ALMACÉN', 'TIENDA', 'PAÍS', 'DEPARTAMENTO',
    'PROVINCIA', 'DISTRITO', 'PRECIO DROP', 'CANTIDAD', 'NRO. PEDIDO', 'ESTADO ENTREGA', 'ESTADO DESPACHO', 
    'DROPSHALOM', 'TOTAL'
  ]

  function handleDownloadExcelOrders() {

    getDispatchReport(
      selectedDatesOrder[0].format('yyyy-MM-DD'),
      selectedDatesOrder[1].format('yyyy-MM-DD'),
      parseInt(ecomSelected),
      filter,
      authUser.user.company.countryCode
    )

  }

  useEffect(() => {
    if (dispatchReports && dispatchReports.length > 0) {
      downloadExcel({
        fileName: 
          `dispatch-report-${selectedDatesOrder[0]
            .format('yyyy-MM-DD')}-to-${selectedDatesOrder[1].format('yyyy-MM-DD')}`,
        sheet: 'react-export-table-to-excel',
        tablePayload: {
          header: headerOrder,
          // accept two different data structures
          body: dispatchReports.map(o => {
            return { 
              deliveryDate: formatDateUTC(o.deliveryDate),
              dispatchDate: formatDateUTC(o.dispatchDate),
              product: o.product,
              variant: o.variant,
              supplier: o.supplier,
              warehouse: o.warehouse,
              ecommerce: o.ecommerce,
              country: o.country,
              department: o.department,
              province: o.province,
              district: o.district,
              dropPrice: o.dropPrice,
              quantity: o.quantity,
              orderNumber: o.orderNumber,
              deliveryStatus: o.deliveryStatus,
              dispatchStatus: o.dispatchStatus,
              dropShalom: o.dropShalom,
              total: o.total,
            }
          })
        }
      })
    }
  }, [dispatchReports])

  const handleCalendarChangeOrder = (dates: any, dateStrings: any) => {
    setSelectedDatesOrder(dates)
  }

  const handleFilter = (e: any) => {
    /*getDispatchReport(
      selectedDatesOrder[0].format('yyyy-MM-DD'),
      selectedDatesOrder[1].format('yyyy-MM-DD'),
      parseInt(ecomSelected),
      e.target.value,
      authUser.user.company.countryCode
    )*/
    setFilter(e.target.value)
  }

  /*useEffect(() => {
    if (selectedDatesOrder.length === 2 && selectedDatesOrder[0] && selectedDatesOrder[1] 
      && ecomSelected.length > 0) {

      getDispatchReport(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        parseInt(ecomSelected),
        filter,
        authUser.user.company.countryCode
      )

    }
  }, [selectedDatesOrder])*/

  const handleChangeEcom = (ecomId: string) => {

    /*if (ecomId.length > 0) {
      getDispatchReport(
        selectedDatesOrder[0].format('yyyy-MM-DD'),
        selectedDatesOrder[1].format('yyyy-MM-DD'),
        parseInt(ecomId),
        filter,
        authUser.user.company.countryCode
      )
    }*/
    
    setEcomSelected(ecomId)
  }

  useEffect(() => {
    if (warehouses && warehouses.length > 0) {
      setEcomSelected(`${warehouses[0].id}`)
    }
  }, [warehouses])

  useEffect(() => {

    if (authUser.user.role.name === 'ADMIN') {
      setHiddenWar(false)
      getWarehouses()
    } else if (authUser.user.role.name === 'STORE') {
      setHiddenWar(true)
      setEcomSelected(`${authUser.user.warehouse?.id}`)
    }
    
  }, [])
  
  return <>
    <Spin spinning={loadingDispatchReports}>

      <Divider />

      <Title level={5} style={{ marginBottom: '0', alignContent: 'center', padding: '.0rem 1.5rem' }}>
        Reporte de Despacho
      </Title>

      <div
        style={{
          padding: '.85rem 1.5rem', display: 'flex',
          gap: '1rem', alignItems: 'center',
        }}
      >

        <Title level={5} style={{ marginBottom: '0' }}>
          Filtrar por
        </Title>

        <Radio.Group
          options={[
            { label: 'Fecha despacho', value: 'dispatch' },
            { label: 'Fecha entrega', value: 'delivery' },
          ]}
          onChange={handleFilter}
          defaultValue={filter}
        />

        <Title level={5} style={{ marginBottom: '0' }}>
          Seleccionar fechas
        </Title>

        <RangePicker format={dateFormat} 
          defaultValue={[moment(new Date(), 'YYYY-MM-DD'), moment(new Date(), 'YYYY-MM-DD')]}
          onCalendarChange={handleCalendarChangeOrder} clearIcon={false}/>

        { !hiddenWar ? <>
          <Title level={5} style={{ marginBottom: '0' }}>
            Almacen
          </Title>

          <Select placeholder='Ecommerce' value={ecomSelected} onChange={handleChangeEcom} 
            loading={loadingWarehouses} style={{ display: 'flex', width: '200px' }}
            showSearch
            optionFilterProp='label'
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key={-1} value={-1} label={'TODOS'}>TODOS</Option>
            {warehouses.map(c => (
              <Option key={c.id} value={`${c.id}`} label={c.name}>{c.name}</Option>
            ))}
          </Select>
        </> : null }

        <Button type='primary' onClick={handleDownloadExcelOrders} 
          loading={loadingDispatchReports}>
            Descargar reporte
        </Button>

      </div>

    </Spin>

  </>
}

export default DispatchReport
