import React from 'react'
import { SignInContainer } from './containers'
import backgroundLogin from './background_login.png'
import logo from './ic_aliclik.png'

const SignInView = () => {
  return <>
    <div style={{ backgroundImage: `url(${backgroundLogin})`, backgroundRepeat: 'no-repeat',
      height: '100vh', width: '100%', backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <div className="WrapperBoxSmall">
        <div className="center">
          <img src={logo} style={{ width: '100px', height: '100px' }}/>
        </div>

        <SignInContainer />

        <p className="center" style={{ fontSize: '13px' }}>
          Copyright 2023 © Aliclik Perú.
        </p>
      </div>
    </div>
  </>
}

export default SignInView
