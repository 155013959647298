import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'
import { ITicketSupport } from '../ticket-support-list/ticket-support-list.interface'
import { ITicketSchema } from '../ticket-support-list/ticket-support.schema'
import { mapToTicketCreateRequest, mapToTicketUpdateRequest } from './ticket-support-create.mapper'
import { useCompanyStore } from '../company-create/company-create.service'

export const useTicketDelete = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<ITicketSupport>()

  const deleteTicket = (ticketId: number) => {

    datadogLogs.logger.info('TK_DELETE', { action: 'TK_DELETE', request: {
      ticketId: ticketId,
    },
    user: {
      id: authUser.user.id,
      email: authUser.user.email,
      fullName: authUser.user.fullname,
      role: authUser.user.role.name,
      permissions: authUser.user.role.permissions
    },
    company: {
      id: authUser.user.company.id,
      name: authUser.user.company.name,
      code: authUser.user.company.setting.companyPrefix,
    }
    })
      
    execute({
      method: 'PATCH',
      url: `/ticket-support/delete/${ticketId}`,
    })
    
  }

  return {
    deleteTicket,
    loadingTkDelete: loading,
    ticketDeleted: response
  }

}

export const useTicketCreate = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading } = useAxios<ITicketSupport>()

  const createNewTicket = (data: ITicketSchema) => {
    
    if (data.id === null) {
      const request = mapToTicketCreateRequest(authUser, companyGlobal, data)

      datadogLogs.logger.info('TK_CREATE', { action: 'TK_CREATE', request: request,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })

      execute({
        method: 'POST',
        url: '/ticket-support',
        data: request,
      })
    } else {

      const request = mapToTicketUpdateRequest(authUser, data)

      datadogLogs.logger.info('TK_UPDATE', { action: 'TK_UPDATE', request: request,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
      
      execute({
        method: 'PATCH',
        url: `/ticket-support/${data.id}`,
        data: request,
      })
    }
    
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('TK:POST', { action: 'TK:POST',
        response: response,
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        }
      })
    }
  }, [response])

  return {
    createNewTicket,
    loading,
    ticketCreated: response
  }
}
