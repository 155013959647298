import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { useCompanyStore } from '../company-create/company-create.service'
import { IProductCreateResponse, ISkuUpdateRequest } from './product-create.interface'
import { mapToProductCreateRequest } from './product-create.mapper'
import { IProductCreateSchema, ISkuUpdateSchema } from './product-create.schema'
import { datadogLogs } from '../../hooks/use-datadog'
import { useEffect } from 'react'

export const useSkuUpdate = () => {
  const { authUser } = useAuthStore()
  const { execute, response, loading } = useAxios<IProductCreateResponse>()

  const updateSku = (data: ISkuUpdateSchema) => {

    const skuRequest: ISkuUpdateRequest = {
      height: parseFloat(String(data.heightProd)),
      width: parseFloat(String(data.widthProd)),
      length: parseFloat(String(data.lengthProd)),
      volume: parseFloat(String(data.heightProd * data.widthProd * data.lengthProd)),
      warehouseId: data.warehouseId !== -1 ? `${data.warehouseId}` : undefined,
      companyId: data.companyId,
      stock: data.stock,
      stockStore: data.stockStore
    }

    datadogLogs.logger.info('SKU_UPDATE', { action: 'SKU_UPDATE',
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
      product: skuRequest,
    })

    execute({
      url: `/product/sku/${data.id}`,
      method: 'PATCH',
      data: skuRequest
    })

  }

  return {
    updateSku,
    skuUpdated: response,
    loading
  }
}

export const useProductCreate = () => {
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { execute, response, loading } = useAxios<IProductCreateResponse>()

  const createProduct = (data: IProductCreateSchema) => {
    const dataRequest = mapToProductCreateRequest(data, authUser, companyGlobal)

    const method = data.id ? 'PATCH' : 'POST'
    const url = data.id ? `/product/${data.id}` : '/product'

    const action = data.id ? 'PRODUCT_UPDATE' : 'PRODUCT_CREATE'

    datadogLogs.logger.info(action, { action: action,
      user: {
        id: authUser.user.id,
        email: authUser.user.email,
        fullName: authUser.user.fullname,
        role: authUser.user.role.name,
        permissions: authUser.user.role.permissions
      },
      company: {
        id: authUser.user.company.id,
        name: authUser.user.company.name,
        code: authUser.user.company.setting.companyPrefix,
      },
      product: dataRequest,
      productName: dataRequest.name
    })

    execute({
      method,
      url,
      data: dataRequest,
    })
  }

  useEffect(() => {
    if (response) {
      datadogLogs.logger.info('PRODUCT:POST', { action: 'PRODUCT:POST',
        user: {
          id: authUser.user.id,
          email: authUser.user.email,
          fullName: authUser.user.fullname,
          role: authUser.user.role.name,
          permissions: authUser.user.role.permissions
        },
        company: {
          id: authUser.user.company.id,
          name: authUser.user.company.name,
          code: authUser.user.company.setting.companyPrefix,
        },
        productResponse: response,
      })
    }
  }, [response])

  return {
    createProduct,
    productCreated: response,
    loading,
  }
}

