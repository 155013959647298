/* eslint-disable @typescript-eslint/no-explicit-any */
import { applyMiddleware, compose, createStore } from 'redux'
import { rootReducer } from '.'
import { loadFromLocalStorage, saveToLocalStorage } from './localstorage/localStorage'

// devtools for debugging in dev environment.
const devTools =
  (process.env.NODE_ENV !== 'production' && (window as any).__REDUX_DEVTOOLS_EXTENSION__) ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (a: any) => a

const middlewares: any[] = []

const store = createStore(
  rootReducer,
  loadFromLocalStorage(),
  compose(applyMiddleware(...middlewares), devTools),
)

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store
