import React from 'react'
import { Table } from 'antd'
import { IMovementResponse } from '../../../../../services/movement-list/movement-list.interface'
import { ColumnsType } from 'antd/lib/table'
import { formatDate } from '../../../../../utils/date'
import useMeasureY from '../../../../../hooks/use-measurey'
import { MOVEMENT_OPERATION_LABEL_LIST } from '../../../../../services/movement-create/movement.params'

interface IMovementsListTableProps {
  movements: IMovementResponse[]
  loading: boolean
}

const MovementsListTable: React.FC<IMovementsListTableProps> = ({ movements, loading }) => {
  const { windowSize } = useMeasureY()
  const columns: ColumnsType<IMovementResponse> = [
    {
      title: 'Fecha',
      dataIndex: 'createdAt', align: 'center',
      width: '8%',
      render: (_, { createdAt, user }) => <>{formatDate(createdAt)}{ user ? <>
        <br />{user.email}
      </> : null }</>,
    },
    {
      title: 'Producto',
      dataIndex: 'sku',
      width: '13.5%',
      render: (_, movement) => <>{movement.sku.product.name} - {movement.sku.skuOptions.length > 0 ? 
        movement.sku.skuOptions[0].option.variant.name : ''} 
      - {movement.sku.skuOptions.length > 0 ? movement.sku.skuOptions[0].option.name : ''}</>
    },
    {
      title: 'Almacén',
      dataIndex: 'warehouse', align: 'center',
      width: '11%',
      render: (_, movement) => <>{movement.warehouse.name}</>
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity', align: 'center',
      width: '5%',
    },
    {
      title: 'Comentario',
      dataIndex: 'comment',
      width: '10%',
    },
    {
      title: 'Operación',
      dataIndex: 'operation',
      width: '5%',
      align: 'center',
      render: (_, movement) => <>
        {MOVEMENT_OPERATION_LABEL_LIST[movement.operation].label}
      </>
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      width: '5%',
      align: 'center',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      width: '5%',
      align: 'center',
    },
  ]

  return (
    <Table
      rowKey="id"
      bordered
      dataSource={movements}
      columns={columns}
      rowClassName="editable-row"
      loading={loading}
      pagination={{ pageSize: 20 }}
      scroll={{
        y: windowSize.height - 200,
      }}
      size="small"
    />
  )
}

export default MovementsListTable
