import React from 'react'
import { TaskContainer } from './containers'

const TaskView = () => {
  return <>
    <TaskContainer />
  </>
}

export default TaskView
