/* eslint-disable @typescript-eslint/no-explicit-any */
import { actionTypes } from './movement.actions'
import { IMovementResponse } from '../../services/movement-list/movement-list.interface'
import { IMovementCreateResponse } from '../../services/movement-create/movement.interface'

export interface IMovementState {
  movementSelected: IMovementResponse | null;
  movementUpdated: IMovementCreateResponse | null;
}

const initialState: IMovementState = {
  movementSelected: null,
  movementUpdated: null,
}

export const movementReducer = (
  state: IMovementState = initialState,
  action: { type: string, payload?: any },
) => {
  switch (action.type) {
  case actionTypes.SET_MOVEMENT_SELECTED:
    return {
      ...state,
      movementSelected: action.payload,
    }
  case actionTypes.SET_MOVEMENT_UPDATED:
    return {
      ...state,
      movementUpdated: action.payload,
    }
  default:
    return state
  }
}
