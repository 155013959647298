import React from 'react'
import { Button } from 'antd'
import { usePreOrderImport } from '../../../../../services/pre-order/pre-order.service'

const ImportOrderContainer = () => {
  const { preOrderImport, loading } = usePreOrderImport()

  return <>
    <Button
      type="primary"
      htmlType="button"
      onClick={preOrderImport}
      loading={loading}
    >
      Importar
    </Button>
  </>
}

export default ImportOrderContainer
