import { ICurrency, ISelect, IBank } from './data.interface'

export const AGENCY_LIST: ISelect[] = [
  { label: 'OLVA', value: 'OLVA' },
  { label: 'SHALOM', value: 'SHALOM' },
  { label: 'EXPRESS', value: 'EXPRESS' },
  { label: 'OTROS', value: 'OTROS' },
]

export const AGENCY_LIST_ONLY_SHALOM: ISelect[] = [
  { label: 'SHALOM', value: 'SHALOM' },
]

export const CURRENCY_LIST: ICurrency[] = [
  { code: 'PEN', name: 'SOLES', symbol: 'S/' },
  { code: 'USD', name: 'DOLAR', symbol: '$' },
  { code: 'BOB', name: 'BOLIVIANO', symbol: 'Bs' },
]

export const COUNTRY_LIST = [
  { code: 'PER', name: 'Perú' },
  { code: 'BOL', name: 'Bolivia' }
]

export const DOCUMENT_TYPE_LIST: ISelect[] = [
  { label: 'CARNE DE EXTRANGERIA', value: 'CARNE DE EXTRANGERIA' },
  { label: 'DNI', value: 'DNI' },
  { label: 'PASAPORTE', value: 'PASAPORTE' },
  { label: 'LIBRETA ELECTORAL', value: 'LIBRETA ELECTORAL' },
]

export const BANK_LIST: IBank[] = [
  { code: 'BCP', value: 'Banco de Crédito del Perú' },
  { code: 'IBK', value: 'Interbank' },
]
