import { Button, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useDispatchGuideList } from '../../../../../services/dispatch-guide-list/dispatch-guide-list.service'
import DispatchGuideListTable from '../../components/DispatchGuideListTable/DispatchGuideListTable'
import { useDispatchGuideStore } from '../../../../../services/dispatch-guide-create/dispatch-guide.store'
import { useModal } from '../../../../../context/ModalContext'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { CompanyChangeContainer } from '../../../../../containers'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useWarehouseByCountry } from '../../../../../services/warehouse-list/warehouse-list.service'

const { Search } = Input
const { Option } = Select

const DispatchGuideMaintContainer = () => {
  const { openDrawer } = useDrawer()
  const { openModal } = useModal()
  const { getDispatchGuides, dispatchGuides, loading, count, currentPage } = useDispatchGuideList()
  const {
    setDispatchGuideSelected,
    dispatchGuideUpdated,
    summaryProductEdited,
    orderAddedToDispatch,
    dispatchGuideDeleted,
  } = useDispatchGuideStore()
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { warehouses, loadingWarehouses, getWarehouses } = useWarehouseByCountry()
  const [warehouseSelected, setWarehouseSelected] = useState<string>('ALL')
  const [searchText, setSearchText] = useState('')

  const handleOpenDrawer = () => {
    setDispatchGuideSelected(null)
    openDrawer('DISPATCH_GUIDE_EDIT', 'Crear guía')
  }

  const handlePagination = (page: number) => {
    getDispatchGuides(page, searchText, warehouseSelected)
  }

  const handleModalCalculateAccumulated = () => {
    setDispatchGuideSelected(null)
    openModal('PRODUCTS_ACCUMULATED', 'Resumen de productos acumulados')
  }

  useEffect(() => {
    getWarehouses()
    if (authUser.user.company.type === 'PRINCIPAL' && companyGlobal) {
      getDispatchGuides(1, searchText, warehouseSelected)
    } else if (authUser.user.company.type === 'NORMAL') {
      getDispatchGuides(1, searchText, warehouseSelected)
    }
  }, [companyGlobal])

  const onSearch = (value: string) => {

    setSearchText(value)

    if (value.length > 0) {

      getDispatchGuides(1, value, warehouseSelected)

    } else {
      getDispatchGuides(
        1,
        value,
        warehouseSelected
      )
    }
  }

  useEffect(() => {
    if (dispatchGuideUpdated) {
      setDispatchGuideSelected(null)
      getDispatchGuides(1, searchText, warehouseSelected)
    }
  }, [dispatchGuideUpdated])

  useEffect(() => {
    if (summaryProductEdited && summaryProductEdited.length > 0) {
      getDispatchGuides(1, searchText, warehouseSelected)
    }
  }, [summaryProductEdited])

  useEffect(() => {
    if (dispatchGuideDeleted) {
      getDispatchGuides(1, searchText, warehouseSelected)
    }
  }, [dispatchGuideDeleted])

  useEffect(() => {
    if (orderAddedToDispatch) {
      getDispatchGuides(1, searchText, warehouseSelected)
    }
  }, [orderAddedToDispatch])

  const handleChangeWarehouse = (value: string) => {
    setWarehouseSelected(value)
    
    getDispatchGuides(1, searchText, warehouseSelected)
  }

  return (
    <>
      <PageHeader>

        <div
          style={{
            display: 'flex', justifyContent: 'space-between', gap: '.5rem', alignItems: 'center',
          }}
        >

          {authUser.user.role?.permissions?.product_company_change ? (
            authUser.user.company.type === 'PRINCIPAL' ? <>
              <CompanyChangeContainer isAll={false}/>
            </> : null
          ) : null}

          { authUser.user.warehouse ? <>
          </> : <>
            <h4 style={{ marginBottom: '0' }}>Almacén</h4>

            <Select
              placeholder="Almacén"
              showSearch
              loading={loadingWarehouses}
              optionFilterProp="label"
              style={{ minWidth: '150px' }}
              onChange={handleChangeWarehouse}
              value={warehouseSelected}
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option key='ALL' value='ALL' label={'TODOS'}>
                  TODOS
              </Option>
              {warehouses.map(key => (
                <Option
                  key={key.id}
                  value={key.id}
                  label={key.name}
                >
                  {key.name}
                </Option>
              ))}
            </Select>

          </>}

          <div>
            <Button type="primary" onClick={handleOpenDrawer}>
              Crear Guía
            </Button>
          </div>

          <div>
            <Search placeholder="Buscar guía y pedidos de la guía"
              onSearch={onSearch} enterButton style={{ width: 304 }} allowClear/>
          </div>
          <div>
            <Button type="primary" onClick={handleModalCalculateAccumulated}>
              Calcular Productos Acumulados
            </Button>
          </div>
        </div>

      </PageHeader>
      <DispatchGuideListTable
        handlePagination={handlePagination}
        dispatchGuides={dispatchGuides}
        loading={loading}
        count={count}
        currentPage={currentPage}
      />
    </>
  )
}

export default DispatchGuideMaintContainer
