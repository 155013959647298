import React from 'react'
import { Form, Input, Typography, InputNumber } from 'antd'

const { Title } = Typography

const OrderShopifyCustomerForm = ({ disabled }: { disabled: boolean }) => {

  return <>
    <Title level={4}>Cliente</Title>
    <Form.Item
      label="Nombres"
      name={['customer', 'name']}
      rules={[{ required: true, message: 'Obligatorio' }]}
    >
      <Input disabled={disabled}/>
    </Form.Item>
    <Form.Item
      label="Apellidos"
      name={['customer', 'lastName']}
    >
      <Input disabled={disabled}/>
    </Form.Item>
    <Form.Item
      label="Móvil"
      name={['customer', 'phone']}
      rules={[{ required: true, message: 'Obligatorio' }]}
    >
      <InputNumber
        disabled={disabled}
        controls={false}
        maxLength={11}
        style={{ display: 'inline-block', width: '100%' }}
      />
    </Form.Item>
  </>
}

export default OrderShopifyCustomerForm
