import React from 'react'
import { DeliveryListContainer } from './containers'

const OrdersView = () => {
  return (
    <>
      <DeliveryListContainer/>
    </>
  )
}

export default OrdersView
