import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../state'
import { IWarehouseState } from '../../state/warehouse/warehouse.reducer'
import { warehouseActions } from '../../state/warehouse/warehouse.actions'
import { IWarehouseResponse } from '../warehouse-list/warehouse-list.interface'
import { IWarehouseCreateResponse } from './warehouse-create.interface'

export const useWarehouseStore = () => {
  const dispatch = useDispatch()
  const {
    warehouseSelected, warehouseUpdated,
  } = useSelector((state: IRootState) => state.warehouse as IWarehouseState)

  const setWarehouseSelected = (data: IWarehouseResponse | null) => {
    dispatch(warehouseActions.setWarehouseSelected(data))
  }

  const setWarehouseUpdated = (data: IWarehouseCreateResponse) => {
    dispatch(warehouseActions.setWarehouseUpdated(data))
  }

  return {
    setWarehouseSelected,
    setWarehouseUpdated,
    warehouseSelected,
    warehouseUpdated
  }
}
