import operations from '../../config/data/operation.json'
import { IOperation } from './operation-list.interface'

export const useOperationList = () => {
  const getOperations = (isMovement: boolean) => {
    operations.find(v => v.isMovement === isMovement) as IOperation
  }

  return {
    getOperations,
    operations: operations ? operations : []
  }
}
