import React, { useEffect } from 'react'
import { useDrawer } from '../../../../../context/DrawerContext'
import { Spin } from 'antd'
import { CatalogEditForm } from '../../forms'
import { useCatalogStore } from '../../../../../services/catalog/catalog.store'
import { ICatalogSchema } from '../../../../../services/catalog/catalog.schema'
import { useCatalogCreate } from '../../../../../services/catalog/catalog.service'

const CatalogEditContainer = () => {
  const { closeDrawer } = useDrawer()
  const { setCatalogUpdated } = useCatalogStore()
  const { createNewCatalog, catalogCreated, loading } = useCatalogCreate()

  const handleSubmit = (data: ICatalogSchema) => {
    createNewCatalog(data)
  }

  useEffect(() => {
    if (catalogCreated) {
      setCatalogUpdated(catalogCreated)
      closeDrawer()
    }
  }, [catalogCreated])

  return (
    <>
      <Spin spinning={loading}>
        <CatalogEditForm
          onSubmit={handleSubmit}
        />
      </Spin>
    </>
  )
}

export default CatalogEditContainer
