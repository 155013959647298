/* eslint-disable max-len */
import React, { FC } from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { IFrequentQuestion } from '../../../../services/frequent-question-list/frequent-question.interface'

interface IQuestionsTableProps {
  data: IFrequentQuestion[] | undefined
  loading: boolean
}

const QuestionsTable: FC<IQuestionsTableProps> = ({ data, loading }) => {

  const columnsLevel1: ColumnsType<IFrequentQuestion> = [
    {
      title: 'Pregunta', dataIndex: 'question', align: 'left', width: '100%',
      render: (_, faq) => <>
        <h3><b>{faq.question}</b></h3>
      </>
    },
    Table.EXPAND_COLUMN,
    {
      title: '', dataIndex: 'x', align: 'center', width: '0%',
    }
  ]

  return (
    <>
      <Table
        rowKey='id'
        columns={columnsLevel1}
        dataSource={data}
        size='small'
        showHeader={false}
        pagination={false}
        loading={loading}
        expandable={{
          expandedRowRender: (record) => <>
            <div dangerouslySetInnerHTML={{ __html: record.answer }} style={{ padding: '10px', fontSize: 'small' }}/>
          </>,
          rowExpandable: (record) => `${record.id}` !== 'Not Expandable',
        }}
      />
    </>
  )
}

export default QuestionsTable
