import { useAxios } from '../../hooks'
import { useAuthStore } from '../auth/auth.service'
import { IProductParentResponse } from './product-parent.interface'

const useProductParentFind = () => {
  const { authUser } = useAuthStore()
  const { execute, response: productParents, loading } = useAxios<IProductParentResponse[]>()

  const getProductParents = (companyId?: string, parentId?: string) => {

    const params = {
      companyId: companyId === '0' ? '' : (companyId || authUser.user.company.id),
      parentId: companyId === '0' ? '' : (parentId ? parentId : authUser.user.company.parentId)
    }
    
    execute({
      method: 'GET',
      url: '/product/parent',
      params: params,
    })
  }

  return {
    getProductParents,
    loading,
    productParents: productParents ? productParents : [],
  }
}

export {
  useProductParentFind,
}
