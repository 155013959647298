import { Button, Select, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '../../../../../components'
import { CompanyChangeContainer } from '../../../../../containers'
import { useDrawer } from '../../../../../context/DrawerContext'
import { useAuthStore } from '../../../../../services/auth/auth.service'
import { useCompanyStore } from '../../../../../services/company-create/company-create.service'
import { useUsersPagination } from '../../../../../services/user-list/user-list.service'
import { useUserStore } from '../../../../../services/user/user.service'
import { UsersListTable } from '../../components'

const { Option } = Select
const { Search } = Input

const UsersMaintContainer = () => {
  const { openDrawer } = useDrawer()
  const { authUser } = useAuthStore()
  const { companyGlobal } = useCompanyStore()
  const { getUsers, response, loading, count, page } = useUsersPagination()
  const { userUpdated, setUserSelected } = useUserStore()
  const [defaultActive, setDefaultActive] = useState<string>('ACTIVE')

  const handleOpenDrawer = () => {
    setUserSelected(null)
    openDrawer('USER_EDIT', 'Agregar usuario')
  }

  const handlePagination = (page: number) => {
    getUsers(
      defaultActive === 'ACTIVE',
      '',
      `${page}`,
    )
  }

  const onSearch = (value: string) => {

    if (value.length > 0) {

      getUsers(
        defaultActive === 'ACTIVE',
        value,
        '1',
      )

    } else {

      getUsers(
        defaultActive === 'ACTIVE',
        '',
        '1'
      )
    }
  }

  useEffect(() => {
    if (userUpdated) {
      getUsers(
        defaultActive === 'ACTIVE',
        '',
        '1'
      )
    }
  }, [userUpdated])

  useEffect(() => {

    if (authUser.user.company.countryCode === 'PER') {
      if (companyGlobal) {

        getUsers(
          defaultActive === 'ACTIVE',
          '',
          '1'
        )
  
      } else {
  
        getUsers(
          defaultActive === 'ACTIVE',
          '',
          '1'
        )
  
      }
    } else if (authUser.user.company.countryCode === 'BOL') {
      getUsers(
        defaultActive === 'ACTIVE',
        '',
        '1'
      )
    }
    
  }, [companyGlobal])

  const handleChangeStatus = (value: string) => {
    setDefaultActive(value)
  }

  useEffect(() => {
    getUsers(
      defaultActive === 'ACTIVE',
      '',
      '1'
    )
  }, [defaultActive])

  return (
    <>
      <PageHeader>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
          { authUser.user.role?.permissions?.user_company_change ? (
            <CompanyChangeContainer isAll={true} />
          ) : null}
          <Button type="primary" onClick={handleOpenDrawer}>
            Agregar usuario
          </Button>

          <Search placeholder="Buscar por usuario y teléfono."
            onSearch={onSearch} enterButton style={{ width: 304 }} allowClear/>
          <div>|</div>
          <Select
            optionFilterProp="label"
            style={{ minWidth: '130px' }}
            onChange={handleChangeStatus}
            value={defaultActive}
            filterOption={(input, option) =>
              (option?.label as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option key='ACTIVE' value='ACTIVE' label={'ACTIVO'}>
              ACTIVO
            </Option>
            <Option key='INACTIVE' value='INACTIVE' label={'INACTIVO'}>
              INACTIVO
            </Option>
          </Select>

        </div>
      </PageHeader>
      <UsersListTable
        users={response}
        loading={loading}
        handlePagination={handlePagination}
        count={count}
        currentPage={page}
      />
    </>
  )
}

export default UsersMaintContainer
